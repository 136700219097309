<div class="sellentt-container" *ngIf="loadedData">
    <div class="sellentt-row">
        <div class="sellentt-col-xl-12">
            <div class="sellentt-row">
                <div class="sellentt-col-xl-12">
                    <div class="product-wrapper card h-40 mt-5" *ngIf="productId">
                        <div class="sellentt-row p-5">
                            <div class="sellentt-col-xl-12">
                                <div class="sellentt-row">
                                    <div class="sellentt-col-x1-6">
                                        <div class="sellentt-row">
                                            <div class="sellentt-col-xs-5 sellentt-col-md-1">
                                                <img src="{{ product.image }}" class="product-image">
                                            </div>
                                            <div class="sellentt-col-xs-7 sellentt-col-md-9">
                                                {{ product.cat.name }} <br>
                                                <b>{{ product.name }}</b>
                                            </div>
                                            <div class="sellentt-col-xs-6 sellentt-col-md-2 h2">
                                                {{ product.quantity }}x {{ product.price_with_discounts | currency : 'BRL'}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="sellentt-col-xl-6">
                                        <div class="sellentt-row" style="color: rgb(80, 207, 80);">
                                            <div class="sellentt-col-xs-8 sellentt-col-md-2"></div>
                                            <div class="sellentt-col-xs-8 sellentt-col-md-8 font-weight-bold text-center">
                                                <i class="fa-solid fa-circle-check"></i> PRODUTO ADICIONADO NO CARRINHO
                                            </div>
                                            <div class="sellentt-col-xs-8 sellentt-col-md-2"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="product-wrapper card h-20 mt-4 float-right" *ngIf="productId">
                        <div class="sellentt-row px-3 py-4">
                            <div class="sellentt-col-xl-12">
                                <div class="sellentt-row">
                                    <div class="sellentt-col-x1-6">
                                        <div class="sellentt-row pb-5" style="font-size: 22px;">
                                            <div class="sellentt-col-xs-18 sellentt-col-md-12 font-weight-bold">
                                                <i class="fa-solid fa-screwdriver-wrench" style="color: orange"></i> RESUMO
                                            </div>
                                        </div>
                                        <div class="sellentt-row">
                                            <div class="sellentt-col-xs-8 sellentt-col-md-6">
                                                Valor do Produto:
                                            </div>
                                            <div class="sellentt-col-xs-8 sellentt-col-md-6 font-weight-bold text-right pb-3">
                                                {{ product.price_with_discounts | currency : 'BRL' }}
                                            </div>
                                        </div>
                                        <hr>
                                        <div class="sellentt-row">
                                            <div class="sellentt-col-xs-16 sellentt-col-md-12 text-center green-card">
                                                Valor total: <br>
                                                <span class="font-weight-bold text-center h1">
                                                    {{ product.quantity * product.price_with_discounts | currency : 'BRL' }} <br>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="sellentt-row pt-5" style="font-size: 20px;">
                                            <div class="sellentt-col-xs-18 sellentt-col-md-12">
                                                <button type="button" class="btn btn-warning w-100 font-weight-bold" (click)="goToCart()">
                                                    IR PARA O CARRINHO
                                                </button>
                                            </div>
                                        </div>
                                        <div class="sellentt-row mt-3" style="font-size: 20px;">
                                            <div class="sellentt-col-xs-18 sellentt-col-md-12">
                                                <button type="button" class="btn btn-outline-warning w-100 font-weight-bold" (click)="keepShopping()">
                                                    CONTINUAR COMPRANDO
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="sellentt-row p-5" *ngIf="!productId && (cart.products.length <= 0)">
                        <div class="sellentt-col-xl-12">
                            <div>
                                <div class="sellentt-row">
                                    <div class="sellentt-col-xl-12 text-center">
                                        <h2 class="font-weight-bold">
                                            O seu carrinho está vazio.
                                        </h2>
                                        <h4 class="font-weight-normal">
                                            Deseja olhar outros produtos similares?
                                        </h4>
                                        <button type="button" class="btn btn-warning btn-lg mt-3 font-weight-bold" (click)="keepShopping()">
                                            <i class="fa-solid fa-cart-shopping"></i> CONTINUAR COMPRANDO
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!loadedData"
    class="sk-spinner sk-spinner-three-bounce m-5 w-100 min-vh-100 d-flex justify-content-center align-items-center">
    <div class="sk-bounce1"></div>
    <div class="sk-bounce2"></div>
    <div class="sk-bounce3"></div>
</div>
