import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UrlService {
  public urlBase: string = '';

  constructor(private router: Router) {
    this.setUrlBase();
  }

  private setUrlBase() {
    const url = this.router['location']._platformLocation.location.origin; // Obtém a origem da URL
    // const url = 'https://bemfixa.sellentt.com.br/home';
    // const url = 'https://uzzyferramentas.sellentt.com.br/';
    // const url = 'https://suportescatarinense.sellentt.com.br/home';
    // const url = 'https://cafeculturabrasil.sellentt.com.br/'
    // const url = 'https://baly.sellentt.com.br/home';
    // const url = 'https://xando.sellentt.com.br/home';
    // const url = 'https://lojaodosdoces.sellentt.com.br/'
    // const url = 'https://alvapersonalcare.sellentt.com.br/'

    // Extraindo a parte base do subdomínio antes do primeiro "."
    this.urlBase = url.split('://')[1].split('.')[0];
  }

  getUrlBase(): string {
    return this.urlBase;
  }
}
