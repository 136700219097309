import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api/api.service';
import { CartService } from '../../services/carts/cart.service';
import { UserService } from '../../services/user/user.service';
import * as _ from 'lodash';
import * as numeral from 'numeral';
import { ToastrService } from '../../services/toastr/toastr-service.service';
import { FirebaseApp } from '@angular/fire';
import { AuthService } from '../../services/auth/auth.service';
import * as moment from 'moment';

import "firebase/functions";
import { LocalStorageService } from '../../services/local-storage/local-storage.service';
import { SalePolicyService } from "../../services/sale-policy/sale-policy.service";
import { OrderService } from "../../services/order/order.service";

import EfiPay from "payment-token-efi";
import { storage } from 'firebase';

declare var $: any;

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {

  store: {};
  only_shippings_linked_to_seller = false;
  freight_type = '';
  mostraTransportadora = false;
  showCifintegration = false;
  cif_integration = false;
  cifValue = 0;
  value_min_freight_fob: any;
  value_min_freight_cif: any;
  freightTableData: any;
  tableFreight: any;
  client_default_shipping = '';
  frete = '';
  freteFormated = '';
  totalCart = 0;
  orderCubage = 0;
  companyPolicies;
  ufFreight = '-';
  restrictionFreight;
  valueFreight = 0;
  didNotEnterAnyShippingRule;
  is_suframa = false;
  simulated_Suframa_descount = false;
  client_unit_discount = 0;
  resultSuframa;

  listaTeste = [
    { id: 1, text: '123' },
    { id: 2, text: '456' },
    { id: 3, text: '789' },
  ];

  listOfCustomFreights = [];

  objTypeFreight = [
    {
      name: 'CIF',
      id: 'CIF',
      priceTable: '',
      show: true,
    },
    {
      name: 'FOB',
      id: 'FOB',
      priceTable: '',
      show: true,
    },
  ];

  states = [
    { 'name': 'Acre', 'uf': 'AC', 'capital': 'Rio Branco' },
    { 'name': 'Alagoas', 'uf': 'AL', 'capital': 'Maceió' },
    { 'name': 'Amapá', 'uf': 'AP', 'capital': 'Macapá' },
    { 'name': 'Amazonas', 'uf': 'AM', 'capital': 'Manaus' },
    { 'name': 'Bahia', 'uf': 'BA', 'capital': 'Salvador' },
    { 'name': 'Ceará', 'uf': 'CE', 'capital': 'Fortaleza' },
    { 'name': 'Distrito Federal', 'uf': 'DF', 'capital': 'Brasilia' },
    { 'name': 'Espírito Santo', 'uf': 'ES', 'capital': 'Vitória' },
    { 'name': 'Goías', 'uf': 'GO', 'capital': 'Goiânia' },
    { 'name': 'Maranhão', 'uf': 'MA', 'capital': 'São Luis' },
    { 'name': 'Mato Grosso', 'uf': 'MT', 'capital': 'Cuiabá' },
    { 'name': 'Mato Grosso do Sul', 'uf': 'MS', 'capital': 'Campo Grande' },
    { 'name': 'Minas Gerais', 'uf': 'MG', 'capital': 'Belo Horizonte' },
    { 'name': 'Pará', 'uf': 'PA', 'capital': 'Belém' },
    { 'name': 'Paraíba', 'uf': 'PB', 'capital': 'João Pessoa' },
    { 'name': 'Paraná', 'uf': 'PR', 'capital': 'Curitiba' },
    { 'name': 'Pernambuco', 'uf': 'PE', 'capital': 'Recife' },
    { 'name': 'Piauí', 'uf': 'PI', 'capital': 'Teresina' },
    { 'name': 'Rio de Janeiro', 'uf': 'RJ', 'capital': 'Rio de Janeiro' },
    { 'name': 'Rio Grande do Norte', 'uf': 'RN', 'capital': 'Natal' },
    { 'name': 'Rio Grande do Sul', 'uf': 'RS', 'capital': 'Porto Alegre' },
    { 'name': 'Rondônia', 'uf': 'RO', 'capital': 'Porto Velho' },
    { 'name': 'Roraíma', 'uf': 'RR', 'capital': 'Boa Vista' },
    { 'name': 'Santa Catarina', 'uf': 'SC', 'capital': 'Florianópolis' },
    { 'name': 'São Paulo', 'uf': 'SP', 'capital': 'São Paulo' },
    { 'name': 'Sergipe', 'uf': 'SE', 'capital': 'Aracaju' },
    { 'name': 'Tocantins', 'uf': 'TO', 'capital': 'Palmas' }
  ];

  shippingSelected = '';
  shippingDescription;
  hasRuleByValueMin = false;
  freightRules = [];
  freight_value_type = '';
  restrictions;
  value_min_freight = 0;
  min_value_type = '-';
  totalWeigthProducts = 0;
  totalCubageProducts = 0;
  totalQuantityProducts;
  listStateTax;
  showShippingCompany = false;
  showShippings = true;
  shippigIsSelected = true;
  valueTotalCartWithDiscounts;
  previsionDateDaysDefault;
  previsionDate;

  orders_module = {
    allow_special_characters: true,
    can_edit_price: '',
    can_order_new_client: '',
    can_price_zero: true,
    can_register_new_client: '',
    order_status_new_client: '',
    payment_method_new_client: '',
    prevision_date_days_default: 0,
    qtd_is_decimal: ''
  };

  // VARIAVEIS PARA MOSTRAR OU NAO INFOS NA TELA
  shipping_company_non_adherent_list = true;
  decimal_limit = 2;
  freight_non_adherent_list = false;
  value_min_freight_non_adherent_list = false;
  require_shipping = true;
  require_shipping_cif = true;
  currency = 'R$';
  valueMinFreightFobAgree;
  valueMinFreightCifAgree;
  valueMinFreightCifShippingAgree;
  goToSummaryScreen = true;
  non_adherent_list = [];
  higherMinimumValue;
  quantityByWeight = 0;
  has_min_weight_freight = false;
  min_weight = 0;
  salesmanObj = {
    id: ''
  };
  cif_with_shipping = false;
  cif_shipping_description = '';
  listShippingCompany = [];
  listTableFreights = [];
  listRestrictionsFreights = [];
  tableFreightFob: any;
  hasFreightData = false;
  citiesOfRestrictionNotFormated = [];
  freightTableConfig = '';
  storeFreightTable = '';
  codeTableOfClient;
  showCIF = true;
  showFOB = true;
  showCIF_SHIPPING = true;
  showCustomFreight = true;
  freightTypePriceTable;
  freightTypeOrderTypeEcommerce;
  freightTypeStore;
  fields_ecommerce;
  showFreightInfo;
  removeTextIpiStPauta = false;
  clientId;
  firebaseId;
  finishButtonText = '';

  cart: {};
  user: {};

  cartProducts = [];
  paymentMethods = [];
  paymentTerms = [];
  paymentTermsList = [];
  pageOfItems = [];

  priceTableSelected;

  loadedData = false;
  showCart = true;
  showPayment = false;
  showSummary = false;
  isLoggedIn = false;

  fixedValue = 0;
  dicountValue = 0;


  cartTotalProducts = 0;
  cartTotalWithDiscounts = 0;
  cartTotalItems = 0;
  cartTotalDiscountPercentage = 0;
  cartTotalDiscount = 0;
  span = 1;
  orderTypeId;
  orderTypeCode;

  paymentMethod;
  paymentTerm;
  payment_type = '';
  payment_terms_can_be_empty = false;
  default_price_table = '';

  b2bConfigs;

  orderType;

  coupons: any[] = [];
  discountCode: '';
  discount = 0;

  private card = {
    number: '',
    holder_name: '',
    exp_month: '',
    exp_year: '',
    cvv: '',
  };

  domain;

  MensageCoupon = false;
  apliCoupon = false;
  showPaymentMessage = false;
  showPaymentTerms = false;
  showEfipayForm = false;
  showEfipayCreditCard = false;
  showEfipayPix = false;

  coupon = '';
  price_with_discounts = 0;
  userGroups: any[] = [];
  validCoupons: any[] = [];
  prodPriceNoDiscounts = 0;
  freightSelect = [];
  showSelectFreight = false;
  order_types_require_freight_calc = [];
  order_value_dont_need_freight_calc = 0;
  prevision_date = false;
  controlVariableOfFreightCifIntegration = false;

  showBudget: boolean = false;
  selectedType;
  showStock = false;
  infoClient;
  companyId = '';
  listTypeFreights;
  priceTableIdOrder = '';

  storeId;

  companyPdId = '';
  userId;
  cartRequest;
  userIdFireBase;

  efipayCardBrand = '';
  efipayInstallments = [];
  efipayPaymentToken = '';
  allow_b2b_checkout_efipay = false;
  ecommerceConfigs = {};
  type_final_price = 1;


  configType = 0;
  generalEnableStockUsage = '';
  generalApplyInventoryConfigsInEcommerce = '';
  generalListProductsStockZero = '';
  generalBlockLargestQuantityInCart = '';
  generalValidateLargestQuantityInOrder = '';
  ignoreQuotaStock = '';
  alsoAvailableForEcommerce = '';
  listProductsWithZeroStock = '';
  blockQuantityGreaterThanStockInCart = '';
  validateQuantityGreaterThanStockInOrder = '';
  standardPriceTableId = '';

  constructor(
    private cartService: CartService,
    private router: Router,
    private userService: UserService,
    private apiService: ApiService,
    private toastrService: ToastrService,
    private firebase: FirebaseApp,
    private authService: AuthService,
    private localStorageService: LocalStorageService,
    private salePolicyService: SalePolicyService,
    private orderService: OrderService,

  ) {
    this.clientId = this.localStorageService.get('pd_id');
    this.firebaseId = this.localStorageService.get('biaction_id');
    this.orderTypeId = this.localStorageService.get('selectedOrderTypeId');
    this.orderTypeCode = this.localStorageService.get('selectedOrderTypeCode');

    let url = this.router['location']._platformLocation.location.origin;
    this.domain = url.split('://')[1];
    this.ecommerceConfigs = this.authService.setEcommerceConfigs();

    this.isLoggedIn = this.authService.isLoggedIn();
  }

  ngOnInit() {
    this.getConfigs();
    this.checkIfCartEmpty();
    this.getCoupons();
    this.getEcommerceConfigs();
    this.getListShippingCompanys();
    this.getListRestrictionsFreights();
    this.getListFreights();
    this.type_order_selected();
    this.getValuesOfStorage();
  }

  getValuesOfStorage() {
    this.configType = this.localStorageService.get('configType');
    this.generalEnableStockUsage = this.localStorageService.get('generalEnableStockUsage');
    this.generalApplyInventoryConfigsInEcommerce = this.localStorageService.get('generalApplyInventoryConfigsInEcommerce');
    this.generalListProductsStockZero = this.localStorageService.get('generalListProductsStockZero');
    this.generalBlockLargestQuantityInCart = this.localStorageService.get('generalBlockLargestQuantityInCart');
    this.generalValidateLargestQuantityInOrder = this.localStorageService.get('generalValidateLargestQuantityInOrder');
    this.ignoreQuotaStock = this.localStorageService.get('ignoreQuotaStock');
    this.alsoAvailableForEcommerce = this.localStorageService.get('alsoAvailableForEcommerce');
    this.listProductsWithZeroStock = this.localStorageService.get('listProductsWithZeroStock');
    this.blockQuantityGreaterThanStockInCart = this.localStorageService.get('blockQuantityGreaterThanStockInCart');
    this.validateQuantityGreaterThanStockInOrder = this.localStorageService.get('validateQuantityGreaterThanStockInOrder');

  }

  getEcommerceConfigs() {
    this.salePolicyService.getEcommercePolice(this.firebaseId).then((res) => {
      if (res) {
        const budget = res['show_budget'];
        if (budget == true) {
          this.showBudget = true;
        } else {
          this.showBudget = false;
        }
        this.removeTextIpiStPauta = res['remove_ipi_st_pauta'] ? res['remove_ipi_st_pauta'] : false;
        this.standardPriceTableId = res && res['ecommerce_price_table_id'] ? res['ecommerce_price_table_id'] : '';
      }
    });
  }

  getListRestrictionsFreights() {
    this.salePolicyService.listFreightsRestrictions(this.firebaseId, (err, res) => {
      if (err) {
        console.log('Error.:', err);
      } else {
        this.listRestrictionsFreights = res;
      }
    });
  }

  getListFreights() {
    this.salePolicyService.listFreights(this.firebaseId, (err, res) => {
      if (err) {
        console.log('Error.:', err);
      } else {
        this.listTableFreights = res;
      }
    });
  }

  filterActiveShipping(shippings) {
    const shippingsFiltered = [];
    const restrictions = this.store && this.store['restrictions'] ?
      this.store['restrictions'] : [];

    if (shippings.length > 0) {
      shippings.forEach(shipping => {
        if (shipping.data.active === undefined || shipping.data.active) {
          if (this.client_default_shipping !== '' && shipping.id === this.client_default_shipping) {
            shippingsFiltered.push(shipping);
          } else if (shipping.data.restrictions !== undefined && shipping.data.restrictions.length > 0) {
            for (let i = 0; i < restrictions.length; i++) {
              if (shipping.data.restrictions.includes(restrictions[i])) {
                shippingsFiltered.push(shipping);
                break;
              }
            }
          } else {
            shippingsFiltered.push(shipping);
          }
        }
      });
    }
    return shippingsFiltered;
  }

  getInfosClient() {
    this.apiService.getStoresByCompany('?company_id=' + this.companyId).subscribe((res) => {
      if (res['error']) {
        console.error(res['message']);
      } else {
        this.infoClient = res['resp']['stores'];
        if (this.infoClient !== undefined) {
          this.infoClient.forEach(cli => {
            cli['client_since'] = cli['client_since'] ? moment(cli['client_since'], 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY') : '-';
          });
        }
      }
    });

    setTimeout(() => {
      this.getListSCustomFreights();
    }, 1000);
  }


  checkRestrictionsTableFreight() {
    let finalListFreights;

    if (this.storeFreightTable !== '' && this.storeFreightTable !== undefined) {
      finalListFreights = this.listTableFreights.find(freight => freight.id === this.storeFreightTable);
    } else {
      if (this.companyPolicies) {
        if (this.listTableFreights !== undefined && (this.companyPolicies.table_freight_fob_id !== undefined && this.companyPolicies.table_freight_fob_id !== '')
          || (this.companyPolicies.table_freight_id !== undefined && this.companyPolicies.table_freight_id !== '')) {

          if (this.frete === 'FOB') {
            finalListFreights = this.listTableFreights.find(freight => freight.id === this.companyPolicies.table_freight_fob_id);
          }

          if (this.frete === 'CIF_SHIPPING' || this.frete === 'CIF') {
            finalListFreights = this.listTableFreights.find(freight => freight.id === this.companyPolicies.table_freight_id);
          }
        }
      }
    }

    if (finalListFreights === undefined) {
      //quando for CIF ou CIF_Shipping e estiver configurado para valor minino, não vai ter lista de tabelas de frete
      this.freightTableData = [];
    } else {
      if (finalListFreights['data']['scope'] !== 'order') {
        this.freightTableData = finalListFreights['data'];
        this.listStateTax = this.freightTableData.state_tax;

        const listOfCustomRestriction = this.listStateTax.filter(stateTax => stateTax.restriction == 'Custom');
        const customRestrictionsIds = listOfCustomRestriction.map(stateTax => stateTax.restriction_id);
        const resultFreightRestriction = this.listRestrictionsFreights.filter(restrictionFreight => customRestrictionsIds.includes(restrictionFreight.id));
        const cityClient = this.store['city'].normalize('NFD').replace(/[^a-zA-Zs]/g, '').toUpperCase();
        let citiesOfRestriction;

        let isArray = function (a) {
          return (!!a) && (a.constructor === Array);
        };

        for (let i = 0; i < resultFreightRestriction.length; i++) {
          citiesOfRestriction = resultFreightRestriction[i].data.cities

          if (isArray(citiesOfRestriction)) {
            let citiesFormatteds = [];
            let citiesCutomer;
            for (let i = 0; i < citiesOfRestriction.length; i++) {
              citiesFormatteds.push(citiesOfRestriction[i].normalize('NFD').replace(/[^a-zA-Zs]/g, '').toUpperCase());
            }

            const checksIfTheCustomerCityIsOnListRestrictionCities = citiesFormatteds.filter(citiesRestriction => citiesRestriction == cityClient);

            if (checksIfTheCustomerCityIsOnListRestrictionCities.length > 0) {
              citiesCutomer = false;
            } else {
              this.listStateTax.forEach(listaStateTax => {
                if (listaStateTax.restriction_id === resultFreightRestriction[i].id) {

                  //TODO: extrair para uma função
                  var removeByAttr = function (arr, attr, value) {
                    var i = arr.length;
                    while (i--) {
                      if (arr[i]
                        && arr[i].hasOwnProperty(attr)
                        && (arguments.length > 2 && arr[i][attr] === value)) {
                        arr.splice(i, 1);
                      }
                    }
                    return arr;
                  }
                  removeByAttr(this.listStateTax, 'restriction_id', resultFreightRestriction[i].id);
                }
              });
            }

          } else {

            const stateClient = this.store['state'].normalize('NFD').replace(/[^a-zA-Zs]/g, '').toUpperCase();;
            const listaDeCidadesDaRestriçãoDoEstadoDoCliente = citiesOfRestriction[stateClient];

            if (listaDeCidadesDaRestriçãoDoEstadoDoCliente !== undefined) {

              let citiesFormateds = [];
              for (let i = 0; i < listaDeCidadesDaRestriçãoDoEstadoDoCliente.length; i++) {
                citiesFormateds.push(listaDeCidadesDaRestriçãoDoEstadoDoCliente[i].normalize('NFD').replace(/[^a-zA-Zs]/g, '').toUpperCase());
              }

              const customerCityIsOnListOfRestrictions = citiesFormateds.filter(test => test == cityClient);
              let citiesCutomer;

              if (customerCityIsOnListOfRestrictions.length > 0) {
                citiesCutomer = false;
              } else {
                this.listStateTax.forEach(listaStateTax => {
                  if (listaStateTax.restriction_id === resultFreightRestriction[i].id) {

                    var removeByAttr = function (arr, attr, value) {
                      var i = arr.length;
                      while (i--) {
                        if (arr[i]
                          && arr[i].hasOwnProperty(attr)
                          && (arguments.length > 2 && arr[i][attr] === value)) {
                          arr.splice(i, 1);
                        }
                      }
                      return arr;
                    }
                    removeByAttr(this.listStateTax, 'restriction_id', resultFreightRestriction[i].id);
                  }
                });
              }
            } else {
              this.listStateTax.forEach(listaStateTax => {
                if (listaStateTax.restriction_id === resultFreightRestriction[i].id) {
                  this.listStateTax.splice(i, 1);
                }
              });
            }
          }
        }

      } else {
        this.freightTableData = [];

      }
    }
  }

  getListSCustomFreights() {
    const that = this;
    that.salePolicyService.getCustomFreights(this.firebaseId, (err, res) => {
      if (err) {
        console.log('Erro ao recuperar os fretes personalizados', err);
        return;
      } else {
        this.listOfCustomFreights = res;

        this.listOfCustomFreights.forEach(item => {
          this.objTypeFreight.push({ name: item.data.description, id: item.data.description, priceTable: item.data.freightTable, show: true });
        })

        if (this.cif_shipping_description) {
          let cifWithShipping = 'CIF_' + this.cif_shipping_description;
          this.objTypeFreight.push({ name: cifWithShipping, id: 'CIF_SHIPPING', priceTable: '', show: true });
        }
      }

      if (this.store && this.store['freight_type'] !== '') {

        let typeOfFreights = this.store['freight_type'];

        this.objTypeFreight.forEach(item => {
          if (item.id !== typeOfFreights) {
            item.show = false;
          }
        });
      }
    });

    let typeFreightOfClient = String(this.infoClient[0]['freight_type']).toUpperCase();

    if (typeFreightOfClient !== '') {
      this.objTypeFreight = this.objTypeFreight.filter(item => item.name.toUpperCase() === typeFreightOfClient);
    }
  }

  getListShippingCompanys() {
    const that = this;
    that.salePolicyService.getShippingCompany(this.firebaseId, (err, res) => {
      if (err) {
        console.log('Erro ao listar transportadoras', err);
        return;
      } else {
        const listOfShipping = this.filterActiveShipping(res);

        //verifica se deve mostrar somente transportadoras vinculadas ao vendedor
        if (this.only_shippings_linked_to_seller && this.salesmanObj.id !== '') {
          listOfShipping.forEach(shipping => {
            const find = _.find(shipping.data.sellers, function (o) {
              return o === that.salesmanObj.id;
            });
            if (find !== undefined) {
              that.listShippingCompany.push(shipping);
            }
          });
        } else {
          that.listShippingCompany = listOfShipping;
        }
      }
    });
  }

  // PEGAR O TIPO DE PEDIDO ( PEDIDO OU ORÇAMENTO )
  type_order_selected() {
    this.selectedType = $('#type_order').val();
    if (this.selectedType == undefined) {
      this.selectedType = 'order';
    }
  }


  // PEGA O TIPO DE FRETE SELECIONADO
  setFreightSelected() {
    this.frete = this.freight_type !== '' ? this.freight_type.toUpperCase() : $('#freight').val();
    this.mostraTransportadora = false;

    if (this.frete === 'CIF_SHIPPING') {
      this.showCifintegration = false;
      this.mostraTransportadora = true;
      this.cifValue = 0;
      this.freightTableData = this.tableFreight;
      this.value_min_freight_cif = parseFloat(this.companyPolicies.value_min_fob);
      this.showShippings = true;
      this.checkRestrictionsTableFreight();
      this.getMinValueFreight();

    } else if (this.frete === 'CIF') {
      this.showCifintegration = this.cif_integration;
      this.mostraTransportadora = false;
      this.cifValue = 0;
      this.valueFreight = 0;
      this.value_min_freight_cif = parseFloat(this.companyPolicies.value_min_cif);
      this.showShippings = false;
      this.checkRestrictionsTableFreight();
      this.getMinValueFreight();
      if (this.showCifintegration && !this.controlVariableOfFreightCifIntegration) {
        this.getIntegrationFreightValue();
      }

    } else if (this.frete === 'FOB') {
      this.showCifintegration = false;
      this.mostraTransportadora = true;
      this.cifValue = 0;
      this.value_min_freight_fob = parseFloat(this.companyPolicies.value_min_fob);
      this.showShippings = true;
      this.checkRestrictionsTableFreight();
      this.getMinValueFreight();

    } else if (this.frete !== '') {

      let find = this.objTypeFreight.find(item => item.name === this.frete)
      if (find) {
        let findTable = this.listTableFreights.find(table => table.id === find.priceTable)
        if (findTable) {
          this.freightTableData = findTable;
          this.getMinValueFreight();
        }
      }

      // this.checkRestrictionsTableFreight();
      // this.getMinValueFreight();

    } else if (this.frete === '') {
      this.showCifintegration = false;
      this.cifValue = 0;
      this.valueFreight = 0;
      $('.oculta').addClass('ocultaCad');
    }

    // this.checkRestrictionsTableFreight();
    // this.getMinValueFreight();
    this.valueTotalCartWithDiscounts = this.cartTotalWithDiscounts + this.valueFreight;
  }

  getIntegrationFreightValue() {
    const products = [];
    this.cartProducts.forEach(product => {
      if (!product.isTotal) {
        products.push({ code: product.code, quantity: product.quantity });
      }
    });
    const data = {
      clientId: String(this.store['id'] || ''),
      clientCEP: this.store['address_zipcode_delivery'] !== null && this.store['address_zipcode_delivery'] !== undefined ? this.store['address_zipcode_delivery'] : '',
      orderValue: this.valueTotalCartWithDiscounts,
      products: products,
      source: 'new-ecommerce',
      company: {
        id: this.ecommerceConfigs['biaction_id'],
      },
    };
    this.freightSelect = [];

    const getFreight = this.firebase.functions().httpsCallable('getFreightPrice');
    getFreight(data).then(result => {
      if (result.data.success) {
        this.showSelectFreight = true;
        const services = result.data.services;
        services.forEach((service, i) => {
          service['id'] = i;
          service['priceFormatted'] = this.formatValue(service.price);
        });
        this.freightSelect = services;
        this.controlVariableOfFreightCifIntegration = true;
      } else {
        this.showSelectFreight = false;
        this.toastrService.showWarning(result.data.message);
      }
    }).catch(error => {
      console.log('ERROR', error);
      this.toastrService.showError('Desculpe, ocorreu um erro ao calcular o frete.');
      this.showSelectFreight = false;
    });
  }

  getCifIntegrationValue() {
    const id = $('#cif_integration_type').val();
    if (id === '') {
      this.valueFreight = 0;
    } else if (id !== undefined && id !== null) {
      const value = this.freightSelect[id] !== undefined && this.freightSelect[id].price !== undefined ? this.freightSelect[id].price : 0;
      this.valueFreight = value;
    }

    this.valueTotalCartWithDiscounts = this.cartTotalWithDiscounts + this.valueFreight;
  }

  formatValue(valueToFormat) {
    const value = !isNaN(parseFloat(valueToFormat)) ? valueToFormat : 0;
    let valueFormatted = this.decimal_limit === 4 ? numeral(value).format('$ 0,0.0000') :
      this.decimal_limit === 3 ? numeral(value).format('$ 0,0.000') : numeral(value).format('$ 0,0.00');

    if (this.currency === 'US$') {
      valueFormatted = valueFormatted.replace('R$', 'US$');
    } else if (this.currency === 'AU') {
      valueFormatted = valueFormatted.replace('R$', 'AU');
    }

    return valueFormatted;
  }

  checkValueMinFreightFobAgree() {
    if (this.valueTotalCartWithDiscounts < this.value_min_freight_fob) {
      this.goToSummaryScreen = false;
      this.toastrService.showWarning(`O valor mínimo (definido pelo frete fob: R$${this.value_min_freight_fob}) para realizar o pedido não foi alcançado`);
    } else {
      this.goToSummaryScreen = true;
    }
  }

  checkValueMinFreightCifAgree() {
    if (this.value_min_freight > 0) {
      this.value_min_freight_cif = this.value_min_freight;
    }

    if (this.valueTotalCartWithDiscounts < this.value_min_freight_cif) {
      this.goToSummaryScreen = false;
      this.toastrService.showWarning(`O valor mínimo (definido pelo frete cif: R$${this.value_min_freight_cif}) para realizar o pedido não foi alcançado`);
    } else {
      this.goToSummaryScreen = true;
    }
  }

  getClientRestriction() {
    const state = this.store['state'];
    const city = this.store['city'].normalize('NFD').replace(/[^a-zA-Zs]/g, '').toUpperCase();
    const index = this.states.map(function (e) {
      return e.uf;
    }).indexOf(state);

    if (index !== -1) {
      if (city === this.states[index].capital.normalize('NFD').replace(/[^a-zA-Zs]/g, '').toUpperCase()) {
        this.restrictions = 'Capital';
      } else {
        this.restrictions = 'Interior';
      }
    }
  }

  getMinValueFreight() {
    if (this.freightTableData && Object.keys(this.freightTableData).length > 0) {
      this.getClientRestriction();
      let rules;
      this.hasRuleByValueMin = false;
      this.freightRules = [];
      this.freight_value_type = '';

      if (this.store['restriction_ids'] !== undefined && this.store['restriction_ids'] !== '') {
        const state_tax = this.freightTableData.state_tax !== undefined ? this.freightTableData.state_tax : [];
        state_tax.forEach(e => {
          if (e.restriction_id !== undefined && this.store['restrictions'].includes(e.restriction_id)) {
            this.freightRules.push(e);
          }
        });
      } else {
        if (this.freightTableData.state_tax !== undefined || this.freightTableData.data.state_tax !== undefined) {

          if (this.freightTableData.state_tax) {
            Object.values(this.freightTableData.state_tax).forEach(e => {
              if ((e['restriction'] === this.restrictions || (e['restriction'] === "Custom")) && e['uf'] === this.store['state']) {
                this.freightRules.push(e);
              }
            });
          } else if (this.freightTableData.data.state_tax) {
            Object.values(this.freightTableData.data.state_tax).forEach(e => {
              if ((e['restriction'] === this.restrictions || (e['restriction'] === "Custom")) && e['uf'] === this.store['state']) {
                this.freightRules.push(e);
              }
            });
          }
        }
      }
      this.freightRules.sort(function (a, b) {
        return (a.min_value > b.min_value) ? 1 : ((b.min_value > a.min_value) ? -1 : 0);
      });

      if (this.freightRules.length > 0) {
        rules = this.freightRules;
      }

      if (rules !== undefined) {
        let taxRuleValue = null;
        let taxRuleWeight = null;
        let taxRuleCubage = null;

        let setFreight = [];

        rules.forEach(rule => {
          if (this.totalWeigthProducts >= rule['min_value'] && rule['min_value_type'] === 'peso') {

            setFreight.push(rule);
            // rules = rule;
          }

          if (this.valueTotalCartWithDiscounts >= rule['min_value'] && rule['min_value_type'] === 'value') {
            // rules = rule;
            setFreight.push(rule);
          }

          if (this.totalCubageProducts >= rule['min_value'] && rule['min_value_type'] === 'cubage') {
            // rules = rule;
            setFreight.push(rule);
          }
        });

        setFreight.forEach(e => {
          if (e.min_value_type === 'peso') {
            if (e.min_value !== undefined && this.totalWeigthProducts >= e.min_value) {
              taxRuleWeight = (e);
            }
          } else if (e.min_value_type === 'value') {
            if (e.min_value !== undefined && this.cartTotalWithDiscounts >= e.min_value) {
              taxRuleValue = (e);
            }
          } else if (e.min_value_type === 'cubage') {
            if (e.min_value !== undefined && this.totalCubageProducts >= e.min_value) {
              taxRuleCubage = (e);
            }
          }
        });

        if (taxRuleWeight !== null) {
          rules = taxRuleWeight;
          this.higherMinimumValue = parseInt(rules.min_value, 10) > this.totalWeigthProducts;
          this.has_min_weight_freight = true;
          this.min_weight = rules['min_value'] !== undefined ? rules['min_value'] : 0;
        } else if (taxRuleValue !== null) {
          rules = taxRuleValue;
          this.higherMinimumValue = parseInt(rules.min_value, 10) > this.valueTotalCartWithDiscounts;
        } else if (taxRuleCubage !== null) {
          rules = taxRuleCubage;
          this.higherMinimumValue = parseInt(rules.min_value, 10) > this.orderCubage;
        } else {
          if (rules.length > 0) {
            rules = rules[0];
            this.didNotEnterAnyShippingRule = rules;
          }
        }

        this.freight_value_type = rules.value_type !== undefined ? rules.value_type : '';
        this.value_min_freight = rules && rules.min_value ? parseFloat(rules.min_value) : 0;
        this.min_value_type = rules.min_value_type === undefined ? '' : rules.min_value_type;

        if (this.freight_value_type === 'abs') {
          this.calculateValueFreight(rules);
        }

        if (this.min_value_type === 'value') {
          this.calculateValueFreight(rules);

        }
        if (this.min_value_type === 'peso') {
          this.calculateValueFreight(rules);
        }

        if (this.min_value_type === 'cubage') {
          this.calculateValueFreight(rules);
        }
      } else {
        this.hasFreightData = false;
        this.min_value_type = '';
        this.valueFreight = 0;

        const frete = this.freight_type !== '' ? this.freight_type.toUpperCase() : $('#freight').val();

        if (frete === 'FOB') {
          this.value_min_freight = this.companyPolicies && this.companyPolicies.value_min_fob ?
            parseFloat(this.companyPolicies.value_min_fob) : 0;
        } else {
          this.value_min_freight = this.companyPolicies && this.companyPolicies.value_min_cif ?
            parseFloat(this.companyPolicies.value_min_cif) : 0;
        }
      }

    } else {
      const frete = this.freight_type !== '' ? this.freight_type.toUpperCase() : $('#freight').val();
      if (frete === 'FOB') {
        this.value_min_freight = this.companyPolicies && this.companyPolicies.value_min_fob ?
          parseFloat(this.companyPolicies.value_min_fob) : 0;
      } else {
        this.valueFreight = 0;

        this.value_min_freight = this.companyPolicies && this.companyPolicies.value_min_cif ?
          parseFloat(this.companyPolicies.value_min_cif) : 0;
      }
    }
  }

  calculateValueFreight(rules: any) {
    if (rules.value_type === "perc") {
      if (rules.value !== '' && rules.value !== undefined) {
        this.valueFreight = (this.cartTotalWithDiscounts) * (rules.value / 100);
      }
    }

    if (rules.value_type === "abs") {
      this.valueFreight = rules.value;
    }

    if (rules.value_type === "peso") {
      if (this.totalWeigthProducts !== null || this.totalWeigthProducts !== undefined || this.totalWeigthProducts !== 0) {
        this.valueFreight = (this.totalWeigthProducts) * rules.value;
      } else {
        this.valueFreight = 0;
      }
    }
  }

  getConfigs() {
    this.salePolicyService.getOrdersModule(this.firebaseId).then((res) => {
      if (res) {
        this.type_final_price = res['type_final_price'] && res['type_final_price'] !== '' ? parseInt(res['type_final_price'], 10) : 1;
        this.payment_type = res['payment_type'] !== undefined ? res['payment_type'] : '3';
        this.previsionDateDaysDefault = res['prevision_date_days_default'] !== undefined ? res['prevision_date_days_default'] : -1;
        this.payment_terms_can_be_empty = res['payment_terms_can_be_empty'] !== undefined ?
          res['payment_terms_can_be_empty'] : false;

        this.non_adherent_list = res['non_adherent_list'] !== undefined ? res['non_adherent_list'] : [];

        if (res !== undefined && this.non_adherent_list !== undefined && this.non_adherent_list.length > 0) {

          this.non_adherent_list.forEach(element => {
            if (element === 'freight') {
              this.freight_non_adherent_list = true;
            }
            if (element === 'value_min_freight') {
              this.value_min_freight_non_adherent_list = true;
            }
            if (element === 'shipping_company' || element === 'shipping_company_cif') {
              this.shipping_company_non_adherent_list = true;
            }
          });
        }
      }
    });

    this.salePolicyService.getPolices(this.firebaseId).then((res) => {
      this.companyPolicies = res;
      this.fields_ecommerce = res['fields_ecommerce'] !== undefined ? res['fields_ecommerce'] : {};
      this.showStock = res['fields']['stock_ecommerce'] !== undefined ? res['fields']['stock_ecommerce'] : false;
      this.showFreightInfo = this.fields_ecommerce['freight']
      this.require_shipping = res['require_shipping'] !== undefined ? res['require_shipping'] : true;
      this.require_shipping_cif = res['require_shipping_cif'] !== undefined ? res['require_shipping_cif'] : true;
      //verifica se pedido CIF pode escolher transportadora
      this.cif_with_shipping = res['cif_with_shipping'] !== undefined ? res['cif_with_shipping'] : false;
      this.cif_integration = res['cif_integration'] !== undefined ? res['cif_integration'] : false;
      this.cif_shipping_description = res['cif_shipping_description'] !== undefined ? res['cif_shipping_description'] : '';
      this.only_shippings_linked_to_seller = res['only_shippings_linked_to_seller'] !== undefined ?
        res['only_shippings_linked_to_seller'] : false;
      this.order_types_require_freight_calc = res['cif_integration_options'] !== undefined ? res['cif_integration_options']['order_types_require_freight_calc'] : [];
      this.order_value_dont_need_freight_calc = res['cif_integration_options'] !== undefined ? res['cif_integration_options']['order_value_dont_need_freight_calc'] : 0;
      this.prevision_date = this.fields_ecommerce['prevision_date'] !== undefined ? this.fields_ecommerce['prevision_date'] : true;
    });
  }

  checkIfCartEmpty() {
    const isEmpty = this.cartService.isCartEmpty();

    if (isEmpty) {
      this.router.navigate(['/precart']);
    } else {
      this.router.navigate(['/cart']);
      this.getUser();
    }
  }

  getUser() {
    this.userService.getUserData().then((res) => {
      if (!res['error']) {
        this.user = res['user'];
        this.userId = this.user['id'];
        this.storeId = res['user']['store_id'];
        this.companyPdId = res['user']['client']['biaction_id'];
        this.store = res['user']['store'];
        this.store['city'] = this.store['address_city'];
        this.store['state'] = this.store['address_state'];
        this.salesmanObj.id = res['user']['seller'] && res['user']['seller']['code'] ? res['user']['seller']['code'] : '';
        this.b2bConfigs = this.user['client'];
        this.companyId = res['user']['store']['company_id'];

        if (res['user']['store']['is_suframa'] == 1) {
          this.is_suframa = true;
        }

        if (this.store && this.store['freight_type']) {
          this.freightTypeStore = this.store['freight_type'].normalize('NFD').replace(/[^a-zA-Zs]/g, '').toUpperCase();
        }


        if (this.b2bConfigs.order_table_fields !== null && this.b2bConfigs.order_table_fields !== '""' &&
          typeof this.b2bConfigs.order_table_fields !== 'undefined') {
          this.b2bConfigs.order_table_fields = JSON.parse(this.b2bConfigs.order_table_fields);

          this.b2bConfigs.order_table_fields.forEach((item) => {
            if (!item.is_active) {
              this.span += 1;
            }
          });

          this.span = Math.ceil(9 / this.span);

          this.b2bConfigs.order_table_fields = this.b2bConfigs.order_table_fields.reduce(
            (obj, item) => Object.assign(obj, { [item.id]: item.is_active }), {});
        } else {
          this.b2bConfigs.order_table_fields = {
            table_price: true,
            payment_method: true,
            shipping: true,
            unit_price: true,
            ipi: true,
            st: true,
            final_price: true,
            liquid_subtotal: true,
            gross_subtotal: true,
          }
        }
      }
      this.getInfosClient();
      this.getOrderTypes();
      this.userFire();
    });
  }

  userFire() {

    const data = {
      source: 'new-ecommerce',
      company: this.companyPdId,
    };

    this.apiService.getUserFireId(data).subscribe((res) => {
      if (!res['error']) {
        this.userIdFireBase = res['userID']
      } else {

      }
    });
  }


  getOrderTypes() {
    const that = this;

    this.apiService.getOrderTypes(this.isLoggedIn, this.clientId).subscribe((res) => {
      if (!res['error']) {
        const index = res['order_types'].map(function (e) { return e.id; }).indexOf(this.orderTypeCode);
        this.orderType = index !== -1 ? res['order_types'][index] : {};
        this.freightTypeOrderTypeEcommerce = this.orderType?.data?.ecommerce?.freight
          ? this.orderType.data.ecommerce.freight
            .normalize('NFD')
            .replace(/[^a-zA-Z\s]/g, '')
            .toUpperCase()
          : '';

        this.getPriceTables();
      } else {
        this.getPriceTables();
      }
    });
  }

  getPriceTables() {
    this.apiService.getPriceTablesAll().subscribe((res) => {
      if (!res['error']) {
        const priceTables = res['price_tables'];
        const clientPriceTable = this.user['store'].default_price_table;
        const index = priceTables.map(function (e) { return e.id; }).indexOf(clientPriceTable);
        let code = '';
        if (index !== -1) {
          code = priceTables[index] && priceTables[index].code ? priceTables[index].code : '';
        } else {
          code = this.default_price_table;
          if (!code) {
            if (this.standardPriceTableId) {
              code = this.standardPriceTableId;
            } else {
              this.toastrService.showError('Cliente sem tabela de preço padrão, por favor, atualize o cadastro e tente novamente.');
              return;
            }

          } 
        }
        if (code && code !== '') {
          this.apiService.getPriceTableById(code).subscribe(res => {
            if (!res['error']) {
              this.priceTableSelected = res['price_tables'];

              if (this.priceTableSelected && this.priceTableSelected['data']['freight_type']) {
                this.codeTableOfClient = this.priceTableSelected['data']['freight_table'];
                this.freightTypePriceTable = this.priceTableSelected['data']['freight_type'].normalize('NFD').replace(/[^a-zA-Zs]/g, '').toUpperCase();
              }

              if (this.store !== null && this.store !== undefined) {
                this.freightTableConfig = this.store['freight_table_config'];
                if (this.freightTableConfig === 'store') {
                  this.storeFreightTable = this.store['freight_table']
                }

                if (this.freightTableConfig === 'price_table') {
                  this.storeFreightTable = this.codeTableOfClient;
                }
              }
              this.getPaymentMethods();
            } else {
              this.getPaymentMethods();
            }
          });
        } else {
          this.getPaymentMethods();
        }
      } else {
        this.getPaymentMethods();
      }
    });
    this.loadCart();
  }

  getPaymentMethods() {
    const store = this.user['store'];
    this.apiService.getPaymentMethods().subscribe((res) => {
      if (!res['error']) {
        const methods = res['payment_method'];

        if (this.orderType.data['is_payment_fixed'] !== undefined && this.orderType.data['is_payment_fixed'] &&
          this.orderType.data['payment_method_fixed'] !== undefined &&
          this.orderType.data['payment_method_fixed'] !== '') {
          const find = _.find(methods, ['code', this.orderType.data['payment_method_fixed']]);
          this.paymentMethods.push(find);
        } else if (this.priceTableSelected && this.priceTableSelected['data'] &&
          this.priceTableSelected['data']['payment_methods'] &&
          Object.keys(this.priceTableSelected['data']['payment_methods']).length > 0) {
          const tableMethods = Object.keys(this.priceTableSelected['data']['payment_methods']);
          methods.forEach(method => {
            if (tableMethods.includes(method['code'])) {
              this.paymentMethods.push(method);
            }
          });
        } else if (store.payment_method_list !== undefined && store.payment_method_list !== null &&
          store.payment_method_list !== '') {
          const storeMethods = store.payment_method_list.split(',');
          methods.forEach(method => {
            if (storeMethods.includes(method['code'])) {
              this.paymentMethods.push(method);
            }
          });
        } else if (store.payment_method_id !== undefined && store.payment_method_id !== null &&
          store.payment_form_id !== '') {
          const find = _.find(methods, ['id', store.payment_method_id]);
          this.paymentMethods.push(find);
        } else if (this.orderType.data.payment_method_list && this.orderType.data.payment_method_list.length > 0) {
          methods.forEach(method => {
            if (this.orderType.data.payment_method_list.includes(method['code'])) {
              this.paymentMethods.push(method);
            }
          });
        } else {
          this.paymentMethods = methods;
        }
      }

      const allow_b2b_checkout = this.user['client']['allow_b2b_checkout'];

      if (allow_b2b_checkout) {
        this.paymentMethods.push({
          id: 'online-payment',
          name: 'Pagamento Online (Cartão, Boleto, Pix)',
        });
      }

      this.allow_b2b_checkout_efipay = this.user['client']['allow_b2b_checkout_efipay'];

      if (this.allow_b2b_checkout_efipay) {
        this.paymentMethods.push({
          id: 'efipay-payment',
          name: 'Pagamento Online - Efipay (Cartão e Pix)',
        });
      }

      this.paymentMethods = this.paymentMethods.filter(method => method !== undefined);

      this.getPaymentTerms();
    });
  }

  getPaymentTerms() {
    this.apiService.getPaymentTerms().subscribe((res) => {
      if (!res['error']) {
        this.paymentTerms = res['payment_term'];
      }

      // this.loadCart();
    });
  }



  loadCart() {
    this.cart = this.cartService.getCart();
    this.cartProducts = this.cart['products'];

    this.cartTotalProducts = 0;
    this.cartTotalWithDiscounts = 0;
    this.cartTotalItems = 0;
    this.cartTotalDiscountPercentage = 0;
    let productWeight = 0;
    let productCubage = 0;
    let productQuantity = 0;
    let totalWeightOfEachProduct = 0;
    let totalCubageOfEachProduct = 0;
    this.totalWeigthProducts = 0;

    if (this.cartProducts && this.cartProducts.length > 0) {
      this.cartProducts.forEach((prod) => {

        // if (prod.images && typeof prod.images[0] !== 'undefined') {
        //   prod.image = 'https://p.biaction.com.br/' + this.clientId + '/products/150x150/' + prod.images[0].filename;
        //} else {
        //   prod.image = `https://app.pedidosdigitais.com.br/img/sem-imagem-mini.png`;
        // }

        if (prod.average_weight !== null && prod.average_weight !== undefined) {
          productWeight = prod.average_weight;
          productQuantity = prod.quantity;
          totalWeightOfEachProduct = (productWeight / 1000) * productQuantity

          this.totalWeigthProducts += totalWeightOfEachProduct;
        }

        if (prod.cubage !== null && prod.cubage !== undefined) {
          productCubage = prod.cubage;
          productQuantity = prod.quantity;
          totalCubageOfEachProduct = (productCubage) * productQuantity

          this.totalCubageProducts += totalCubageOfEachProduct;
        }

        prod.has_discount = prod.price_no_discounts > 0 && prod.price_no_discounts > prod.price_with_discounts ? true : false;
        prod.price_no_discounts = prod.price_no_discounts && prod.price_no_discounts > 0 ? prod.price_no_discounts : 0;
        prod.price_with_discounts = prod.price_with_discounts && prod.price_with_discounts > 0 ? prod.price_with_discounts : 0;
        prod.total_discount_percentage = prod.has_discount ? numeral((prod.price_with_discounts - prod.price_no_discounts) / (prod.price_no_discounts * 100)).format('0') : 0;

        this.cartTotalProducts += (prod.price_no_discounts * prod.quantity);
        this.cartTotalWithDiscounts += (prod.price_with_discounts * prod.quantity);
        this.cartTotalItems += prod.quantity;

        if (prod.default_discount > 0) {
          this.cartTotalDiscountPercentage += (((prod.price_no_discounts - prod.price_with_discounts) / ((prod.price_no_discounts + prod.price_with_discounts) / 2)) * 100);
        }
      });

      this.cartTotalDiscount = this.cartTotalProducts - this.cartTotalWithDiscounts;
      this.cartTotalDiscountPercentage = (this.cartTotalDiscount / this.cartTotalProducts) * 100;

      if (this.dicountValue > 0) {
        this.cartTotalWithDiscounts -= this.dicountValue;
        this.cartTotalDiscountPercentage = ((this.cartTotalDiscount + this.dicountValue) / this.cartTotalProducts) * 100;
      }

      if (this.fixedValue > 0) {
        this.cartTotalWithDiscounts -= this.fixedValue;
        this.cartTotalDiscountPercentage = ((this.cartTotalDiscount + this.fixedValue) / this.cartTotalProducts) * 100;
      }
      this.calculatePrevisionDate();
    }
    this.loadedData = true;
  }


  changePaymentMethod(method) {
    this.paymentTermsList = [];
    const paymentTerms = method.payment_terms ? JSON.parse(method.payment_terms) : [];

    this.paymentMethod = method;

    if (this.payment_type !== '3') {
      this.paymentTerms.forEach((term) => {
        if (paymentTerms.includes(String(term.code))) {
          this.paymentTermsList.push(term);
        }
      });
    } else {
      this.filterPaymentTerms(paymentTerms);
    }

    if (this.paymentMethod.id === 'online-payment') {
      this.showPaymentMessage = true;
      this.showPaymentTerms = false;
      this.showEfipayForm = false;
    } else if (this.paymentMethod.id === 'efipay-payment') {
      this.showPaymentMessage = false;
      this.showPaymentTerms = false;
      this.showEfipayForm = true;
    } else {
      this.showPaymentMessage = false;
      this.showPaymentTerms = true;
      this.showEfipayForm = false;
    }
  }

  changeEfipayPaymentMethod(method) {
    if (method === 'credit-card') {
      this.showEfipayCreditCard = true;
      this.showEfipayPix = false;

      this.maskCardFields();
    } else if (method === 'pix') {
      this.showEfipayCreditCard = false;
      this.showEfipayPix = true;
    } else {
      this.showEfipayCreditCard = false;
      this.showEfipayPix = false;
    }
  }

  async fillEfipayInstallments() {
    const cardNumberPattern = /^[0-9]{16}$/;
    const cardNumber = $('#card-number').val().replace(/\D/g, '');

    if (cardNumber.match(cardNumberPattern)) {
      this.efipayCardBrand = await this.identifyCardBrand(cardNumber);
      this.efipayInstallments = await this.getInstallments(this.user['client']['efipay_account_identifier'], this.efipayCardBrand, 5050);
      $('#card-installments').prop('disabled', false);
    } else {
      $('#card-installments').prop('disabled', true);
    }
  }

  async validateEfipayCardData() {
    const cardNamePattern = /^[a-zA-Z\s]+$/;
    const cardName = $('#card-name').val();

    const cardNumber = $('#card-number').val().replace(/\D/g, '');

    const cardExpiryPattern = /^(0[1-9]|1[0-2])\/(2[5-9]|[3-9]\d)$/;
    const cardExpiry = $('#card-expiry').val();

    const cardCVV = $('#card-cvv').val();

    let msg = '';

    if (!cardNumber) {
      msg += `O campo <b>Número do Cartão</b> deve ser preenchido. <br>`;
    }

    if (cardName === '') {
      msg += `O campo <b>Nome no Cartão</b> deve ser preenchido. <br>`;
    }

    if (cardName !== '') {
      if (!cardName.match(cardNamePattern)) {
        msg += `O campo <b>Nome no Cartão</b> deve conter apenas letras e espaços. <br>`;
      }
    }

    if (!cardExpiry) {
      msg += `O campo <b>Validade</b> deve ser preenchido. <br>`;
    }

    if (!cardExpiry.match(cardExpiryPattern)) {
      msg += `O campo <b>Validade</b> deve estar no formato MM/AA. <br>`;
    }

    if (!cardCVV) {
      msg += `O campo <b>CVV</b> deve ser preenchido. <br>`;
    }

    if (msg === '') {
      const total = 5050;
      const data = {
        brand: this.efipayCardBrand,
        number: cardNumber,
        cvv: cardCVV,
        expirationMonth: cardExpiry.split('/')[0],
        expirationYear: '20' + cardExpiry.split('/')[1],
        holderName: cardName,
        holderDocument: '',
        total: total,
      }

      this.efipayPaymentToken = await this.generatePaymentToken(this.user['client']['efipay_account_identifier'], data);

      return true;
    } else {
      this.toastrService.showWarning(msg);

      return false;
    }
  }

  createEfipayCardCharge() {
    this.apiService.createCardCharge({
      client: {
        efipay_client_id: this.user['client']['efipay_client_id'],
        efipay_client_secret: this.user['client']['efipay_client_secret'],
      },
      products: [
        {
          name: 'Fone de Ouvido HyperX', // Nome do serviço ou produto
          value: 40000, // Valor em centavos (R$400,00)
          amount: 2, // Quantidade do produto
        }
      ],
      customer: {
        name: this.user['name'],
        cpf: this.user['doc_number'],
        phone_number: this.user['phone_number'],
        email: this.user['email_1'],
      },
      paymentToken: this.efipayPaymentToken,
      installments: this.efipayInstallments,
      shipping: {
        total: 990,
      }
    }).subscribe((res) => {
      if (!res['error']) {
        console.log('Erro ao cobrar cartão no efipay: ', res['message']);
      }
    });
  }

  filterPaymentTerms(terms) {
    this.paymentTermsList = [];

    if (this.orderType.data['is_payment_fixed'] !== undefined && this.orderType.data['is_payment_fixed'] &&
      this.orderType.data['payment_term_fixed'] && this.orderType.data['payment_term_fixed'] !== '') {
      this.paymentTerms.forEach(term => {
        if (term.code === this.orderType.data['payment_term_fixed']) {
          this.paymentTermsList.push(term);
        }
      });
    } else {
      const methodPaymentTerms = [];
      terms.forEach(id => {
        const find = _.find(this.paymentTerms, ['code', id]);
        if (find !== undefined) {
          methodPaymentTerms.push(find);
        }
      });

      const paymentCode = this.paymentMethod['code'];

      let price_table_payment_terms = [], client_payment_terms = [];
      if (Object.keys(this.priceTableSelected).length > 0) {
        price_table_payment_terms = this.priceTableSelected['data']['payment_methods'] !== undefined &&
          this.priceTableSelected['data']['payment_methods'][paymentCode] !== undefined &&
          this.priceTableSelected['data']['payment_methods'][paymentCode].payment_terms !== undefined ?
          this.priceTableSelected['data']['payment_methods'][paymentCode].payment_terms : [];
      }

      if (this.user['store']['payment_term_list'] && this.user['store']['payment_term_list'] !== '') {
        client_payment_terms = this.user['store']['payment_term_list'].split(',');
      }

      if (price_table_payment_terms.length > 0 || client_payment_terms.length > 0) {
        let filteredTerms = JSON.parse(JSON.stringify(methodPaymentTerms));
        if (price_table_payment_terms.length > 0) {
          filteredTerms = _.remove(filteredTerms, function (n) { return price_table_payment_terms.includes(n.code); });
        }
        if (client_payment_terms.length > 0) {
          filteredTerms = _.remove(filteredTerms, function (n) { return client_payment_terms.includes(n.code); });
        }
        this.paymentTermsList = filteredTerms;
      } else {
        this.paymentTermsList = methodPaymentTerms;
      }

      if (!this.payment_terms_can_be_empty && this.paymentTermsList.length === 0) {
        this.paymentTermsList = this.paymentTerms;
      }
    }
  }

  changePaymentTerm(term) {
    this.paymentTerm = term;
  }

  addProduct() {

  }

  keepShopping() {
    this.router.navigate(['/']);
  }

  removeAllProducts() {
    this.cartService.removeProducts();
    this.router.navigate(['/precart']);
  }

  cartPage() {
    this.showShippingCompany = false;
    this.showCart = true;
    this.showPayment = false;
    this.showSummary = false;

    $('.progress-bar').attr('data-content', '20%');

    $('.progress-bar').css('background', 'radial-gradient(closest-side, white 79%, transparent 80% 100%), conic-gradient(var(--bg-secondary) 20%, #DEE0E4 0)');

    $('#user-circle').removeClass('active');
    $('#payment-circle').removeClass('active');
    $('#summary-circle').removeClass('active');
  }

  payment(back = null) {
    if (this.isLoggedIn) {
      if (this.user['client']['payment_methods_active']) {
        this.showCart = false;
        this.showPayment = true;
        this.showSummary = false;
        this.showShippingCompany = false;

        if (this.showFreightInfo) {
          $('.progress-bar').attr('data-content', '50%');
          $('.progress-bar').css('background', 'radial-gradient(closest-side, white 79%, transparent 80% 100%), conic-gradient(var(--bg-secondary) 50%, #DEE0E4 0)');
        } else {
          $('.progress-bar').attr('data-content', '60%');
          $('.progress-bar').css('background', 'radial-gradient(closest-side, white 79%, transparent 80% 100%), conic-gradient(var(--bg-secondary) 60%, #DEE0E4 0)');
        }

        $('#user-circle').addClass('active');
        $('#payment-circle').addClass('active');
        $('#transport-circle').removeClass('active');
        $('#summary-circle').removeClass('active');
      } else if (back !== null) {
        this.cartPage();
      } else {
        this.showCart = false;
        this.showPayment = false;
        this.showSummary = true;
        this.showShippingCompany = false;

        $('.progress-bar').attr('data-content', '80%');

        $('.progress-bar').css('background', 'radial-gradient(closest-side, white 79%, transparent 80% 100%), conic-gradient(var(--bg-secondary) 80%, #DEE0E4 0)');

        $('#user-circle').addClass('active');
        $('#payment-circle').addClass('active');
        $('#transport-circle').addClass('active');
        $('#summary-circle').addClass('active');
      }
    } else {
      this.router.navigate(['/login']);
    }
    // this.checkTypesToShowInSelect();
  }

  checkTypesToShowInSelect() {
    if (this.freightTypeOrderTypeEcommerce === 'ALL' || this.freightTypeOrderTypeEcommerce === '') {
      this.showCIF = true;
      this.showFOB = true;
      this.showCIF_SHIPPING = true;
      this.showCustomFreight = true;

    } else if (this.freightTypeOrderTypeEcommerce === 'CIF') {
      this.showCIF = true;
      this.showFOB = false;
      this.showCIF_SHIPPING = false;
      this.showCustomFreight = false;

    } else if (this.freightTypeOrderTypeEcommerce === 'FOB') {
      this.showCIF = false;
      this.showFOB = true;
      this.showCIF_SHIPPING = false;
      this.showCustomFreight = false;

    } else if (this.freightTypeOrderTypeEcommerce === 'CIFSHIPPING') {
      this.showCIF = false;
      this.showFOB = false;
      this.showCIF_SHIPPING = true;
      this.showCustomFreight = false;
    }

    if (this.freightTypeOrderTypeEcommerce === 'ALL' || this.freightTypeOrderTypeEcommerce === '') {
      if (this.freightTypePriceTable === 'ALL' || this.freightTypePriceTable === '') {
        this.showCIF = true;
        this.showFOB = true;
        this.showCIF_SHIPPING = true;
        this.showCustomFreight = true;

      } else if (this.freightTypePriceTable === 'CIF') {
        this.showCIF = true;
        this.showFOB = false;
        this.showCIF_SHIPPING = false;
        this.showCustomFreight = false;

      } else if (this.freightTypePriceTable === 'FOB') {
        this.showCIF = false;
        this.showFOB = true;
        this.showCIF_SHIPPING = false;
        this.showCustomFreight = false;

      } else if (this.freightTypePriceTable === 'CIFSHIPPING') {
        this.showCIF = false;
        this.showFOB = false;
        this.showCIF_SHIPPING = true;
        this.showCustomFreight = false;
      }
    }

    if ((this.freightTypeOrderTypeEcommerce === 'ALL' || this.freightTypeOrderTypeEcommerce === '')) {
      if (this.freightTypeStore === 'ALL' || this.freightTypeStore === '') {
        this.showCIF = true;
        this.showFOB = true;
        this.showCIF_SHIPPING = true;
        this.showCustomFreight = true;

      } else if (this.freightTypeStore === 'CIF') {
        this.showCIF = true;
        this.showFOB = false;
        this.showCIF_SHIPPING = false;
        this.showCustomFreight = false;

      } else if (this.freightTypeStore === 'FOB') {
        this.showCIF = false;
        this.showFOB = true;
        this.showCIF_SHIPPING = false;
        this.showCustomFreight = false;

      } else if (this.freightTypeStore === 'CIFSHIPPING') {
        this.showCIF = false;
        this.showFOB = false;
        this.showCIF_SHIPPING = true;
        this.showCustomFreight = false;

      } else if (this.freightTypeStore !== 'ALL' && this.freightTypeStore !== '' && this.freightTypeStore !== 'CIF'
        && this.freightTypeStore !== 'FOB' && this.freightTypeStore !== 'CIFSHIPPING') {
        this.showCIF = false;
        this.showFOB = false;
        this.showCIF_SHIPPING = false;
        this.showCustomFreight = true;
      }
    }
  }

  async getShippingCompanys() {
    if (this.allow_b2b_checkout_efipay) {
      const validatedCard = await this.validateEfipayCardData();

      if (validatedCard) {
        this.valueTotalCartWithDiscounts = this.cartTotalWithDiscounts;
        $('.progress-bar').attr('data-content', '70%');
        $('.progress-bar').css('background', 'radial-gradient(closest-side, white 79%, transparent 80% 100%), conic-gradient(var(--bg-secondary) 70%, #DEE0E4 0)');

        this.showCart = false;
        this.showPayment = false;
        this.showShippingCompany = true;
        this.showSummary = false;

        $('#user-circle').addClass('active');
        $('#payment-circle').addClass('active');
        $('#transport-circle').addClass('active');
        $('#summary-circle').removeClass('active');
      }
    } else {
      this.valueTotalCartWithDiscounts = this.cartTotalWithDiscounts;
      $('.progress-bar').attr('data-content', '70%');
      $('.progress-bar').css('background', 'radial-gradient(closest-side, white 79%, transparent 80% 100%), conic-gradient(var(--bg-secondary) 70%, #DEE0E4 0)');

      this.showCart = false;
      this.showPayment = false;
      this.showShippingCompany = true;
      this.showSummary = false;

      $('#user-circle').addClass('active');
      $('#payment-circle').addClass('active');
      $('#transport-circle').addClass('active');
      $('#summary-circle').removeClass('active');
    }
  }

  checkShippingIsSelected() {
    if (this.frete === 'FOB' && this.require_shipping && (this.shippingSelected === '' || this.shippingSelected === undefined)) {
      this.toastrService.showWarning('O pedido não possui transportadora.');
      this.shippigIsSelected = false;
    } else if (this.frete === 'CIF_SHIPPING' && this.require_shipping_cif && (this.shippingSelected === '' || this.shippingSelected === undefined)) {
      this.toastrService.showWarning('O pedido não possui transportadora.');
      this.shippigIsSelected = false;
    } else {
      this.shippigIsSelected = true;
    }
  }

  summary() {

    //define o texto do botão de finalizar
    if (this.selectedType === 'order') {
      this.finishButtonText = 'FINALIZAR PEDIDO';
    } else {
      this.finishButtonText = 'FINALIZAR ORÇAMENTO';
    }

    this.shippingSelected = $('#shipping-company').val();

    if (this.selectedType === 'order') {
      this.checkValeuCifIntegrationIsSelected();
    }

    if (this.shippingSelected !== '') {
      const findShipping = this.listShippingCompany.find((shipping) => String(shipping.id) === String(this.shippingSelected));
      if (findShipping !== undefined) {
        this.shippingDescription = findShipping;
      }
    }

    if (this.frete !== "CIF" && this.showFreightInfo && this.selectedType === 'order') {
      this.checkShippingIsSelected();
    }

    if (this.showFreightInfo && (this.frete === 'CIF' || this.frete === 'CIF_SHIPPING')) {
      if (this.value_min_freight_cif !== '' && this.selectedType === 'order') {
        this.checkValueMinFreightCifAgree();
      }
      this.freteFormated = this.frete;
      if (this.frete === 'CIF_SHIPPING') {
        this.freteFormated = 'CIF (' + this.cif_shipping_description + ')';
      }
    }

    if (this.showFreightInfo && this.frete === 'FOB') {
      if (this.valueFreight === 0 && this.selectedType === 'order') {
        this.checkValueMinFreightFobAgree();
      } else {
        this.goToSummaryScreen = true;
      }
    }

    if (this.didNotEnterAnyShippingRule !== undefined) {
      if (this.didNotEnterAnyShippingRule['min_value_type'] === 'cubage') {
        if (this.totalCubageProducts < this.didNotEnterAnyShippingRule['min_value']) {
          this.goToSummaryScreen = false;
          this.toastrService.showWarning(
            'Atenção, volume mínimo (' + this.didNotEnterAnyShippingRule['min_value'] + ' m³) não atingido'
          );
        }

      } else if (this.didNotEnterAnyShippingRule['min_value_type'] === 'peso') {
        if (this.totalWeigthProducts < this.didNotEnterAnyShippingRule['min_value']) {
          this.goToSummaryScreen = false;
          this.toastrService.showWarning(
            'Atenção, peso mínimo (' + this.didNotEnterAnyShippingRule['min_value'] + ' kg) não atingido'
          );
        }

      } else if (this.didNotEnterAnyShippingRule['min_value_type'] === 'value') {
        if (this.cartTotalProducts < this.didNotEnterAnyShippingRule['min_value']) {
          this.goToSummaryScreen = false;
          this.toastrService.showWarning(
            'Atenção, valor mínimo (' + this.didNotEnterAnyShippingRule['min_value'] + ') não atingido'
          );
        }
      }
    }

    if (this.goToSummaryScreen && this.shippigIsSelected) {
      if (this.user['client']['payment_methods_required']) {
        let msg = '';

        if (!this.paymentMethod || Object.keys(this.paymentMethod).length === 0) {
          msg += 'Uma <b>Forma de Pagamento</b> deve ser selecionada. <br>';
        }

        if (this.paymentMethod !== undefined) {
          if (this.paymentMethod.id !== 'online-payment') {
            if (!this.paymentTerm || Object.keys(this.paymentTerm).length === 0) {
              msg += 'Um <b>Prazo de Pagamento</b> deve ser selecionado. <br>';
            }
          }
        } else {
          msg += 'Um <b>Prazo de Pagamento</b> deve ser selecionado. <br>';
        }

        let freightSelected = $('#freight').val();
        if (this.showFreightInfo && (freightSelected === '' || freightSelected === undefined)) {
          msg += 'Um <b>Tipo de Frete</b> deve ser selecionado. <br>';
        }

        if (msg === '') {
          this.showCart = false;
          this.showPayment = false;
          this.showShippingCompany = false;
          this.showSummary = true;

          $('.progress-bar').attr('data-content', '80%');

          $('.progress-bar').css('background', 'radial-gradient(closest-side, white 79%, transparent 80% 100%), conic-gradient(var(--bg-secondary) 80%, #DEE0E4 0)');

          $('#user-circle').addClass('active');
          $('#payment-circle').addClass('active');
          $('#transport-circle').addClass('active');
          $('#summary-circle').addClass('active');
        } else {
          this.toastrService.showWarning(msg);
        }
      } else {
        this.showCart = false;
        this.showPayment = false;
        this.showShippingCompany = false;
        this.showSummary = true;

        if (this.showFreightInfo) {
          $('.progress-bar').attr('data-content', '85%');
          $('.progress-bar').css('background', 'radial-gradient(closest-side, white 79%, transparent 80% 100%), conic-gradient(var(--bg-secondary) 85%, #DEE0E4 0)');
        } else {
          $('.progress-bar').attr('data-content', '80%');
          $('.progress-bar').css('background', 'radial-gradient(closest-side, white 79%, transparent 80% 100%), conic-gradient(var(--bg-secondary) 80%, #DEE0E4 0)');
        }

        $('#user-circle').addClass('active');
        $('#payment-circle').addClass('active');
        $('#transport-circle').addClass('active');
        $('#summary-circle').addClass('active');
      }
    }
  }

  private checkValeuCifIntegrationIsSelected() {
    if (this.showCifintegration) {
      const selectValueCifIntegration = $('#cif_integration_type').val();
      if (this.freightSelect.length > 0) {
        if (this.order_types_require_freight_calc.length > 0) {
          if (this.order_types_require_freight_calc.includes(this.orderTypeCode)) {
            if ((this.valueTotalCartWithDiscounts < this.order_value_dont_need_freight_calc)) {
              if (selectValueCifIntegration === '' || selectValueCifIntegration === undefined) {
                this.shippigIsSelected = false;
                this.toastrService.showWarning('O pedido não tem frete selecionado');
              } else {
                this.shippigIsSelected = true;
              }
            } else {
              this.shippigIsSelected = true;
            }
          } else {
            this.shippigIsSelected = true;
          }
        } else {
          if ((this.valueTotalCartWithDiscounts < this.order_value_dont_need_freight_calc)) {
            if (selectValueCifIntegration === '' || selectValueCifIntegration === undefined) {
              this.shippigIsSelected = false;
              this.toastrService.showWarning('O pedido não tem frete selecionado');
            } else {
              this.shippigIsSelected = true;
            }
          } else {
            this.shippigIsSelected = true;
          }
        }
      } else {
        this.shippigIsSelected = true;
      }
    }
  }

  onChangePage(pageOfItems) {
    this.pageOfItems = pageOfItems;
  }

  simulatedSuframa(){
    this.resultSuframa = 0.00;
    const minOrderStore = this.user['store']['minimum_order'];
    const messageObservation = $('#text_observation').val();

    if (this.cartTotalWithDiscounts < minOrderStore && this.selectedType === 'order') {
      this.toastrService.showWarning(`O valor mínimo do pedido para esta loja é de R$ ${minOrderStore}`);
      return;
    }

    // this.firebase.functions().useFunctionsEmulator('http://localhost:5001');
    const suframaSimulator = this.firebase.functions().httpsCallable('getSuframa');


    let data: any = {};

    const order = this.getOrderData();

    this.priceTableIdOrder = this.localStorageService.get('priceTableId');

    data = {
      itens: [],
      "calculation_type": this.type_final_price,
      "conta": {
        "id": this.user['store']['id'] || '',
        "nome": this.user['store']['name'] || '',
        "nickname": this.user['store']['name'] || '',
        "cnpj": this.user['store']['doc_number'] || '',
        "erp_id": this.user['store']['erp_id'] || '',
        "enderecoCidade": this.user['store']['address_city'] || '',
        "enderecoEstado": this.user['store']['address_state'] || '',
        "enderecoLogradouro": this.user['store']['address_street'] || '',
        "enderecoBairro": this.user['store']['address_district'] || '',
        "enderecoNumero": this.user['store']['address_number'] || '',
        "enderecoCep": this.user['store']['address_zipcode'] || '',
        "prospect": false,
        "segment": this.user['store']['segment_id'] || '',
        "unit_discount": this.client_unit_discount,
      },
      "created_at": new Date(),
      "emailTo": this.user['email'] || '',
      "emissao": moment().format('YYYY-MM-DD'),
      "f_seller_value": 0,
      // fields_order
      "freight_calc_type": "fixed",
      "freight_text": "",
      "freight_weight": this.totalWeigthProducts,
      "installment_qty": order.payment_term_installments,
      "installment_value": 0,
      "installments": order.payment_term_installments,
      "ip": '',
      "is_adherent": true,
      "is_consumable": false,
      "is_simulated": false,
      // items
      "mix_category_discount": 0,
      "moedaCodigo": "R$",
      "nf_seller_percent": 0,
      "non_adherent_warning": '',
      "observacoes": messageObservation,
      "order_discount": 0,
      "order_type": this.orderTypeCode,
      "paymentMethod": order.payment_method_code,
      "payment_method_text": order.payment_method_text,
      "payment_term_discount": 0,
      "payment_term_increase": 0,
      "payment_term_text": order.payment_term_text,
      "pendency_list": [],
      "pendency_list_details": {},
      "policy_limit_discount": 0,
      "politics_out": 0,
      "politics_out_reason": '',
      "prazoPagto": order.payment_term_code,
      "previsao": '',
      "price_table_id_order": this.priceTableIdOrder,
      "qtd": this.cartTotalItems,
      "ref": "",
      "ruleMargin": "1",
      "source": "new-ecommerce",
      "tex_rule_id": '',
      "type": this.selectedType,
      "typeFreight": this.frete,
      "use_c_table": false,
      "user_id": this.userIdFireBase,
      "value": this.cartTotalProducts,
      "value_final": this.cartTotalWithDiscounts,
      "coupon": this.coupon,
      "value_freight": this.valueFreight,
      "company": {
        "id": this.firebaseId,
        "pd_id": this.clientId,
      }
    }


    if (this.prevision_date) {
      if (this.previsionDate !== undefined && this.previsionDate !== '') {
        var date = new Date(this.previsionDate)
        data.previsao = date;
      }
    }


    if (this.shippingDescription !== undefined && this.shippingDescription !== null) {
      data.transportadora = {};
      data.transportadora.id = this.shippingSelected;
      data.transportadora.cnpj = this.shippingDescription.data.cnpj;
      data.transportadora.address = this.shippingDescription.data.address;
      data.transportadora.city = this.shippingDescription.data.city;
      data.transportadora.legal_name = this.shippingDescription.data.legal_name;
      data.transportadora.neighborhood = this.shippingDescription.data.neighborhood;
      data.transportadora.number = this.shippingDescription.data.number;
      data.transportadora.zip_code = this.shippingDescription.data.zip_code;
      data.transportadora.state = this.shippingDescription.data.state;
      data.transportadora.sellers = JSON.stringify(this.shippingDescription.data.sellers);
      data.transportadora.restrictions = JSON.stringify(this.shippingDescription.data.restrictions);
    }


    if (this.user['seller']) {
      data.vendedor = {};
      data.vendedor.id = this.user['seller']['code'];
    }

    const products = [];

    const QntItens = this.cartProducts.length
    this.fixedValue = this.fixedValue / QntItens;
    this.dicountValue = this.dicountValue / QntItens;

    this.cartProducts.forEach((product) => {
      let valorUnitarioDefault = product.price_no_discounts;

      if (this.paymentTerm) {
        if (this.paymentTerm['rule'] && this.paymentTerm['rule'] !== 'none' || this.paymentTerm['rule'] !== '') {
          const ruleValue = ((this.paymentTerm['rule_value']) / 100);

          if (this.paymentTerm['rule'] === 'acresc') {
            valorUnitarioDefault = valorUnitarioDefault * (1 + ruleValue);
          } else if (this.paymentTerm['rule'] === 'desc') {
            valorUnitarioDefault = valorUnitarioDefault * (1 - ruleValue);
          }
        }
      }

      products.push({
        code: product.code,
        description: product.name,
        quantity: product.quantity,
        table_price: product.price_no_discounts,
        final_price: product.price_with_discounts,
      });

      let couponDiscount = 0;
      if (this.dicountValue > 0) {
        const percent = this.coupon && this.coupon['discount'] ? this.coupon['discount'] : 0;
        if (percent > 0) {
          const total = product.price_with_discounts * product.quantity;
          couponDiscount = (total * percent) / 100;
        }
      } else if (this.fixedValue > 0) {
        const percent = parseFloat(((product.quantity * 100) / this.cartTotalItems).toFixed(2));
        if (percent > 0) {
          couponDiscount = (this.fixedValue * percent) / 100;
        }
      }


      if (QntItens === 1) {
        this.discount = product.price_no_discounts - product.price_with_discounts + couponDiscount;
      }

      if (QntItens > 1) {
        this.discount = (((product.price_no_discounts - product.price_with_discounts) * product.quantity) + couponDiscount);
      }

      const discount = this.discount;

      data['itens'].push({
        "acres": 0,
        "commission_max_percent_discount": '',
        "currency": "R$",
        "desconto": discount,
        "discount_cascade": '',
        "discounts": {
          "client_discount": 0,
          "client_discount_percent": 0,
          "icms_discount": 0,
          "icms_discount_percent": 0,
          "payment_term_discount": 0,
          "payment_term_discount_percent": 0,
          "product_discount": 0,
          "product_discount_percent": 0,
          "product_incentive_discount": 0,
          "product_incentive_discount_percent": 0,
          "segment_discount": 0,
          "segment_discount_percent": 0,
          "tax_discount": 0,
          "tax_discount_percent": 0,
          "variation_discount": 0,
          "variation_discount_percent": 0,
        },
        "imported": false,
        "increases": {
          "client_increases": 0,
          "client_increases_percent": 0,
          "icms_percent": 0,
          "payment_term_increases": 0,
          "payment_term_increases_percent": 0,
          "pis_cofins_percent": '',
          "segment_increase": 0,
          "segment_increase_percent": 0,
          "variation_increase": 0,
          "variation_increase_percent": 0,
        },
        "ipi": 0,
        "ipi_percent": 0,
        "ipi_value": 0,
        "item": {
          "codigo": product.code,
          "erp_id": product.erp_id || '',
          "id": product.id,
          "kitInfos": '',
          "minimum": 1,
          "multiple": 0,
          "nome": product.name,
          "unidadeCodigo": "UN",
          "variations": "[]",
        },
        "ncm": product.ncm,
        "notes": "",
        "percent_discount": 0,
        "percent_increases": 0,
        "politics_out": 0,
        "politics_out_reason": 0,
        "position": 0,
        "qtd_order": product.quantity,
        "st": '',
        "st_percent": 0,
        "st_value": 0,
        "valorUnitario": product.product_price_table,
        "valorUnitarioDefault": valorUnitarioDefault,
        "value_custom": 0,
        "value_total": product.price_with_discounts * product.quantity,
      });
    });

    suframaSimulator(data)
      .then(async result => {
        if (result.data.success === true) {
          this.resultSuframa = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(result.data.result.client_suframa_discount);
          $(`#modal-infos-suframa`).modal('show');
        } 
      }).catch(error => {
        console.log('ERROR', error);
        const errorMessage = error.data !== undefined && error.data.message !== undefined ?
          error.data.message : 'Desculpe, ocorreu um erro inesperado.';
        this.toastrService.showError(errorMessage);
      });
  }

  placeOrder() {
    const minOrderStore = this.user['store']['minimum_order'];
    const messageObservation = $('#text_observation').val();

    if (this.cartTotalWithDiscounts < minOrderStore && this.selectedType === 'order') {
      this.toastrService.showWarning(`O valor mínimo do pedido para esta loja é de R$ ${minOrderStore}`);
      return;
    }

    // this.firebase.functions().useFunctionsEmulator('http://localhost:5001');
    const createRequest = this.firebase.functions().httpsCallable('placeOrder');

    const btn = $('#btn-place-order');

    btn.html('<i class="fas fa-spinner fa-spin"></i>');
    btn.prop('disabled', true);

    const data: any = {};

    const order = this.getOrderData();

    this.priceTableIdOrder = this.localStorageService.get('priceTableId');

    data.order = {
      itens: [],
      "conta": {
        "id": this.user['store']['id'] || '',
        "nome": this.user['store']['name'] || '',
        "nickname": this.user['store']['name'] || '',
        "cnpj": this.user['store']['doc_number'] || '',
        "erp_id": this.user['store']['erp_id'] || '',
        "enderecoCidade": this.user['store']['address_city'] || '',
        "enderecoEstado": this.user['store']['address_state'] || '',
        "enderecoLogradouro": this.user['store']['address_street'] || '',
        "enderecoBairro": this.user['store']['address_district'] || '',
        "enderecoNumero": this.user['store']['address_number'] || '',
        "enderecoCep": this.user['store']['address_zipcode'] || '',
        "prospect": false,
        "segment": this.user['store']['segment_id'] || '',
      },
      "source": "new-ecommerce",
      "ref": "",
      "value": this.cartTotalProducts,
      "value_final": this.cartTotalWithDiscounts,
      "coupon": this.coupon,
      "qtd": this.cartTotalItems,
      "emailTo": this.user['email'] || '',
      "payment_term_discount": 0,
      "payment_term_increase": 0,
      "order_type": this.orderTypeCode,
      "order_discount": 0,
      "paymentMethod": order.payment_method_code,
      "installments": order.payment_term_installments,
      "installment_qty": order.payment_term_installments,
      "installment_value": 0,
      "prazoPagto": order.payment_term_code,
      "payment_method_text": order.payment_method_text,
      "payment_term_text": order.payment_term_text,
      "is_adherent": true,
      "observacoes": messageObservation,
      "price_table_id_order": this.priceTableIdOrder,
      "freight_calc_type": "fixed",
      "typeFreight": this.frete,
      "value_freight": this.valueFreight,
      "freight_weight": this.totalWeigthProducts,
      "freight_text": "",
      "is_simulated": false,
      "emissao": "",
      "moedaCodigo": "R$",
      "type": this.selectedType,
      "user_id": this.userIdFireBase,
      "company": {
        "id": this.firebaseId,
        "pd_id": this.clientId,
      }
    }

    if (this.prevision_date) {
      if (this.previsionDate !== undefined && this.previsionDate !== '') {
        var date = new Date(this.previsionDate)
        data.order.previsao = date;
      }
    }

    // Envio do parâmetro transportadora
    if (this.shippingDescription !== undefined && this.shippingDescription !== null) {
      data.order.transportadora = {};
      data.order.transportadora.id = this.shippingSelected;
      data.order.transportadora.cnpj = this.shippingDescription.data.cnpj;
      data.order.transportadora.address = this.shippingDescription.data.address;
      data.order.transportadora.city = this.shippingDescription.data.city;
      data.order.transportadora.legal_name = this.shippingDescription.data.legal_name;
      data.order.transportadora.neighborhood = this.shippingDescription.data.neighborhood;
      data.order.transportadora.number = this.shippingDescription.data.number;
      data.order.transportadora.zip_code = this.shippingDescription.data.zip_code;
      data.order.transportadora.state = this.shippingDescription.data.state;
      data.order.transportadora.sellers = JSON.stringify(this.shippingDescription.data.sellers);
      data.order.transportadora.restrictions = JSON.stringify(this.shippingDescription.data.restrictions);
    }


    // Envio do parâmetro vendedor
    if (this.user['seller']) {
      data.order.vendedor = {};
      data.order.vendedor.id = this.user['seller']['code'];
    }

    const products = [];

    // comentar essas 3 linhas apos tratar cupom no backend
    const QntItens = this.cartProducts.length
    this.fixedValue = this.fixedValue / QntItens;
    this.dicountValue = this.dicountValue / QntItens;

    this.cartProducts.forEach((product) => {
      let valorUnitarioDefault = product.price_no_discounts;

      if (this.paymentTerm) {
        if (this.paymentTerm['rule'] && this.paymentTerm['rule'] !== 'none' || this.paymentTerm['rule'] !== '') {
          const ruleValue = ((this.paymentTerm['rule_value']) / 100);

          if (this.paymentTerm['rule'] === 'acresc') {
            valorUnitarioDefault = valorUnitarioDefault * (1 + ruleValue);
          } else if (this.paymentTerm['rule'] === 'desc') {
            valorUnitarioDefault = valorUnitarioDefault * (1 - ruleValue);
          }
        }
      }

      products.push({
        code: product.code,
        description: product.name,
        quantity: product.quantity,
        table_price: product.price_no_discounts,
        final_price: product.price_with_discounts,
      });

      let couponDiscount = 0;
      if (this.dicountValue > 0) {
        const percent = this.coupon && this.coupon['discount'] ? this.coupon['discount'] : 0;
        if (percent > 0) {
          const total = product.price_with_discounts * product.quantity;
          couponDiscount = (total * percent) / 100;
        }
      } else if (this.fixedValue > 0) {
        const percent = parseFloat(((product.quantity * 100) / this.cartTotalItems).toFixed(2));
        if (percent > 0) {
          couponDiscount = (this.fixedValue * percent) / 100;
        }
      }

      // comentar esses 2 if apos tratar cupom no backend
      if (QntItens === 1) {
        // this.fixedValue = this.fixedValue / product.quantity;
        // this.dicountValue = this.dicountValue / product.quantity;
        // this.discount = product.price_no_discounts - product.price_with_discounts + this.fixedValue + this.dicountValue;
        this.discount = product.price_no_discounts - product.price_with_discounts + couponDiscount;
      }

      if (QntItens > 1) {
        // this.discount = (((product.price_no_discounts - product.price_with_discounts) * product.quantity) + this.fixedValue + this.dicountValue) / product.quantity;
        this.discount = (((product.price_no_discounts - product.price_with_discounts) * product.quantity) + couponDiscount);
      }

      const discount = this.discount;

      // const discount = product.price_no_discounts - product.price_with_discounts

      data.order['itens'].push({
        "currency": "R$",
        "desconto": discount,
        "ipi": 0,
        "ipi_percent": 0,
        "ipi_value": 0,
        "st": 0,
        "st_percent": 0,
        "st_value": 0,
        "ncm": product.ncm,
        "notes": "",
        "valorUnitario": product.product_price_table,
        "item": {
          "codigo": product.code,
          "erp_id": product.erp_id || '',
          "id": product.id,
          "nome": product.name,
          "unidadeCodigo": "",
          "variations": "[]",
          "minimum": 1,
          "multiple": 0,
        },
        "qtd_order": product.quantity,
        "valorUnitarioDefault": valorUnitarioDefault,
        "value_total": product.price_with_discounts * product.quantity,
        "value_custom": 0,
        "discounts": {
          "icms_discount": 0,
          "icms_discount_percent": 0,
          "product_incentive_discount": 0,
          "product_incentive_discount_percent": 0,
          "segment_discount": 0,
          "segment_discount_percent": 0,
          "variation_discount": 0,
          "variation_discount_percent": 0,
          "product_discount": 0,
          "product_discount_percent": 0,
          "client_discount": 0,
          "client_discount_percent": 0,
          "tax_discount": 0,
          "tax_discount_percent": 0
        },
        "increases": {
          "variation_increase": 0,
          "variation_increase_percent": 0,
          "segment_increase": 0,
          "segment_increase_percent": 0
        }
      });
    });

    const that = this;
    // Verifica se é ORÇAMENTO
    if (this.selectedType === 'budget') {
      this.orderService.createOrder(this.companyPdId, data.order, function (err, res) {
        if (err) {
          that.toastrService.showError('Erro');
          return;
        } else {
          that.toastrService.showSuccess('Sucesso no orçamento');
          that.cartService.removeProducts();
          // window.location.href = res['url'];
          that.router.navigate([`/orders`]);
        }
      });
    } else {

      data.order = JSON.stringify(data.order);

      createRequest(data)
        .then(async result => {
          if (result.data.success === true) {
            if (this.paymentMethod && this.paymentMethod.id == 'online-payment') {
              const orderCode = result.data.code;
              const res = await this.makeCreditCardRequest(products, orderCode);

              if (!res['error']) {
                this.toastrService.showSuccess('Pedido feito com sucesso!');
                this.cartService.removeProducts();
                window.location.href = res['url'];
              } else {
                // save errors
                console.log('Erros de validação da pagar.me: ', res['errors']);

                btn.prop('disabled', false);
                btn.html('<i class="fa-solid fa-file-import"></i> FINALIZAR PEDIDO');
              }
            } else {
              this.toastrService.showSuccess('Pedido feito com sucesso!');
              this.cartService.removeProducts();
              this.router.navigate([`/orders`]);
            }
          } else {
            this.toastrService.showError(result.data.message);
            btn.prop('disabled', false);
            btn.html('<i class="fa-solid fa-file-import"></i> FINALIZAR PEDIDO');
          }
        }).catch(error => {
          console.log('ERROR', error);
          const errorMessage = error.data !== undefined && error.data.message !== undefined ?
            error.data.message : 'Desculpe, ocorreu um erro inesperado.';
          this.toastrService.showError(errorMessage);

          btn.prop('disabled', false);
          btn.html('<i class="fa-solid fa-file-import"></i> FINALIZAR PEDIDO');
        });
    }
  };

  makeCreditCardRequest(products, orderCode) {

    if(this.user['store']['doc_number'] !== undefined) {
      this.user['store']['doc_number'] = this.formatCNPJ(this.user['store']['doc_number']);
    }

    let phoneNumber = this.user['phone_number'] || this.user['phone_number_1'] || this.user['phone_number_2'];

    if (phoneNumber) {
      phoneNumber = phoneNumber.replace(/\D/g, '');
    }

    return new Promise((resolve, reject) => {
      const userData = {
        code: this.user['store']['code'],
        name: this.user['name'],
        email: this.user['email'],
        document_type: this.user['store']['doc_type'],
        document: this.user['store']['doc_number'],
        phone_number: phoneNumber || '999998888'
      };

      const billingAddress = {
        address_street: this.user['store']['address_street'] || '',
        address_number: this.user['store']['address_number'] || '',
        address_zipcode: this.user['store']['address_zipcode'] || '',
        address_district: this.user['store']['address_district'] || '',
        address_city: this.user['store']['address_city'] || '',
        address_state: this.user['store']['address_state'] || '',
        address_more: this.user['store']['address_city'] || '',
      };

      const settings = {
        domain: this.domain,
        order_code: orderCode,
        b2b_mundipagg_installments: this.user['client']['b2b_mundipagg_installments'],
      };

      const card = {
        number: this.card.number,
        holder_name: this.card.holder_name,
        exp_month: this.card.exp_month,
        exp_year: this.card.exp_year,
        cvv: this.card.cvv,
      };

      this.apiService.createPaymentLink({
        userData: userData,
        billingAddress: billingAddress,
        settings: settings,
        card: card,
        products: products,
      }).subscribe((res) => {
        if (!res['error']) {
          resolve({
            error: false,
            url: res['url'],
            message: res['message'],
          });
        }

        resolve({
          error: true,
          errors: res['errors'],
          message: res['message'],
        });
      });
    });
  }

  formatCNPJ(cnpj) {
    // Remove todos os caracteres não numéricos
    return cnpj.replace(/\D/g, '');
}

  getOrderData() {
    const data = {
      payment_method_code: '',
      payment_method_text: '',
      payment_term_code: '',
      payment_term_text: '',
      payment_term_installments: 1,
    };

    if (this.paymentMethod) {
      data.payment_method_code = this.paymentMethod['code'];

      if (this.paymentMethod['description'] && this.paymentMethod['description'] !== '') {
        data.payment_method_text = `${this.paymentMethod['name']} (${this.paymentMethod['description']})`;
      } else {
        data.payment_method_text = `${this.paymentMethod['name']}`;
      }
    }

    if (this.paymentTerm) {
      data.payment_term_code = this.paymentTerm['code'];
      data.payment_term_installments = this.paymentTerm['installments'];

      if (this.paymentTerm['rule'] && this.paymentTerm['rule'] !== 'none' && this.paymentTerm['rule'] !== '') {
        data.payment_term_text = `${this.paymentTerm['description']} (em ${this.paymentTerm['installments']}x` +
          ` com ${this.paymentTerm['rule']}. de ${this.paymentTerm['rule_value']}%)`;
      } else {
        data.payment_term_text = `${this.paymentTerm['description']} (em ${this.paymentTerm['installments']}x`;
      }
    }

    return data;
  }

  removeProduct(product) {
    this.cartService.removeProduct(product.id);
    this.checkIfCartEmpty();
    this.toastrService.showSuccess('Produto removido do carrinho com sucesso!');
  }

  plusMinusCart(product, type) {
    if (type === 'plus') {

      if(this.configType == 2) {

          if (product.quantity > product['stock'] && this.generalBlockLargestQuantityInCart) {
            product.quantity = product['stock'];
            this.toastrService.showWarning('Quantidade acima do estoque');
            return;
          } else {
            product.quantity = product.quantity + 1;
          }

      } else if (this.configType == 1) {

        if (product.quantity > product['stock'] && this.blockQuantityGreaterThanStockInCart) {
          product.quantity = product['stock'];
          this.toastrService.showWarning('Quantidade acima do estoque');
          return;
        } else {
          product.quantity = product.quantity + 1;
        }
      } else if (this.configType == 0) {
        product.quantity = product.quantity + 1;
      }
      


    } else if (type === 'minus') {
      product.quantity = product.quantity - 1;
    } else if (type === 'custom') {
      let qtd = parseInt($(`#product_${product.id}_qtd`).val());

      if (typeof qtd !== 'number') {
        qtd = 1;
      }

      if(this.configType == 2) {

        if (product['stock']) {
          if(qtd > product['stock'] && this.generalBlockLargestQuantityInCart){
            product.quantity = product['stock'];
            $(`#product_${product.id}_qtd`).val(product['stock']);
            this.toastrService.showWarning('Quantidade acima do estoque');
            return;
          } else {
            product.quantity = qtd;
          }
        }

      } else if (this.configType == 1) {

        if (product['stock']) {
          if(qtd > product['stock'] && this.blockQuantityGreaterThanStockInCart){
            product.quantity = product['stock'];
            $(`#product_${product.id}_qtd`).val(product['stock']);
            this.toastrService.showWarning('Quantidade acima do estoque');
            return;
          } else {
            product.quantity = qtd;
          }
        }

      } else if(this.configType == 0) {
        product.quantity = qtd;
      }

      
    }

    if (product.quantity <= 0) {
      product.quantity = 1;
    }

    this.cartService.setProduct(product, product.quantity, false);
    this.loadCart();
    const coupon = $('#discountCode').val();
    if (coupon !== '') {
      this.applyCoupon();
    }
  };

  calculatePrevisionDate() {
    try {
      const products = this.cartProducts;
      const store = this.user['store'];

      let previsionDaysDelivery = 0;

      // Array com os dias de pevisão por produto
      const previsionDaysDeliveryProductsArray = products.map((product) => product['prevision_days_delivery']);

      // Busca o maior dia de previsão por produto
      const biggerPrevisionDaysDeliveryProducts = Math.max(...previsionDaysDeliveryProductsArray);

      // Primeira validação -> PRODUTOS
      if (biggerPrevisionDaysDeliveryProducts > 0) {
        previsionDaysDelivery = biggerPrevisionDaysDeliveryProducts;

        // Segunda validação -> CLIENTE
      } else if (store['prevision_days_delivery'] > 0) {
        previsionDaysDelivery = store['prevision_days_delivery'];

        // Terceira validação -> EMPRESA
      } else if (this.previsionDateDaysDefault) {
        previsionDaysDelivery = this.previsionDateDaysDefault;
      }

      // Verifica se o dia escolhido é superior a 0
      if (previsionDaysDelivery > 0) {
        const currentDate = new Date();
        const forecastDate = new Date();

        forecastDate.setDate(currentDate.getDate() + previsionDaysDelivery);

        // Verifica os Sábados
        if (moment(forecastDate).format('dddd') === 'Saturday') {
          forecastDate.setDate(forecastDate.getDate() + 2);

          // Verifica os Domingos
        } else if (moment(forecastDate).format('dddd') === 'Sunday') {
          forecastDate.setDate(forecastDate.getDate() + 1);
        }

        const previsionDate = moment(forecastDate).format('DD/MM/yyyy');

        this.previsionDate = previsionDate;
      }

    } catch (err) {
      console.error('Error previsionDateRules ', err);
      return err;
    }
  }

  getCoupons() {
    this.apiService.getCoupons().subscribe((res) => {
      if (!res.error) {
        this.coupons = res.discountCoupons;
      } else {
        this.toastrService.showWarning('Erro ao obter os cupons de desconto!');
        console.error('Erro ao obter os cupons de desconto', res.message);
      }
    }, (err) => {
      this.toastrService.showWarning('Erro na solicitação ao servidor!');
      console.error('Erro na solicitação ao servidor:', err);
    });
  }


  applyCoupon() {

    const storeId = this.user['store'].id;
    const storeSegmentId = this.user['store'].segment_id;
    const discountCode = this.discountCode;

    const validStore = this.coupons.filter((coupon) => coupon.store_id === storeId);

    const validGrop = this.coupons.filter((coupon) => {
      if (coupon.user_group_ids) {
        return coupon.user_group_ids.includes(storeSegmentId)
      }
      return false;
    });

    const validAll = this.coupons.filter((coupon) => {
      if (coupon.store_id === 0 && coupon.user_group_ids === null) {
        return coupon;
      }
      return false;
    });


    const validCoupons = validStore.concat(validGrop).concat(validAll);

    const couponValid = validCoupons.find((coupon) => coupon.discount_code === discountCode);

    if (couponValid) {

      const currentDate = new Date();
      const validUntilDate = new Date(couponValid.valid_date);

      if (currentDate <= validUntilDate && couponValid.used <= couponValid.use_limit) {
        this.apliCoupon = true;
        this.MensageCoupon = false;
        this.toastrService.showSuccess('Cupom Aplicado com sucesso!');

        this.fixedValue = couponValid.fixed_value;
        this.dicountValue = couponValid.discount / 100 * this.cartTotalProducts;
        this.coupon = couponValid;
      } else {
        this.fixedValue = 0;
        this.dicountValue = 0;
        this.apliCoupon = false;
        this.MensageCoupon = true;
      }

    } else {
      this.fixedValue = 0;
      this.dicountValue = 0;
      this.apliCoupon = false;
      this.MensageCoupon = true;
    }
    this.loadCart();
  }

  maskCardFields() {
    $(document).ready(function () {
      $('#card-number').mask('9999 9999 9999 9999').click(function () {
        this.setSelectionRange(0, 0);
      });

      $('#card-expiry').mask('99/99').click(function () {
        this.setSelectionRange(0, 0);
      });

      $('#card-cvv').mask('999').click(function () {
        this.setSelectionRange(0, 0);
      });
    });
  }

  async identifyCardBrand(cardNumber) {
    try {
      const brand = await EfiPay.CreditCard
        .setCardNumber(cardNumber)
        .verifyCardBrand();

      return brand;
    } catch (error) {
      console.log('Erro ao recuperar bandeira do cartão: ', error.error_description)
      return undefined;
    }
  }

  async getInstallments(accountIdentifier, brand, total) {
    try {
      const result = await EfiPay.CreditCard
        .setAccount(accountIdentifier)
        .setEnvironment("sandbox") // 'production' or 'sandbox'
        .setBrand(brand)
        .setTotal(total)
        .getInstallments();

      // Formatando as parcelas
      const installmentsFormatted = result['installments'].map((installment) => {
        if (installment.installment === 1) {
          return {
            number: 1,
            description: `À vista`,
          };
        } else {
          const formattedValue = installment.currency;
          const interestText = installment.has_interest ? ` com juros de ${installment.interest_percentage}%` : '';

          return {
            number: installment.installment,
            description: `${installment.installment}x de R$ ${formattedValue}${interestText}`,
          };
        }
      });

      return installmentsFormatted;
    } catch (error) {
      console.log("Erro ao recuperar as parcelas do cartão: ", error.error_description);
      return [];
    }
  }

  async generatePaymentToken(accountIdentifier, card) {
    try {
      const result = await EfiPay.CreditCard
        .setAccount(accountIdentifier)
        .setEnvironment("sandbox") // 'production' or 'sandbox'
        .setCreditCardData({
          brand: card.brand,
          number: card.number,
          cvv: card.cvv,
          expirationMonth: card.expirationMonth,
          expirationYear: card.expirationYear,
          holderName: card.holderName,
          holderDocument: '',
          reuse: true,
        })
        .getPaymentToken();

      return result['payment_token'];
    } catch (error) {
      console.log("Erro ao gerar o o token de pagamento do cartão: ", error.error_description);
      return undefined;
    }
  }
}
