import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import * as numeral from 'numeral';
import { CartService } from '../../services/carts/cart.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SalePolicyService } from '../../services/sale-policy/sale-policy.service';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';
import { ToastrService } from '../../services/toastr/toastr-service.service';
import { FirebaseApp } from '@angular/fire';
import {AuthService} from '../../services/auth/auth.service';
import { ApiService } from '../../services/api/api.service';



declare var $: any;

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css'],
})

export class ProductComponent implements OnInit {

  @Input()
  product: any;

  @Input()
  orderTypeId: any;

  @Input()
  priceTableId?: any;

  @Input()
  type: String;

  @Input()
  insideCatalog: boolean;

  companyId: any;
  clientId: any;
  isStockActive: boolean;
  showQtdField: boolean;
  showSpinner: boolean = false;
  isLoggedIn: boolean;
  wishlists: any[] = [];
  newWishlistName: string = '';
  newName: string = '';
  selectedWishlistId: number | null = null;
  deletedWishlistId: number | null = null;
  newFavorite: any[] = [];
  selectedProductId: number | null = null;;
  productID: any[] = [];
  removeTextIpiStPauta = false;
  ecommerceConfigs = {};
  storeAccess = 1;
  showStock = false;
  validateStock = false;

  configType = 0;
  generalEnableStockUsage = '';
  generalApplyInventoryConfigsInEcommerce = '';
  generalListProductsStockZero = '';
  generalBlockLargestQuantityInCart = '';
  generalValidateLargestQuantityInOrder = '';
  ignoreQuotaStock = '';
  alsoAvailableForEcommerce = '';
  listProductsWithZeroStock = '';
  blockQuantityGreaterThanStockInCart = '';
  validateQuantityGreaterThanStockInOrder = '';
  generalEnableFutureStock = '';
  generalStockFutureAdvisory = '';
  enableFutureStock = '';
  stockFutureAdvisory = '';
  scopeStockQuota = '';
  ignoreStockQuota = '';
  storeSegment = '';

  currentGrade = {
    products: [],
  };


  constructor(
    private activatedRoute: ActivatedRoute,
    private firebase: FirebaseApp,
    private cartService: CartService,
    private router: Router,
    private salePolicyService: SalePolicyService,
    private localStorageService: LocalStorageService,
    private toastrService: ToastrService,
    private toastr: ToastrService,
    private authService: AuthService,
    private apiService: ApiService,
  ) {
    this.companyId = this.localStorageService.get('biaction_id');
    this.clientId = this.localStorageService.get('pd_id');
  }

  ngOnInit() {
    this.isLoggedIn = this.authService.isLoggedIn();

    // Método para setar as configurações do Ecommerce
    this.ecommerceConfigs = this.authService.setEcommerceConfigs();
    this.getStoreAccess();
    this.getPricingPolicy();
    this.getEcommercePolicy();
    this.getValuesOfStorage();

  }

  getValuesOfStorage() {
    this.configType = this.localStorageService.get('configType');
    this.generalEnableStockUsage = this.localStorageService.get('generalEnableStockUsage');
    this.generalApplyInventoryConfigsInEcommerce = this.localStorageService.get('generalApplyInventoryConfigsInEcommerce');
    this.generalListProductsStockZero = this.localStorageService.get('generalListProductsStockZero');
    this.generalBlockLargestQuantityInCart = this.localStorageService.get('generalBlockLargestQuantityInCart');
    this.generalValidateLargestQuantityInOrder = this.localStorageService.get('generalValidateLargestQuantityInOrder');
    this.ignoreQuotaStock = this.localStorageService.get('ignoreQuotaStock');
    this.alsoAvailableForEcommerce = this.localStorageService.get('alsoAvailableForEcommerce');
    this.listProductsWithZeroStock = this.localStorageService.get('listProductsWithZeroStock');
    this.blockQuantityGreaterThanStockInCart = this.localStorageService.get('blockQuantityGreaterThanStockInCart');
    this.validateQuantityGreaterThanStockInOrder = this.localStorageService.get('validateQuantityGreaterThanStockInOrder');
    this.generalEnableFutureStock = this.localStorageService.get('generalEnableFutureStock');
    this.generalStockFutureAdvisory = this.localStorageService.get('generalStockFutureAdvisory');
    this.enableFutureStock = this.localStorageService.get('enableFutureStock');
    this.stockFutureAdvisory = this.localStorageService.get('stockFutureAdvisory');
    this.scopeStockQuota = this.localStorageService.get('scopeStockQuota');
    this.ignoreStockQuota = this.localStorageService.get('ignoreStockQuota');
    this.storeSegment = this.localStorageService.get('storeSegment');
  }

  getStoreAccess() {
    this.storeAccess = this.ecommerceConfigs['store_access'] ? this.ecommerceConfigs['store_access'] : 2;

    if(!this.isLoggedIn && this.storeAccess === 2) {
      this.product.price_no_discounts = '';
      this.product.price_with_discounts = '';

    }
  }


  getEcommercePolicy() {
    this.salePolicyService.getEcommercePolice(this.companyId).then((res) => {
      if (res) {
        this.showQtdField = res['show_qtd_field']
          ? res['show_qtd_field']
          : false;

          this.removeTextIpiStPauta = res['remove_ipi_st_pauta']
          ? res['remove_ipi_st_pauta']
          : false;

          this.showStock = res && res['show_stock'] 
          ? res['show_stock'] 
          : false;
      }
    });
    this.getPricingPolicy();
  }

  getPricingPolicy() {
    this.salePolicyService.getPolices(this.companyId).then((res) => {
      if (res && typeof res['fields'] !== 'undefined') {
        this.validateStock = res['fields']['stock_ecommerce'] !== undefined ? res['fields']['stock_ecommerce'] : false;

        if (
          typeof res['fields']['stock_ecommerce'] !== 'undefined' &&
          res['fields']['stock_ecommerce']
        ) {
          this.isStockActive = true;
        } else {
          this.isStockActive = false;
        }
      }
    });
  }

  isInsideCart(productId) {
    return this.cartService.isInsideCart(productId);
  }

  hasStock(item) {
    if (this.isStockActive) {
      if (item.stock > 0) {
        return true;
      }

      return false;
    }

    return true;
  }

  openProductDetails(product) {
    /* Só navegará para Detalhes do Produto, se o item.grade_id
    for null e o produto não possuir nenhuma relação na tabela grade. */

    let qtdField = $(`#${product.id}_qtd`).val();
    if(this.configType == 2) {
      if (product['stock'] && this.generalBlockLargestQuantityInCart) {
        if(qtdField > product['stock'] && this.validateStock){
          this.toastr.showWarning('Quantidade acima do estoque');
          return;
        }
      }

    } else if (this.configType == 1) {
      if (product['stock'] && this.blockQuantityGreaterThanStockInCart) {
        if(qtdField > product['stock'] && this.validateStock){
          this.toastr.showWarning('Quantidade acima do estoque');
          return;
        }
      }

    }

    

    if (!this.hasStock(product)) {
      return;
    }

    const minimum = product.minimum ? product.minimum : 0;
    const multiple = product.multiple ? product.multiple : 0;
    const maximum = product.maximum ? product.maximum : 0;
    let msg = '';

    let goToPreCart = false,
      qtd = 0;

    if (this.showQtdField) {
      let qtdField = $(`#${product.id}_qtd`).val();

      if (qtdField && qtdField !== '') {
        goToPreCart = true;
        qtd = qtdField;
      }

      if (minimum > 0 && qtdField > 0) {
        if (qtdField < minimum) {
          msg += 'A Quantidade mínima para esse produto é ' + minimum + '.<br>';
        }
      }

      if (maximum > 0 && qtdField > 0) {
        if (qtdField > maximum) {
          msg += 'A Quantidade máxima para esse produto é ' + maximum + '.<br>';
        }
      }

      if (multiple > 0 && qtdField > 0) {
        const rest =
          (parseFloat((100 * qtdField).toFixed(2)) %
            parseFloat((100 * multiple).toFixed(2))) /
          100;
        if (rest !== 0) {
          msg +=
            'A Quantidade deve ser um valor múltiplo de ' + multiple + '.<br>';
        }
      }
    }

    if (this.isLoggedIn) {
      if (msg !== '') {
        this.toastr.showWarning(msg);
      } else {
        if (goToPreCart) {
          this.router.navigate([`/precart/${product.id}`], {
            queryParams: {qtd: qtd, orderTypeId: this.orderTypeId},
          });
        } else {
          if (!product.grade_id) {
            this.router.navigate([`/products/${product.id}`], {
              queryParams: {orderTypeId: this.orderTypeId},
            });
          } else {
            // this.firebase.functions().useFunctionsEmulator('http://localhost:5001');
            const productsRequest = this.firebase
              .functions()
              .httpsCallable('products-getCart');

            const data = {
              grade_id: product.grade_id,
              price_table_id: this.priceTableId,
              source: 'new-ecommerce',
              company: {
                id: this.companyId,
              },
            };
            
            productsRequest(data)
              .then((res) => {
                this.currentGrade = res['data'];
                console.log(this.currentGrade);

                for (let i = 0; i < this.currentGrade.products.length; i++) {
                  const prod = this.currentGrade.products[i];
                  
                  prod['image'] = prod['photo_cover']
                    ? 'https://p.biaction.com.br/' +
                    this.ecommerceConfigs['pd_id'] +
                    '/products/300x300/' +
                    prod['photo_cover']
                    : 'https://app.pedidosdigitais.com.br/img/sem-imagem-mini.png';
                  prod['has_discount'] =
                    prod['default_discount'] > 0 &&
                      prod['price_no_discounts'] > prod['price_with_discounts']
                      ? true
                      : false;
                  prod['price_default'] =
                    prod['price_default'] && prod['price_default'] > 0
                      ? prod['price_default']
                      : 0;
                  prod['price_no_discounts'] = prod['product_price_table'];
                  prod['price_with_discounts'] = prod['product_price_table'] - (prod['product_price_table'] * prod['default_discount']) / 100;
                  prod['total_discount_percentage'] = prod['has_discount']
                    ? numeral(
                      ((prod['price_with_discounts'] -
                        prod['price_no_discounts']) /
                        prod['price_no_discounts']) *
                      100
                    ).format('0')
                    : 0;
                  prod['stock_default'] = prod['stock'] > 0 ? prod['stock'] : 0;
                  prod['stock'] = this.getLocalStock(prod) + this.getFutureStock(prod);


                  prod.isInsideCart = false;
                  prod.isInsideCart = this.isInsideCart(prod.id) ? true : false;

                  if (prod.isInsideCart) {
                    prod.quantity = this.cartService.getProductQtd(prod.id);
                  }
                }

                $(`#modal-product-grade-${product.id}`).modal('show');
              })
              .catch((error) => {
                console.error(error);
              });
          }
        }
      }
    } else {
      this.router.navigate([`/login`]);
    }
  }

  changeQtd(product) {
    const qtd = $(`#add_cart_${product.id}`).val();
    product.quantity = qtd;
  }

  addToCart(product) {
    let qtdField = $(`#add_cart_${product.id}`).val();
    let msg = '';
    let qtd = 0;

    if(this.configType == 2) {
      if (product['stock'] && this.generalBlockLargestQuantityInCart) {
        if(qtdField > product['stock'] && this.validateStock){
          this.toastr.showWarning('Quantidade acima do estoque');
          return;
        }
      }
    } else if(this.configType == 1) {

      if (product['stock'] && this.generalBlockLargestQuantityInCart) {
        if(qtdField > product['stock'] && this.validateStock){
          this.toastr.showWarning('Quantidade acima do estoque');
          return;
        }
      }
    }

    

    const minimum = product.minimum ? product.minimum : 0;
    const multiple = product.multiple ? product.multiple : 0;
    const maximum = product.maximum ? product.maximum : 0;

    if (qtdField && qtdField !== '') {
      qtd = qtdField;
    }else{
      this.toastr.showWarning('A Quantidade não pode ser 0');
      return;
    }

    if (qtdField && qtdField < 0) {
      this.toastr.showWarning('A Quantidade não pode ser menor que 0');
      return;
    }

    if (minimum > 0 && qtdField > 0) {
      if (qtdField < minimum) {
        msg += 'A Quantidade mínima para esse produto é ' + minimum + '.<br>';
      }
    }

    if (maximum > 0 && qtdField > 0) {
      if (qtdField > maximum) {
        msg += 'A Quantidade máxima para esse produto é ' + maximum + '.<br>';
      }
    }

    if (multiple > 0 && qtdField > 0) {
      const rest =
        (parseFloat((100 * qtdField).toFixed(2)) %
          parseFloat((100 * multiple).toFixed(2))) /
        100;
      if (rest !== 0) {
        msg +=
          'A Quantidade deve ser um valor múltiplo de ' + multiple + '.<br>';
      }
    }

    if (msg !== '') {
      this.toastr.showWarning(msg);
    } else {
      this.cartService.setProduct(product, qtd, false);

      product.isInsideCart = true;
      this.toastr.showSuccess('Produto inserido ao carrinho com sucesso!');
    }
  }

  removeFromCart(product) {
    this.cartService.removeProduct(product.id);

    product.isInsideCart = false;
    this.toastr.showSuccess('Produto removido do carrinho com sucesso!');
  }

  openInfos(product) {  
    $(`#modal-infos-${product.id}`).modal('show');
  }

  loadProductsInWishlist(productId) {
    if (productId) {
      this.apiService.getProductsWishlistById(productId).subscribe((res) => {
        if (!res.error) {
          const wishlistIds = res.productsWishlist.map((item) => item.wishlist_id)
          wishlistIds.forEach((wishlistId) => {
            const checkbox = $(`#wishlist_${wishlistId}_${productId}`);
            if (checkbox.length) {
              checkbox.prop('checked', true);
            } else{
              checkbox.prop('checked', false);
            }
          });
        } else {
          console.error('Erro ao obter os produtos da lista de desejos:', res.message);
        }
        // this.showSpinner = true;
      });
    } else {
      console.error('ID da lista de desejos não encontrado');
    }
  }


  // ABRINDO O MODAL PRINCIPAL
  openWishlist(product) {

    this.productID = product.id;

    this.getWishlist();

    $(`#modal-wishlist-${product.id}`).modal('show');
  }

  // CARREGANDO AS WISHLISTS
  getWishlist(){
    this.wishlists = [];
    this.apiService.getWishlists().subscribe((res) => {
      if (!res.error) {
        this.wishlists = res.wishlists;
        this.loadProductsInWishlist(this.productID)
      } else {
        console.error('Desculpe! Nenhuma lista de desejos encontrada', res.message);
      }
    }, (err) => {
      console.error('Desculpe! Nenhuma lista de desejos encontrada', err);
    });
  }

  // CRIANDO UMA NOVA LISTA
  createNewWishlist() {
    if (this.newWishlistName.trim() === '') {
      alert('O nome da lista é obrigatório.');
      return;
    }
    this.apiService.createWishlist(this.newWishlistName).subscribe((res) => {
      if (!res.error) {

        this.apiService.getWishlists().subscribe((res) => {
          if (!res.error) {
            this.wishlists = res.wishlists;
          }
          this.toastrService.showSuccess('Lista Criada com sucesso!');
        });

        this.newWishlistName = '';
      } else {
        this.toastrService.showWarning('Desculpe! Lista ja existe');
      }
    }, (err) => {
      console.error('Desculpe! Lista ja existe', err);
      this.toastrService.showWarning('Desculpe! Lista ja existe');
    });
  }


  // codição para o checkbox
  onChangeFavorite(product: any, wishlistId: string) {
    const checkbox = $(`#wishlist_${wishlistId}_${product.id}`);
    if (checkbox.is(':checked')) {
      this.addProductList(wishlistId, product);
    } else {
      this.delProductList(wishlistId, product.id);
    }
  }

  // adiciona o produto na lista
  addProductList(wishlistId: string, product: any) {
    this.apiService.addProduct(wishlistId, product).subscribe((res) => {
      if (!res.error) {
        this.newFavorite = res.selected;
        this.toastrService.showSuccess('Produto adicionado com sucesso!');
      } else {
        this.toastrService.showError('Erro ao selecionar o produto. Por favor, tente novamente');
      }
    }, (err) => {
      console.error('Erro ao selecionar o item. Por favor, tente novamente:', err);
      this.toastrService.showError('Erro ao selecionar o produto. Por favor, tente novamente');
    });
  }


  // remove o produto na lista
  delProductList(wishlistId: string, productId: string) {

    this.apiService.delProduct(wishlistId, productId).subscribe((res) => {
        if (!res.error) {
            this.newFavorite = res.selected;
            this.toastrService.showSuccess('Produto deletado com sucesso!');
        } else {
          this.toastrService.showError('Erro ao excluir o item. Por favor, tente novamente');
        }
    }, (err) => {
        console.error('Erro ao excluir o produto. Por favor, tente novamente:', err);
        this.toastrService.showError('Erro ao excluir o produto. Por favor, tente novamente');
    });
  }

  getLocalStock(prod) {
    this.scopeStockQuota;
    let general = 'general';

    if(this.configType == 2)  {
      if(!this.ignoreStockQuota && this.generalEnableStockUsage){

        if (prod['stock_quota'] === undefined || prod['stock_quota'] === null || (Object.keys(prod['stock_quota']).length === 0)) {
          return prod['stock_default'];
        }
        
        if (this.scopeStockQuota === 'segment' && this.storeSegment !== null) {
          return this.getStockQuota(prod, this.storeSegment) ?? 0;
        } else if (this.scopeStockQuota === 'general') {
          return this.getStockQuota(prod, general) ?? 0;
        } 

      } else {
        //usar estoque local

        if(prod['stock_place_order_type'] !== undefined && prod['stock_place_order_type'] !== null) {
          return prod['stock_place_order_type'];
        } else if(prod['stock_place_enterprise'] !== undefined && prod['stock_place_enterprise'] !== null) {
          return prod['stock_place_enterprise'];
        } else if(prod['stock_place_store'] !== undefined && prod['stock_place_store'] !== null) {
          return prod['stock_place_store'];
        } else {
          return 0;
        }
      }
    } else if(this.configType == 1) {
      if(!this.ignoreStockQuota){

        if (prod['stock_quota'] === undefined || prod['stock_quota'] === null || (Object.keys(prod['stock_quota']).length === 0)) {
          return prod['stock_default'];
        }

        if (this.scopeStockQuota === 'segment' && this.storeSegment !== null) {
          return this.getStockQuota(prod, this.storeSegment) ?? 0;
        } else if (this.scopeStockQuota === 'general') {
          return this.getStockQuota(prod, general) ?? 0;
        }

      } else {
        //usar estoque local

        if(prod['stock_place_order_type'] !== undefined && prod['stock_place_order_type'] !== null) {
          return prod['stock_place_order_type'];
        } else if(prod['stock_place_enterprise'] !== undefined && prod['stock_place_enterprise'] !== null) {
          return prod['stock_place_enterprise'];
        } else if(prod['stock_place_store'] !== undefined && prod['stock_place_store'] !== null) {
          return prod['stock_place_store'];
        } else {
          return 0;
        }
      }

    }
  }

  getStockQuota(prod, param) {
    let totalQuota = 0;
    if (prod['stock_quota'] !== null && prod['stock_quota'][param] !== null && prod['stock_quota'][param] !== undefined) {
      return totalQuota = prod['stock_quota'][param];
    } else {
      return prod['stock_default'];
    }
  }

  getFutureStock(prod) {

    if(this.configType == 2) {
      
      if (this.generalEnableFutureStock && !this.generalStockFutureAdvisory) {
        const dataAtual = new Date();
  
        let totalFutureStock = 0;
    
        if (prod.future_stock && Array.isArray(prod.future_stock)) {
          for (const future of prod.future_stock) {
            const dataFuture = new Date(future.date);
    
            if (dataFuture > dataAtual) {
              return totalFutureStock += future.qty;
            }
          }
        }
    
        return 0;
      } else {
        return 0;
      }


    } else if(this.configType == 1) {

      if (this.enableFutureStock && !this.stockFutureAdvisory) {
        const dataAtual = new Date();
  
        let totalFutureStock = 0;
    
        if (prod.future_stock && Array.isArray(prod.future_stock)) {
          for (const future of prod.future_stock) {
            const dataFuture = new Date(future.date);
    
            if (dataFuture > dataAtual) {
              return totalFutureStock += future.qty;
            }
          }
        }
    
        return 0;
      } else {
        return 0;
      }
    }
    
  }

}
