import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ApiService } from '../../services/api/api.service';
import { UserService } from '../../services/user/user.service';
import * as numeral from 'numeral';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { CartService } from '../../services/carts/cart.service';
import { ToastrService } from '../../services/toastr/toastr-service.service';
import { SalePolicyService } from '../../services/sale-policy/sale-policy.service';
import { FirebaseApp } from '@angular/fire';
import * as moment from 'moment';
import { UrlService } from '../../services/url/url.service';
import { OrderService } from '../../services/order/order.service';

declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  inputs: ['slide-to'],
})
export class HomeComponent implements OnInit {

  @ViewChild('carouselBanners') carouselBanners!: ElementRef;

  user;

  products = [];
  banners = [];
  orderTypes = [];
  pageOfItems = [];

  selectedOrderType = {};

  loadedData: boolean = false;
  loadedBanner: boolean = false
  showQtdField = false;
  loggedOutPriceTable: String;
  isGrid = true;

  bannerImage = '';
  term = '';
  emphasis: boolean = false;
  is_featured = [];
  productSelected = {};
  groupedProducts: any[][] = [];

  private cachedData = {};

  paginatedData = [];
  page: number = 1;
  hitsPerPage: number = 20;
  nbHits: number;
  nbPages: number = 1;
  maxSize: number = 7;

  client_selected = {};
  client_cnpj = '';

  grades;

  msg = '';
  error = '';

  ncm_list: any;
  isento_st = false;

  basePath = `https://p.biaction.com.br/`;
  storeName = 'Ecommerce';
  urlBase = '';

  isLoggedIn: boolean;

  currentGrade = {
    description: 'Grade De Produtos',
    products: [],
  };

  defaultPriceTable: String;

  ecommerceConfigs = {};
  allCategories = [];
  selectedOrderTypeName = '';
  selectedOrderTypeId = '';
  selectedOrderTypeCode = '';
  selectedPriceTableId = '';
  resultOrderTypes = [];
  clientInfo = {};
  clients = [];
  idClient = '';
  listAllOrderTypes = [];
  rulesTypeOrderClient = [];
  rulesPriceTable = [];
  priceTableId = '';
  dontShowProductsWhenUserNotLogged = false;
  standardPriceTableId = ''
  hasStandardPriceTable = false;
  defaultClientPriceTable = '';
  standardPriceTable = '';
  listOfCategories = [];
  configType = 0;
  generalListProductsStockZero = '';
  generalBlockLargestQuantityInCart = '';
  generalValidateLargestQuantityInOrder = '';
  generalEnableStockUsage = '';
  generalApplyInventoryConfigsInEcommerce = '';
  generalEnableFutureStock = '';
  generalStockFutureAdvisory = '';
  ignoreQuotaStock = '';
  alsoAvailableForEcommerce = '';
  listProductsWithZeroStock = '';
  blockQuantityGreaterThanStockInCart = '';
  validateQuantityGreaterThanStockInOrder = '';
  enableFutureStock = '';
  stockFutureAdvisory = '';
  scopeStockQuota = '';
  ignoreStockQuota = '';
  segments = [];
  storeSegment = '';

  activeIndex = 0

  constructor(
    private firebase: FirebaseApp,
    private apiService: ApiService,
    private orderService: OrderService,
    private userService: UserService,
    private localStorageService: LocalStorageService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private cartService: CartService,
    private toastrService: ToastrService,
    private salePolicyService: SalePolicyService,
    private urlService: UrlService
  ) {
    this.ecommerceConfigs['biaction_id'] = this.localStorageService.get('biaction_id');
    this.term = this.route.snapshot.params['term'];
    this.isLoggedIn = this.authService.isLoggedIn();

    this.urlBase = this.urlService.getUrlBase();
  }

  ngOnInit() {
    this.route.params.subscribe((val) => {
      this.term = this.route.snapshot.params['term'];
      this.getEcommerceConfigs();
      this.getGrades();
      this.getCategoriesWithChildren();
      this.getAllOrderTypes();
    });
  }

  getSegments() {
    this.apiService.getUserGroup().subscribe((res) => {
      if (res['error']) {
        console.log('Erro ao importar grupo de clientes!');
      } else {
        this.segments = res['userGroup'];
        let findSegment = this.segments.find((item) => item.id === this.client_selected['segment_id']);
        this.storeSegment = findSegment['code'];
        this.localStorageService.set('storeSegment', this.storeSegment);
      }
    });
  }

  async getEcommerceConfigs() {
    // Método Principal para pegar configurações da Empresa e salvar no Storage
    await this.authService.getInfosByUrl('?url_base=' + this.urlBase);

    // Método para setar as configurações do Ecommerce
    this.ecommerceConfigs = this.authService.setEcommerceConfigs();

    // Banners
    this.banners = this.ecommerceConfigs['banners'] !== '' ? this.ecommerceConfigs['banners'].split(',') : [];
    this.loadedBanner = true;
    this.initializeCarousel();

    // Método para buscar as configurações do Ecommerce
    this.getEcommercePolicy();
  }


  initializeCarousel() {
    const carouselElement = this.carouselBanners.nativeElement;

     (window as any).$(carouselElement).carousel({
      interval: 3000,
      ride: 'carousel'
    });

    (window as any).$(carouselElement).on('slid.bs.carousel', (e: any) => {
      this.activeIndex = (e.to !== undefined) ? e.to : 0;
    });
  }

  getClients(companyId) {
    this.apiService.getStoresByCompany('?company_id=' + companyId).subscribe((res) => {
      if (res['error']) {
        console.error(res['message']);
      } else {
        this.clients = res['resp']['stores'];
        this.idClient = this.clients[0]['id'];
        if (this.clients !== undefined) {
          this.clients.forEach(cli => {
            cli['client_since'] = cli['client_since'] ? moment(cli['client_since'], 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY') : '-';
          });
        }
      }
    });

    setTimeout(() => {
      this.getStoreRulesOfPriceTable();
    }, 1000);
  }

  getEcommercePolicy() {
    this.salePolicyService.getEcommercePolice(this.ecommerceConfigs['biaction_id']).then((res) => {
      this.showQtdField = res && res['show_qtd_field'] ? res['show_qtd_field'] : false;
      this.loggedOutPriceTable = res && res['logged_out_price_table'] ? res['logged_out_price_table'] : '';
      this.dontShowProductsWhenUserNotLogged = res && res['dont_show_products_when_client_offline'] ? res['dont_show_products_when_client_offline'] : false;
      this.standardPriceTableId = res && res['ecommerce_price_table_id'] ? res['ecommerce_price_table_id'] : '';

      if (!this.isLoggedIn && this.dontShowProductsWhenUserNotLogged) {
        this.router.navigateByUrl('login').then(() => {
          // window.location.reload();
        }).catch(error => {
          console.log('Error on navigateByUrl', error);
          window.location.reload();
        });
      }

      this.getPricingPolicy();
    });
  }

  getCategoriesWithChildren() {
    const isUserLoggedIn = this.isLoggedIn;

    const getCategoryChildrenSubscription = this.apiService.getCategoryChildrenById(1, isUserLoggedIn, this.ecommerceConfigs['pd_id']);

    const handleResponse = (res) => {
      if (!res['error']) {
        this.allCategories = res['categories'];
      }
    };

    getCategoryChildrenSubscription.subscribe(handleResponse);
  }

  getPricingPolicy() {
    this.salePolicyService.getPolices(this.ecommerceConfigs['biaction_id']).then((res) => {
      this.defaultPriceTable = res && res['default_price_table'] ? res['default_price_table'] : '';

      // Verifica se o Usuário está LOGADO
      if (this.isLoggedIn) {
        this.getUser();
      } else {
        this.getAllProducts();
      }
    });
  }

  getUser() {
    this.userService.getUserData().then((res) => {
      if (!res['error']) {
        this.user = res['user'];
        this.client_selected = this.user.store ? this.user.store : {};
        this.clientInfo = res['user']['company_id'];
        this.getClients(res['user']['store']['company_id']);

        if (this.client_selected['doc_number'] && this.client_selected['doc_number'] &&
          this.client_selected['doc_number'] !== '' && this.client_selected['doc_number'].length > 0) {
          this.client_cnpj = this.client_selected['doc_number'].replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
            '\$1.\$2.\$3/\$4-\$5');
          if (this.client_selected['doc_number'].length === 11) {
            this.client_cnpj = this.client_selected['doc_number'].replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g,
              '\$1.\$2.\$3-\$4');
          }
        }

        if (!this.user.company) {
          this.toastrService.showError('Cliente com grupo de cliente não configurado, por favor, atualize o cadastro e tente novamente.');
          setTimeout(() => {
            this.authService.authLogout();
            window.location.href = '/login';
            return;
          }, 4000);
        }
        this.getPriceTableById();
      }
    });

    setTimeout(() => {
      this.getSegments();
    }, 1000);

    
  }

  getPriceTableById() {
    this.apiService.getPriceTablesAll().subscribe((res) => {
      if (!res['error']) {
        const priceTables = res['price_tables'];

        if (this.standardPriceTableId !== '') {
          this.hasStandardPriceTable = true;
          this.standardPriceTable = priceTables.find(
            (priceTable) => priceTable.code === this.standardPriceTableId
          );
        }

        this.defaultClientPriceTable = priceTables.find(
          (priceTable) => priceTable.id === this.user.store.default_price_table
        );

        //tem tabela padrão no cliente
        if (this.defaultClientPriceTable) {
          this.user.store.default_price_table_code = this.defaultClientPriceTable['code'];
        
        //não tem tabela no cliente e tem tabela na config
        } else if (this.standardPriceTable) {
          this.user.store.default_price_table_code = this.standardPriceTable['code'];

        } else {
          this.toastrService.showError('Cliente sem tabela de preço padrão, por favor, atualize o cadastro e tente novamente.');
          return;
        }


        this.getTaxRules();

        setTimeout(() => {
          this.getListEcommerceOrderTypes();
        }, 1500);
      }
    });
  }

  getTaxRules() {
    const store = this.user && this.user.store ? this.user.store : {};
    const tax_rules_id = store.tax_rules_id ? store.tax_rules_id : '';

    if (tax_rules_id !== '') {
      this.apiService.getTaxRules(tax_rules_id).subscribe((res) => {
        if (!res['error']) {
          const fiscalTaxRules = res['fiscalTaxRules'];
          if (fiscalTaxRules !== undefined) {
            this.isento_st = fiscalTaxRules.isento_st
              ? fiscalTaxRules.isento_st
              : false;
            this.ncm_list = fiscalTaxRules.ncm;
          }
        }
      });
    }
  }

  openChangeOrderTypeModal() {
    $('#modalChangeOrderType').modal('show');
    $('#order_type_select').select2({});
    $('#order_type_select').prop('selectedIndex', 0).change();
  }

  changeOrderTypeEcommerce() {
    const orderType = $('#order_type_select').val();
    const that = this;
    this.localStorageService.set('listProductsOfCategories', '');

    if (orderType && orderType !== '') {
      const btn = $('changeOrderType');
      btn.html('<span class="spinner-border spinner-border-sm" aria-hidden"true"></span>Alterando ...');
      btn.prop('disabled', true);
      $('#cancelOrderTypeChange').prop('disabled', true);

      let order = orderType.split(' ');
      this.localStorageService.set('selectedOrderTypeId', order[0]);
      this.localStorageService.set('selectedOrderTypeCode', order[1]);
      this.localStorageService.remove('carts');


      setTimeout(function () {
        $('#modalChangeOrderType').modal('hide');
        btn.html('<i class="fa-solid fa-check"></i> Confirmar');
        btn.prop('disabled', false);
        $('#cancelOrderTypeChange').prop('disabled', false);
      }, 4000);

      this.router.navigateByUrl('home').then(() => {
        window.location.reload();
      }).catch(error => {
        console.log('Error on navigateByUrl', error);
        window.location.reload();
      })
    }
  }

  getListEcommerceOrderTypes() {
    const that = this;

    this.apiService
      .getOrderTypes(this.isLoggedIn, this.ecommerceConfigs['pd_id'])
      .subscribe((res) => {
        if (!res['error']) {
          res['order_types'].forEach((type) => {
            if (typeof type.data.ecommerce !== 'undefined' && type.data.ecommerce.active === true) {
              that.orderTypes.push({
                id: type.id,
                name: type.data.name,
                categories: type.data.ecommerce.categories,
                priceTableId: type.data.price_tables
              });
            }
          });

          if (that.orderTypes.length > 0) {
            that.orderTypes.forEach(orderType => {
              let findOrderType = that.listAllOrderTypes.find(item => item.code === orderType.id)
              if (findOrderType) {
                orderType['code'] = orderType['id'];
                orderType['id'] = findOrderType['id'];
              }
            })
          }

          setTimeout(() => {
            this.getStoreRulesByOrderType();
          }, 2000);
        }
      });
  }

  getGrades() {
    this.apiService.getGrades('').subscribe((res) => {
      if (res['error']) {
        console.log('Erro ao recuperar as grades');
      } else {
        this.grades = res['grades'];
      }
    });
  }

  getStoreRulesByOrderType() {
    this.apiService.getStoreOrderTypeByClientId(this.idClient).subscribe((res) => {

      if (res['error']) {
        console.log('Error');
      } else {
        if (res !== undefined) {
          this.rulesTypeOrderClient = res['rules'] ? res['rules'] : [];

          if (this.rulesTypeOrderClient.length > 0) {
            this.rulesTypeOrderClient.forEach(rules => {
              let find;
              find = this.listAllOrderTypes.find(item => item.id === rules.order_type_id);

              if (find) {
                rules['code'] = find['code']
                rules['name'] = find['name']
                rules['id'] = rules['order_type_id']
              }
            });
          }
        }
      }
    });

    this.getAllowedProductsToUser();

  }

  getAllOrderTypes() {
    const that = this;
    that.apiService.getOrderType().subscribe((res) => {
      if (res['error']) {
        console.log('Erro ao importar os tipos do pedido!');
      } else {
        this.listAllOrderTypes = res['order_types'];
      }
    });
  }

  getAllowedProductsToUser() {
    const that = this;
    let is_search = false;
    this.paginatedData = [];
    this.loadedData = false;

    if (this.rulesTypeOrderClient.length > 0) {
      this.orderTypes = this.rulesTypeOrderClient;
    }

    that.selectedOrderType = this.localStorageService.get('selectedOrderTypeId');
    if (that.selectedOrderType === undefined || that.selectedOrderType === null || that.selectedOrderType === '') {
      if (that.orderTypes.length > 0) {
        //Quando é o primeiro acesso vai selecionar o primeiro tipo de pedido da lista
        that.selectedOrderType = that.orderTypes[0].id
        this.localStorageService.set('selectedOrderTypeCode', that.orderTypes[0].code);

      } else {
        that.selectedOrderType = '';
      }
    }

    let findOrderType = this.orderTypes.find(item => String(item.id) === String(this.selectedOrderType));

    this.getOrderTypeDetails(findOrderType.code);

    if (findOrderType) {
      this.selectedOrderTypeName = findOrderType['name'];
      this.selectedOrderTypeId = findOrderType['id'];
      this.selectedOrderTypeCode = findOrderType['code'];
      this.selectedPriceTableId = findOrderType['priceTableId'];
    }

    //verifica se o tipo de pedido tem tabela de preço
    if (this.selectedPriceTableId.length > 0) {
      this.selectedPriceTableId = this.selectedPriceTableId[0];
      this.priceTableId = this.selectedPriceTableId;

      //caso não  tenha tabela de preço no tipo de pedido, pega a tabela padrão o cliente
    } else if (this.user.store.default_price_table_code !== '') {
      this.priceTableId = this.user.store.default_price_table_code;

      //caso não tenha tabela padrão do cliente, pega a tabela padrão do ecommerce
    } else {
      this.priceTableId = this.standardPriceTableId;

    }

    if (this.priceTableId === '' || this.priceTableId === undefined || this.priceTableId === null) {
      this.priceTableId = this.user.store.default_price_table_code;
    }

    if (this.term && this.term !== '') {
      is_search = true;
    }

    if (
      typeof this.cachedData[this.page] !== 'undefined' &&
      this.cachedData[this.page]['products'].length > 0 &&
      !is_search
    ) {
      const cachedData = this.cachedData[this.page];

      this.paginatedData = cachedData['products'];
      this.nbHits = cachedData['nbHits'];
      this.nbPages = cachedData['nbPages'];
      this.maxSize = cachedData['maxSize'];

      this.loadedData = true;
    } else {
      // this.firebase.functions().useFunctionsEmulator('http://localhost:5001');

      this.localStorageService.set('priceTableId', this.priceTableId);

      const productsRequest = this.firebase
        .functions()
        .httpsCallable('products-getCart');

      const data = {
        name: this.term ? this.term : '',
        price_table_id: String(this.priceTableId),
        page: this.page,
        source: 'new-ecommerce',
        company: {
          id: this.ecommerceConfigs['biaction_id'],
        },
        order_type_id: this.selectedOrderTypeCode,
      };

      setTimeout(() => {
        productsRequest(data)
        .then((res) => {
          const currentPage = res['data']['current_page'];
          const fullPage = res['data']['full_page'];

          if (res['data']['products']) {
            res['data']['products'].forEach((prod) => {
              const categoryId = prod.category_id;
              const findCategory = this.allCategories.find((cat) => String(categoryId) === String(cat.id));
              if (findCategory === undefined || (findCategory !== undefined && findCategory.allow_ecommerce === 1)) {

                prod['image'] = prod['photo_cover']
                  ? 'https://p.biaction.com.br/' +
                  this.ecommerceConfigs['pd_id'] +
                  '/products/300x300/' +
                  prod['photo_cover']
                  : 'https://app.pedidosdigitais.com.br/img/sem-imagem-mini.png';
                prod['has_discount'] =
                  prod['default_discount'] > 0 &&
                    prod['price_no_discounts'] > prod['price_with_discounts']
                    ? true
                    : false;
                prod['price_default'] =
                  prod['price_default'] && prod['price_default'] > 0
                    ? prod['price_default']
                    : 0;
                prod['price_no_discounts'] = prod['product_price_table'];
                prod['price_with_discounts'] = prod['product_price_table'] - (prod['product_price_table'] * prod['default_discount']) / 100;
                prod['total_discount_percentage'] = prod['has_discount']
                  ? numeral(
                    ((prod['price_with_discounts'] -
                      prod['price_no_discounts']) /
                      prod['price_no_discounts']) *
                    100
                  ).format('0')
                  : 0;

                prod['stock_default'] = prod['stock'] > 0 ? prod['stock'] : 0;
                prod['stock'] = this.getLocalStock(prod) + this.getFutureStock(prod);
                    
                const findGrade = this.grades.find((grade) => {
                  return grade.product_id.toString() === prod.id;
                });

                if (findGrade) {
                  prod['grade_id'] = findGrade['id'];
                }

                if (typeof this.cachedData[this.page] === 'undefined') {
                  this.cachedData[this.page] = {
                    nbHits: 44,
                    nbPages: fullPage,
                    maxSize: fullPage,
                    products: [],
                  };
                }

                const ipi = prod.ipi;
                if (
                  prod.ncm !== '' &&
                  this.ncm_list !== undefined &&
                  Object.keys(this.ncm_list).length > 0
                ) {
                  const ncm = this.ncm_list[prod.ncm];
                  if (ncm !== undefined) {
                    const state = this.user.store.address_state;
                    const ipi_ncm = ncm.ipi;
                    const isento_ipi = ncm.isento_ipi ? ncm.isento_ipi : false;
                    if (!isento_ipi) {
                      if (ipi_ncm && !isNaN(ipi_ncm)) {
                        prod['ipi'] = ipi_ncm;
                      } else {
                        prod['ipi'] = ipi !== -1 && ipi !== null ? ipi : 0;
                      }
                    } else {
                      prod['ipi'] = 0;
                    }
                    if (!this.isento_st) {
                      if (prod.fromabroad) {
                        prod['st'] =
                          ncm.im !== undefined && ncm.im[state] !== undefined
                            ? ncm.im[state]
                            : 0;
                      } else {
                        prod['st'] =
                          ncm.st !== undefined && ncm.st[state] !== undefined
                            ? ncm.st[state]
                            : 0;
                      }
                    } else {
                      prod['st'] = 0;
                    }
                    prod['tax_discount'] =
                      ncm.tax_discount !== undefined &&
                        ncm.tax_discount[state] !== undefined
                        ? ncm.tax_discount[state]
                        : 0;
                  } else {
                    prod['ipi'] = ipi !== -1 && ipi !== null ? ipi : 0;
                    prod['st'] = 0;
                    prod['tax_discount'] = 0;
                  }
                } else {
                  prod['ipi'] = ipi !== -1 && ipi !== null ? ipi : 0;
                  prod['st'] = 0;
                  prod['tax_discount'] = 0;
                }

                let listOfCategoriesLinkedToOrderType = this.localStorageService.get('filterCategories');

                if (!listOfCategoriesLinkedToOrderType) {
                  listOfCategoriesLinkedToOrderType = [];
                }

                if (listOfCategoriesLinkedToOrderType.length === 0 ||
                  (listOfCategoriesLinkedToOrderType.length > 0 && listOfCategoriesLinkedToOrderType.includes(prod.category_id))) {
                  this.paginatedData.push(prod);

                  if(this.configType == 2) {
                    if(!this.generalListProductsStockZero){
                      this.paginatedData = this.paginatedData.filter((item) => item.stock > 0);
                    }
              
                  } else if(this.configType == 1) {
                    if(!this.listProductsWithZeroStock){
                      this.paginatedData = this.paginatedData.filter((item) => item.stock > 0);
                    }
                  }
                 
                }

                this.cachedData[this.page]['products'].push(prod);
              }
            });
            this.is_featured = this.paginatedData.filter((prod) => prod.is_featured == 1);
            if (this.is_featured.length > 0) {
              this.groupProducts()
              this.emphasis = true;
            }
          }

          this.nbHits = 1;
          this.nbPages = fullPage;
          this.maxSize = fullPage;
          this.page = currentPage;

          if (this.user.client.allow_popup && !is_search && this.page == 1) {
            this.openBannerPopup();
          }

          this.loadedData = true;

          if (this.term && this.term !== '') {
            setTimeout(function () {
              that.highlightFoundTerms();
            }, 1000);
          }
        })
        .catch((error) => {
          console.error(error);
        });
      }, 2000);
    }

    // setTimeout(() => {
      // this.checkIfProductsAreInOrderTypeCategory();
    // }, 2500);

    // this.getOrderTypeDetails(findOrderType.code); 

  }

  getOrderTypeDetails(id) {
    const that = this;
    this.apiService.getOrderTypeDetails(this.ecommerceConfigs['biaction_id'], id, function (err, res) {
      if (err) {
        this.toastrService.showError(err, 'Erro', { enableHTML: true });
        console.log('error.:', err);
        return;
      } else {
        that.configType = res['stock']['use_stock'];
        that.localStorageService.set('configType', that.configType);

        if (that.configType !== undefined) {
          that.getGeneralStockPolicy();
          that.getStockPolicy(id);
        }
      }
    });
  }

  getGeneralStockPolicy() {

    this.salePolicyService.getPolices(this.ecommerceConfigs['biaction_id']).then((res) => {
      if (res) {
        this.generalEnableStockUsage = res['fields']['stock'];
        this.generalApplyInventoryConfigsInEcommerce = res['fields']['stock_ecommerce'];

        this.localStorageService.set('generalEnableStockUsage', this.generalEnableStockUsage);
        this.localStorageService.set('generalApplyInventoryConfigsInEcommerce', this.generalApplyInventoryConfigsInEcommerce);
      }
    });

    this.salePolicyService.getOrdersModule(this.ecommerceConfigs['biaction_id']).then((res) => {
      if (res) {

        //Listar os produtos com estoque zerado
        this.generalListProductsStockZero = res['show_products_with_zero_stock'];
        this.localStorageService.set('generalListProductsStockZero', this.generalListProductsStockZero);

        //Bloquear quantidade maior que o estoque no carrinho
        this.generalBlockLargestQuantityInCart = res['stock_lock'];
        this.localStorageService.set('generalBlockLargestQuantityInCart', this.generalBlockLargestQuantityInCart);

        //Validar quantidade maior que o estoque no pedido
        this.generalValidateLargestQuantityInOrder = res['stock_validate'];
        this.localStorageService.set('generalValidateLargestQuantityInOrder', this.generalValidateLargestQuantityInOrder);

        //Habilitar estoque futuro
        this.generalEnableFutureStock = res['future_stock'];
        this.localStorageService.set('generalEnableFutureStock', this.generalEnableFutureStock);

        //Habilitar estoque futuro apenas consultivo
        this.generalStockFutureAdvisory = res['stock_future_advisory'];
        this.localStorageService.set('generalStockFutureAdvisory', this.generalEnableFutureStock);

        //Escopo do estoque quota
        this.scopeStockQuota = res['scope_stock_quota'];
        if(this.scopeStockQuota !== undefined && this.scopeStockQuota !== null) {
          this.localStorageService.set('scopeStockQuota', this.scopeStockQuota);
        }
        
      }
    });

}

  getStockPolicy(id) {
    const that = this;
    this.apiService.getOrderTypeDetails(this.ecommerceConfigs['biaction_id'], id, function (err, res) {
      if (err) {
        this.toastrService.showError(err, 'Erro', { enableHTML: true });
        console.log('error.:', err);
        return;
      } else {

        that.ignoreQuotaStock = res['ignore_stock_quota']; 
        that.localStorageService.set('ignoreQuotaStock', that.ignoreQuotaStock);

        that.alsoAvailableForEcommerce = res['stock']['stock_ecommerce'];
        that.localStorageService.set('alsoAvailableForEcommerce', that.alsoAvailableForEcommerce);

        that.listProductsWithZeroStock = res['stock']['show_products_with_zero_stock'];
        that.localStorageService.set('listProductsWithZeroStock', that.listProductsWithZeroStock);

        that.blockQuantityGreaterThanStockInCart = res['stock']['stock_lock'];
        that.localStorageService.set('blockQuantityGreaterThanStockInCart', that.blockQuantityGreaterThanStockInCart);

        that.validateQuantityGreaterThanStockInOrder = res['stock']['stock_validate'];
        that.localStorageService.set('validateQuantityGreaterThanStockInOrder', that.validateQuantityGreaterThanStockInOrder);

        that.enableFutureStock = res['stock']['future_stock'];
        that.localStorageService.set('enableFutureStock', that.enableFutureStock);

        that.stockFutureAdvisory = res['stock']['stock_future_advisory'];
        that.localStorageService.set('stockFutureAdvisory', that.stockFutureAdvisory);

        that.ignoreStockQuota = res['ignore_stock_quota'];
        that.localStorageService.set('ignoreStockQuota', that.ignoreStockQuota);

      }
    });

  }

  checkIfProductsAreInOrderTypeCategory() {
    let categoriesFiltered = this.localStorageService.get('filterCategories');

    if(categoriesFiltered !== '' && categoriesFiltered !== undefined && categoriesFiltered !== null) {
      if (categoriesFiltered.length > 0) {
        this.listOfCategories = this.localStorageService.get('listOfCategories');
      }
    }

    let listOfCategoriesLinkedToOrderType = this.localStorageService.get('listOfCategoriesLinkedToOrderType');

    if (this.listOfCategories.length > 0 && this.paginatedData.length > 0) {
      const productsAreInOrderTypeCategory = this.paginatedData.filter(item2 =>
        this.listOfCategories.some(item1 => item1.id === item2.category_id)
      );

      //valida se tem produtos em destaque
      if (this.groupedProducts.length > 0) {

        this.groupedProducts.forEach(prod => {
          const productsFiltered = prod.filter(item2 =>
            this.listOfCategories.some(item1 => item1.id === item2.category_id)
          );

          //valida se tem categoria para o tipo de pedido e altera os produtos em destaque
          if (productsFiltered.length > 0 && listOfCategoriesLinkedToOrderType.length > 0) {
            this.groupedProducts = [];
            this.groupedProducts.push(productsFiltered);
          }
        });
      }
      this.paginatedData = productsAreInOrderTypeCategory;
    }
  }

  getStoreRulesOfPriceTable() {
    this.apiService.getPriceTablesByOrderTypesByClientId(this.idClient).subscribe((res) => {
      if (res['error']) {
        console.log('Regras não encontradas!');
      } else {
        if (res !== undefined) {
          const rules = res['rules'] ? res['rules'] : [];
          if (rules.length > 0) {
            this.rulesPriceTable = rules
          }
        }
      }
    });
  }

  groupProducts(): void {
    let groupSize;
    const screenWidth = window.innerWidth;

    if (screenWidth >= 1400) {
      groupSize = 5;
    } else if (screenWidth >= 770) {
      groupSize = 4;
    } else if (screenWidth >= 550) {
      groupSize = 3;
    } else if (screenWidth >= 576) {
      groupSize = 2;
    } else {
      groupSize = 1;
    }

    for (let i = 0; i < this.is_featured.length; i += groupSize) {
      this.groupedProducts.push(this.is_featured.slice(i, i + groupSize));
    }
  }


  getAllProducts() {
    const that = this;
    this.paginatedData = [];
    this.loadedData = false;
    let is_search = false;

    if (this.term && this.term !== '') {
      is_search = true;
    }

    if (typeof this.cachedData[this.page] !== 'undefined' && this.cachedData[this.page]['products'].length > 0) {
      const cachedData = this.cachedData[this.page];

      this.paginatedData = cachedData['products'];
      this.nbHits = cachedData['nbHits'];
      this.nbPages = cachedData['nbPages'];
      this.maxSize = cachedData['maxSize'];

      this.loadedData = true;
    } else {
      // this.firebase.functions().useFunctionsEmulator('http://localhost:5001');
      const productsRequest = this.firebase.functions().httpsCallable('products-getCart');

      const store_access = this.ecommerceConfigs['store_access'] ? this.ecommerceConfigs['store_access'] : 2;
      const priceTableId = store_access === 1 ? this.loggedOutPriceTable ? this.loggedOutPriceTable :
        this.defaultPriceTable : '';

      const data = {
        name: this.term ? this.term : '',
        price_table_id: priceTableId,
        page: this.page,
        source: 'new-ecommerce',
        company: {
          id: this.ecommerceConfigs['biaction_id'],
        },
      };

      productsRequest(data)
        .then((res) => {
          const currentPage = res['data']['current_page'];
          const fullPage = res['data']['full_page'];

          if (res['data']['products']) {
            res['data']['products'].forEach((prod) => {
              prod['image'] = prod['photo_cover']
                ? 'https://p.biaction.com.br/' +
                this.ecommerceConfigs['pd_id'] +
                '/products/300x300/' +
                prod['photo_cover']
                : 'https://app.pedidosdigitais.com.br/img/sem-imagem-mini.png';

              prod['has_discount'] =
                prod['default_discount'] > 0 &&
                  prod['price_no_discounts'] > prod['price_with_discounts']
                  ? true
                  : false;
              prod['price_default'] =
                prod['price_default'] && prod['price_default'] > 0
                  ? prod['price_default']
                  : 0;
              prod['price_no_discounts'] = prod['product_price_table'];
              prod['price_with_discounts'] = prod['product_price_table'] - (prod['product_price_table'] *
                prod['default_discount']) / 100;
              prod['total_discount_percentage'] = prod['has_discount']
                ? numeral(
                  ((prod['price_with_discounts'] -
                    prod['price_no_discounts']) /
                    prod['price_no_discounts']) *
                  100
                ).format('0')
                : 0;

              prod['stock_default'] = prod['stock'] > 0 ? prod['stock'] : 0;
              prod['stock'] = this.getLocalStock(prod) + this.getFutureStock(prod);

              if (typeof this.cachedData[this.page] === 'undefined') {
                this.cachedData[this.page] = {
                  nbHits: 44,
                  nbPages: fullPage,
                  maxSize: fullPage,
                  products: [],
                };
              }

              const ipi = prod.ipi;
              if (
                prod.ncm !== '' &&
                this.ncm_list !== undefined &&
                Object.keys(this.ncm_list).length > 0
              ) {
                const ncm = this.ncm_list[prod.ncm];
                if (ncm !== undefined) {
                  const state = this.user.store.address_state;
                  const ipi_ncm = ncm.ipi;
                  const isento_ipi = ncm.isento_ipi ? ncm.isento_ipi : false;
                  if (!isento_ipi) {
                    if (ipi_ncm && !isNaN(ipi_ncm)) {
                      prod['ipi'] = ipi_ncm;
                    } else {
                      prod['ipi'] = ipi !== -1 && ipi !== null ? ipi : 0;
                    }
                  } else {
                    prod['ipi'] = 0;
                  }
                  if (!this.isento_st) {
                    if (prod.fromabroad) {
                      prod['st'] =
                        ncm.im !== undefined && ncm.im[state] !== undefined
                          ? ncm.im[state]
                          : 0;
                    } else {
                      prod['st'] =
                        ncm.st !== undefined && ncm.st[state] !== undefined
                          ? ncm.st[state]
                          : 0;
                    }
                  } else {
                    prod['st'] = 0;
                  }
                  prod['tax_discount'] =
                    ncm.tax_discount !== undefined &&
                      ncm.tax_discount[state] !== undefined
                      ? ncm.tax_discount[state]
                      : 0;
                } else {
                  prod['ipi'] = ipi !== -1 && ipi !== null ? ipi : 0;
                  prod['st'] = 0;
                  prod['tax_discount'] = 0;
                }
              } else {
                prod['ipi'] = ipi !== -1 && ipi !== null ? ipi : 0;
                prod['st'] = 0;
                prod['tax_discount'] = 0;
              }

              this.paginatedData.push(prod);
              this.cachedData[this.page]['products'].push(prod);
            });
            this.is_featured = this.paginatedData.filter((prod) => prod.is_featured == 1);
            if (this.is_featured.length > 0) {
              this.groupProducts()
              this.emphasis = true;
            }
          }

          this.nbHits = 1;
          this.nbPages = fullPage;
          this.maxSize = fullPage;
          this.page = currentPage;

          this.loadedData = true;

          if (this.page == 1) {
            this.openBannerPopup();
          }

          if (this.term && this.term !== '') {
            setTimeout(function () {
              that.highlightFoundTerms();
            }, 1000);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  onPageChange(page: number): void {
    this.page = page;

    if (this.isLoggedIn) {
      this.getAllowedProductsToUser();
    } else {
      this.getAllProducts();
    }

  }

  changeColor(event, type) {

    if (type === 'list') {
      this.emphasis = false;
    } else {
      this.emphasis = true;
    }

    event.preventDefault();
    const target = event.currentTarget.id;

    if (target === 'pills-list-tab') {
      this.isGrid = false;
      $('#pills-grid')
        .removeClass('show active')
        .animate({ overflow: 'hidden', opacity: '0.4' }, 1300, 'easeOutCubic');
      $('#pills-list')
        .addClass('show active')
        .animate({ opacity: '1' }, 1000, 'easeOutCubic');

      if ($('#pills-list-tab svg path').hasClass('icon-active')) {
        $('#pills-list-tab svg path').removeClass('icon-active');
        $('#pills-list-tab svg path').addClass('icon-inactive');
      } else {
        $('#pills-list-tab svg path').addClass('icon-active');
        $('#pills-list-tab svg path').removeClass('icon-inactive');
        $('#pills-grid-tab svg path').removeClass('icon-active');
        $('#pills-grid-tab svg path').addClass('icon-inactive');
      }
    } else {
      event.preventDefault();
    }

    if (target === 'pills-grid-tab') {
      this.isGrid = true;
      $('#pills-list')
        .removeClass('show active')
        .animate({ overflow: 'hidden', opacity: '0.4' }, 1300, 'easeOutCubic');
      $('#pills-grid')
        .addClass('show active')
        .animate({ opacity: '1' }, 1000, 'easeOutCubic');

      if ($('#pills-grid-tab svg path').hasClass('icon-active')) {
        $('#pills-grid-tab svg path').removeClass('icon-active');
        $('#pills-grid-tab svg path').addClass('icon-inactive');
      } else {
        $('#pills-grid-tab svg path').addClass('icon-active');
        $('#pills-grid-tab svg path').removeClass('icon-inactive');
        $('#pills-list-tab svg path').removeClass('icon-active');
        $('#pills-list-tab svg path').addClass('icon-inactive');
      }
    } else {
      event.preventDefault();
    }
  }

  changeOrderType() {
    const orderType = $('#order-type').val();
    const find = this.orderTypes.find(
      (t) => String(t.id) === String(orderType)
    );

    if (find.length) {
      this.selectedOrderType = orderType;
    }
  }

  getStorageUrl(path) {
    path = path.replace(/^\/?(storage\/)?uploads\//, '');

    if (path.indexOf('/') === 0) {
      path = path.substr(1);
    }

    return `https://p.biaction.com.br/${this.ecommerceConfigs['pd_id']}/${path}`;
  }

  changeHitsPerPage() {
    const hitsPerPage = parseInt($('#hitsPerPage').val());
    this.hitsPerPage = hitsPerPage;

    this.cachedData = [];
    this.getAllowedProductsToUser();
  }

  openBannerPopup() {
    if (this.ecommerceConfigs['store_popup_image'] !== '' && this.ecommerceConfigs['show_banner']) {
      this.bannerImage = `https://p.biaction.com.br/${this.ecommerceConfigs['pd_id']}/${this.ecommerceConfigs['store_popup_image']}`;

      $('#banner-modal').modal('show');
    }
  }

  closeBannerModal() {
    this.localStorageService.set('show_banner', false);
  }

  trackByFn(item) {
    return item.id;
  }

  highlightFoundTerms() {
    const searchText = this.term;
    const regex = new RegExp(searchText, 'gi');

    $.each($('.product-content .product-description a'), (index, element) => {
      const text = $(element)
        .text()
        .replace(/(<mark class="highlight">|<\/mark>)/gim, '');
      const newText = text.replace(
        regex,
        '<mark class="highlight" style="background-color: #ff0">$&</mark>'
      );

      $(element).html(newText);
    });
  }

  openProductDetails(product) {
    /* Só navegará para Detalhes do Produto, se o item.grade_id
    for null e o produto não possuir nenhuma relação na tabela grade. */

    const minimum = product.minimum ? product.minimum : 0;
    const multiple = product.multiple ? product.multiple : 0;
    const maximum = product.maximum ? product.maximum : 0;
    let msg = '';

    let goToPreCart = false,
      qtd = 0;

    if (this.showQtdField) {
      let qtdField = $(`#${product.id}_qtd`).val();

      if (qtdField && qtdField !== '') {
        goToPreCart = true;
        qtd = qtdField;
      }

      if (minimum > 0 && qtdField > 0) {
        if (qtdField < minimum) {
          msg += 'A Quantidade mínima para esse produto é ' + minimum + '.<br>';
        }
      }

      if (maximum > 0 && qtdField > 0) {
        if (qtdField > maximum) {
          msg += 'A Quantidade máxima para esse produto é ' + maximum + '.<br>';
        }
      }

      if (multiple > 0 && qtdField > 0) {
        const rest =
          (parseFloat((100 * qtdField).toFixed(2)) %
            parseFloat((100 * multiple).toFixed(2))) /
          100;
        if (rest !== 0) {
          msg +=
            'A Quantidade deve ser um valor múltiplo de ' + multiple + '.<br>';
        }
      }
    }

    if (msg !== '') {
      this.toastrService.showWarning(msg);
    } else {
      if (goToPreCart) {
        this.router.navigate([`/precart/${product.id}`], {
          queryParams: { qtd: qtd },
        });
      } else {
        if (product.grade === null) {
          this.router.navigate([`/products/${product.id}`], {
            queryParams: {
              page: this.page,
              orderTypeId: this.selectedOrderType,
            },
          });
        } else {
          if (
            product.grade &&
            product.grade.products &&
            product.grade.products.length > 0
          ) {
            this.currentGrade = product.grade;

            for (let i = 0; i < this.currentGrade.products.length; i++) {
              const prod = this.currentGrade.products[i];

              prod.variations = prod.variations
                ? JSON.parse(prod.variations)
                : [];

              prod.isInsideCart = false;
              prod.isInsideCart = this.isInsideCart(prod.id) ? true : false;

              if (prod.isInsideCart) {
                prod.quantity = this.cartService.getProductQtd(prod.id);
              }
            }

            $('#modal-product-grade').modal('show');
          } else {
            this.router.navigate([`/products/${product.id}`], {
              queryParams: {
                page: this.page,
                orderTypeId: this.selectedOrderType,
              },
            });
          }
        }
      }
    }
  }

  addToCart(product) {
    const qtd = $(`#add_cart_${product.id}`).val();
    this.cartService.setProduct(product, qtd, false);

    product.isInsideCart = true;
    this.toastrService.showSuccess('Produto inserido ao carrinho com sucesso!');
  }

  removeFromCart(product) {
    this.cartService.removeProduct(product.id);

    product.isInsideCart = false;
    this.toastrService.showSuccess('Produto removido do carrinho com sucesso!');
  }

  changeQtd(product) {
    const qtd = $(`#add_cart_${product.id}`).val();
    product.quantity = qtd;
  }

  isInsideCart(productId) {
    return this.cartService.isInsideCart(productId);
  }

  getProductCartQtd(product) {
    this.cartService.getProductQtd(product);
  }

  openInfos(product) {
    this.productSelected = product;
    $('#modal-products-infos').modal('show');
  }

  // função para pegar dados do componente filho
  filterProducts(res) {
    this.paginatedData = [];
    const that = this;

    const currentPage = res['data']['current_page'];
    const fullPage = res['data']['full_page'];

    if (res['data']['products']) {
      res['data']['products'].forEach((prod) => {
        const categoryId = prod.category_id;
        const findCategory = this.allCategories.find((cat) => String(categoryId) === String(cat.id));
        if (findCategory === undefined || (findCategory !== undefined && findCategory.allow_ecommerce === 1)) {

          prod['image'] = prod['photo_cover'] ?
            'https://p.biaction.com.br/' + this.ecommerceConfigs['pd_id'] + '/products/300x300/' + prod['photo_cover'] :
            'https://app.pedidosdigitais.com.br/img/sem-imagem-mini.png';
          prod['has_discount'] = prod['default_discount'] > 0 &&
            prod['price_no_discounts'] > prod['price_with_discounts'] ? true : false;
          prod['price_default'] = prod['price_default'] && prod['price_default'] > 0 ? prod['price_default'] : 0;
          prod['price_no_discounts'] = prod['product_price_table'];
          prod['price_with_discounts'] = prod['product_price_table'] -
            (prod['product_price_table'] * prod['default_discount']) / 100;
          prod['total_discount_percentage'] = prod['has_discount'] ?
            numeral(((prod['price_with_discounts'] - prod['price_no_discounts']) / prod['price_no_discounts']) * 100
            ).format('0') : 0;
            let stock = prod['stock_place_order_type'] > 0 ? prod['stock_place_order_type'] : prod['stock'];
            // prod['stock'] = stock > 0 ? stock : 0;

          prod['stock_default'] = prod['stock'] > 0 ? prod['stock'] : 0;
          prod['stock'] = this.getLocalStock(prod) + this.getFutureStock(prod);

          const findGrade = this.grades.find((grade) => {
            return grade.product_id.toString() === prod.id;
          });

          if (findGrade) {
            prod['grade_id'] = findGrade['id'];
          }

          if (typeof this.cachedData[this.page] === 'undefined') {
            this.cachedData[this.page] = {
              nbHits: 44,
              nbPages: fullPage,
              maxSize: fullPage,
              products: [],
            };
          }

          const ipi = prod.ipi;
          if (
            prod.ncm !== '' &&
            this.ncm_list !== undefined &&
            Object.keys(this.ncm_list).length > 0
          ) {
            const ncm = this.ncm_list[prod.ncm];
            if (ncm !== undefined) {
              const state = this.user.store.address_state;
              const ipi_ncm = ncm.ipi;
              const isento_ipi = ncm.isento_ipi ? ncm.isento_ipi : false;
              if (!isento_ipi) {
                if (ipi_ncm && !isNaN(ipi_ncm)) {
                  prod['ipi'] = ipi_ncm;
                } else {
                  prod['ipi'] = ipi !== -1 && ipi !== null ? ipi : 0;
                }
              } else {
                prod['ipi'] = 0;
              }
              if (!this.isento_st) {
                if (prod.fromabroad) {
                  prod['st'] = ncm.im !== undefined && ncm.im[state] !== undefined ? ncm.im[state] : 0;
                } else {
                  prod['st'] = ncm.st !== undefined && ncm.st[state] !== undefined ? ncm.st[state] : 0;
                }
              } else {
                prod['st'] = 0;
              }
              prod['tax_discount'] = ncm.tax_discount && ncm.tax_discount[state] ? ncm.tax_discount[state] : 0;
            } else {
              prod['ipi'] = ipi !== -1 && ipi !== null ? ipi : 0;
              prod['st'] = 0;
              prod['tax_discount'] = 0;
            }
          } else {
            prod['ipi'] = ipi !== -1 && ipi !== null ? ipi : 0;
            prod['st'] = 0;
            prod['tax_discount'] = 0;
          }

          this.paginatedData.push(prod);
          
          if(this.configType == 2) {
            
            if(!this.generalListProductsStockZero){
              this.paginatedData = this.paginatedData.filter((item) => item.stock > 0);
            }

          } else if(this.configType == 1) {
            if(!this.listProductsWithZeroStock){
              this.paginatedData = this.paginatedData.filter((item) => item.stock > 0);
            }
          }
          
          this.cachedData[this.page]['products'].push(prod);
        }
      });
    }

    this.nbHits = 1;
    this.nbPages = fullPage;
    this.maxSize = fullPage;
    this.page = currentPage;
  }

   // Função para calcular o estoque futuro
   updateStock(paginatedData) {
    const dataAtual = new Date();

    paginatedData.forEach(item => {
      if (item.future_stock && Array.isArray(item.future_stock)) {''
        item.future_stock.forEach(future => {
          const dataFuture = new Date(future.date); 

          if (dataFuture > dataAtual) {
            item.stock += future.qty;
          }
        });
      }
    });

    return paginatedData;
  }

  getLocalStock(prod) {
    this.scopeStockQuota;
    let general = 'general';

    if(this.configType == 2)  {
      if(!this.ignoreStockQuota && this.generalEnableStockUsage){

        if (prod['stock_quota'] === undefined || prod['stock_quota'] === null || (Object.keys(prod['stock_quota']).length === 0)) {
          return prod['stock_default'];
        }
        
        if (this.scopeStockQuota === 'segment' && this.storeSegment !== null) {
          return this.getStockQuota(prod, this.storeSegment) ?? 0;
        } else if (this.scopeStockQuota === 'general') {
          return this.getStockQuota(prod, general) ?? 0;
        } 

      } else {
        //usar estoque local

        if(prod['stock_place_order_type'] !== undefined && prod['stock_place_order_type'] !== null) {
          return prod['stock_place_order_type'];
        } else if(prod['stock_place_enterprise'] !== undefined && prod['stock_place_enterprise'] !== null) {
          return prod['stock_place_enterprise'];
        } else if(prod['stock_place_store'] !== undefined && prod['stock_place_store'] !== null) {
          return prod['stock_place_store'];
        } else {
          return 0;
        }
      }
    } else if(this.configType == 1) {
      if(!this.ignoreStockQuota){

        if (prod['stock_quota'] === undefined || prod['stock_quota'] === null || (Object.keys(prod['stock_quota']).length === 0)) {
          return prod['stock_default'];
        }

        if (this.scopeStockQuota === 'segment' && this.storeSegment !== null) {
          return this.getStockQuota(prod, this.storeSegment) ?? 0;
        } else if (this.scopeStockQuota === 'general') {
          return this.getStockQuota(prod, general) ?? 0;
        }

      } else {
        //usar estoque local

        if(prod['stock_place_order_type'] !== undefined && prod['stock_place_order_type'] !== null) {
          return prod['stock_place_order_type'];
        } else if(prod['stock_place_enterprise'] !== undefined && prod['stock_place_enterprise'] !== null) {
          return prod['stock_place_enterprise'];
        } else if(prod['stock_place_store'] !== undefined && prod['stock_place_store'] !== null) {
          return prod['stock_place_store'];
        } else {
          return 0;
        }
      }

    }
  }

  getStockQuota(prod, param){
    let totalQuota = 0;
    if(prod['stock_quota'] !== null && prod['stock_quota'][param] !== null && prod['stock_quota'][param] !== undefined){
      return totalQuota = prod['stock_quota'][param];
    } else {
      return 0;
    }
    
  }

  getFutureStock(prod) {
    if(this.configType == 2) {
      
      if (this.generalEnableFutureStock && !this.generalStockFutureAdvisory) {
        const dataAtual = new Date();
  
        let totalFutureStock = 0;
    
        if (prod.future_stock && Array.isArray(prod.future_stock)) {
          for (const future of prod.future_stock) {
            const dataFuture = new Date(future.date);
    
            if (dataFuture > dataAtual) {
              return totalFutureStock += future.qty;
            }
          }
        }
    
        return 0;
      } else {
        return 0;
      }


    } else if(this.configType == 1) {

      if (this.enableFutureStock && !this.stockFutureAdvisory) {
        const dataAtual = new Date();
  
        let totalFutureStock = 0;
    
        if (prod.future_stock && Array.isArray(prod.future_stock)) {
          for (const future of prod.future_stock) {
            const dataFuture = new Date(future.date);
    
            if (dataFuture > dataAtual) {
              return totalFutureStock += future.qty;
            }
          }
        }
    
        return 0;
      } else {
        return 0;
      }
    }
    
  }
}
