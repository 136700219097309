<div class="sellentt-col-xl-12">
  <div class="card-header text-white px-4 secondary-color-ground">
    <div class="d-flex justify-content-center align-items-center w-100">
      <h3 class="mb-1 text-center">
        POLÍTICA DE PRIVACIDADE
      </h3>
    </div>
  </div>
</div>

<div class="sellentt-col-xl-12">
  <div class="privacy-policy" [innerHTML]="text_privacy_policy"></div>
</div>



