import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api/api.service';
import { UserService } from '../../services/user/user.service';

import * as numeral from 'numeral';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';

declare var $: any;

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {

  loadedData: boolean = false;
  pageOfItems = [];

  user;
  orders = [];
  order: any;
  allOrders: any[] = [];


  api_conditions: { search: string | null, date: string | null, order_type_id: string | null } = { search: null, date: null, order_type_id: null };
  search = '';
  date = '';
  orderType = '';
  noResults: boolean = false;


  paginatedData = [];
  page: number = 1;
  hitsPerPage: number = 20;
  nbHits: number;
  nbPages: number = 1;
  maxSize: number = 7;
  isLoggedIn: boolean;
  ecommerceConfigs = {};
  orderTypes = [];
  listAllOrderTypes = [];

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private router: Router,
    private authService: AuthService,
    private localStorageService: LocalStorageService,
  ) { 

    this.isLoggedIn = this.authService.isLoggedIn();
    this.ecommerceConfigs['biaction_id'] = this.localStorageService.get('biaction_id');
  }

  ngOnInit() {
    this.getUser();
    this.getAllOrderTypes();
  }

  getUser() {
    this.userService.getUserData().then((res) => {
      if (!res['error']) {
        this.user = res['user'];
      }

      this.getUserOrders();
    });
  }

  getUserOrders() {
    this.paginatedData = [];
    this.loadedData = false;

    this.apiService.getUserOrdersPaginated({
      user: this.user,
      page: this.page,
      hitsPerPage: this.hitsPerPage,
    }).subscribe((res) => {

      if (!res['error']) {
        this.orders = res['orders'];

        res['orders'].forEach((order) => {
          this.paginatedData.push(order);
        });

        this.noResults = false;
        this.nbHits = res['nbHits'];
        this.nbPages = res['nbPages'];
        this.maxSize = res['maxSize'];

      }

      this.loadedData = true;
    });
  }

  orderDetails(order) {
    this.order = order;

    this.router.navigate([`/orders/${this.order['code']}`]);
  }

  onPageChange(page: number): void {
    this.page = page;
    this.getUserOrders();
  }

  filter() {
    this.api_conditions = {
      search: this.search ? this.search : null,
      date: this.date ? this.date : null,
      order_type_id: this.orderType ? this.orderType : null
    };
    if (!this.api_conditions.search && !this.api_conditions.date && !this.api_conditions.order_type_id) {
      this.getUserOrders();
    } else {
      this.page = 1;
      this.allOrders = [];

      this.getAllUserOrders();
    }
  }


  getAllOrderTypes() {
    const that = this;
    that.apiService.getOrderType().subscribe((res) => {
      if (res['error']) {
        console.log('Erro ao importar os tipos do pedido!');
      } else {
        this.listAllOrderTypes = res['order_types'];
      }
    });

    this.getListEcommerceOrderTypes();
  }

  getListEcommerceOrderTypes() {
    const that = this;

    this.apiService
      .getOrderTypes(this.isLoggedIn, this.ecommerceConfigs['pd_id'])
      .subscribe((res) => {
        if (!res['error']) {
          res['order_types'].forEach((type) => {
            if (typeof type.data.ecommerce !== 'undefined' && type.data.ecommerce.active === true) {
              that.orderTypes.push({
                id: type.id,
                name: type.data.name,
                categories: type.data.ecommerce.categories,
                priceTableId: type.data.price_tables
              });
            }
          });

          if (that.orderTypes.length > 0) {
            that.orderTypes.forEach(orderType => {
              let findOrderType = that.listAllOrderTypes.find(item => item.code === orderType.id)
              if (findOrderType) {
                orderType['code'] = orderType['id'];
                orderType['id'] = findOrderType['id'];
              }
            })
          }
        }
      });
  }

  getAllUserOrders() {
    this.paginatedData = [];
    this.loadedData = false;
    this.noResults = false;

    const loadAllPages = (page) => {
      this.apiService.getUserOrdersPaginated({
        user: this.user,
        page: page,
        hitsPerPage: this.hitsPerPage,
      }).subscribe((res) => {
        if (!res['error']) {
          this.allOrders = this.allOrders.concat(res['orders']);
          if (page < res['nbPages'] && page < this.maxSize) {
            loadAllPages(page + 1);
          } else {
            this.applyFilters();
          }
        }
      });
    };
    loadAllPages(this.page);
  }


  applyFilters() {
    if (this.api_conditions.search || this.api_conditions.date || this.api_conditions.order_type_id) {
      this.paginatedData = this.allOrders.filter((order) => {
        const matchesSearch = this.api_conditions.search ?
          (order.code == this.api_conditions.search || order.buyer_name == this.api_conditions.search) : true;
        const matchesOrderType = this.api_conditions.order_type_id ?
        (order.order_type_id == this.api_conditions.order_type_id) : true;  
        const matchesDate = this.api_conditions.date ?
          new Date(order.created_at).toISOString().split('T')[0] == this.api_conditions.date : true;

        return matchesSearch && matchesOrderType && matchesDate;
      });
      if (this.paginatedData.length === 0) {
        this.noResults = true;
      } else {
        this.noResults = false;
      }
    } else {
      this.paginatedData = this.allOrders;
    }
    this.loadedData = true;
  }



}
