import { Component, OnInit, ViewEncapsulation, Renderer2 } from '@angular/core';
import { SalePolicyService } from '../../services/sale-policy/sale-policy.service';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PrivacyPolicyComponent implements OnInit {

  ecommerceConfigs;
  text_privacy_policy = '';

  constructor(
    private renderer: Renderer2,
    private salePolicyService: SalePolicyService,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    const categorySection = document.querySelector('.category-section');
    if (categorySection) {
      this.renderer.setStyle(categorySection, 'display', 'none');
    }
    this.ecommerceConfigs = this.authService.setEcommerceConfigs();
    this.getEcommercePolicy();
  }

  getEcommercePolicy() {
    this.salePolicyService.getEcommercePolice(this.ecommerceConfigs['biaction_id']).then((res) => {
      this.text_privacy_policy = res['text_privacy_policy'];
    });
  }

}
