import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {UserService} from '../../services/user/user.service';

import * as numeral from 'numeral';
import {CartService} from '../../services/carts/cart.service';
import {ActivatedRoute, Router} from '@angular/router';
import {LocalStorageService} from '../../services/local-storage/local-storage.service';
import {AuthService} from '../../services/auth/auth.service';
import {FirebaseApp} from '@angular/fire';

@Component({
  selector: 'app-precart',
  templateUrl: './precart.component.html',
  styleUrls: ['./precart.component.css'],
})
export class PrecartComponent implements OnInit {
  clientId;
  firebaseId;
  productId;

  orderTypeId;

  allProducts = [];
  pageOfItems = [];

  product = {
    quantity: 1,
  };
  user: any;
  cart = {
    products: [],
  };

  qtd = 1;

  loadedData = false;
  isLoggedIn;
  selectedPriceTableId = '';

  configType = 0;
  generalEnableFutureStock = '';
  generalStockFutureAdvisory = '';
  enableFutureStock = '';
  stockFutureAdvisory = '';
  scopeStockQuota = '';
  ignoreStockQuota = '';
  generalEnableStockUsage = '';
  storeSegment = '';

  constructor(
    private firebase: FirebaseApp,
    private userService: UserService,
    private apiService: ApiService,
    private cartService: CartService,
    private route: ActivatedRoute,
    private router: Router,
    private localStorageService: LocalStorageService,
    private authService: AuthService
  ) {
    this.clientId = this.localStorageService.get('pd_id');
    this.firebaseId = this.localStorageService.get('biaction_id');

    this.isLoggedIn = this.authService.isLoggedIn();
  }

  ngOnInit() {
    const routeParams = this.route.snapshot.params;
    const queryParams = this.route.snapshot.queryParams;

    this.orderTypeId = queryParams['orderTypeId'];

    const productId = routeParams['id'];

    this.qtd = parseInt(queryParams['qtd']) || 1;

    if (productId) {
      this.productId = productId;
      this.getUser();
    } else {
      this.getUser();
    }
  }

  getValuesOfStorage() {
    this.configType = this.localStorageService.get('configType');
    this.generalEnableFutureStock = this.localStorageService.get('generalEnableFutureStock');
    this.generalStockFutureAdvisory = this.localStorageService.get('generalStockFutureAdvisory');
    this.enableFutureStock = this.localStorageService.get('enableFutureStock');
    this.stockFutureAdvisory = this.localStorageService.get('stockFutureAdvisory');
    this.scopeStockQuota = this.localStorageService.get('scopeStockQuota');
    this.ignoreStockQuota = this.localStorageService.get('ignoreStockQuota');
    this.generalEnableStockUsage = this.localStorageService.get('generalEnableStockUsage');
    this.storeSegment = this.localStorageService.get('storeSegment');
  }

  getUser() {
    this.userService.getUserData().then((res) => {
      if (!res['error']) {
        this.user = res['user'];
      }

      this.getPriceTableById();
    });
  }

  getPriceTableById() {
    this.apiService.getPriceTablesAll().subscribe((res) => {
      if (!res['error']) {
        // console.log(this.user);
        const priceTables = res['price_tables'];

        const storePriceTable = priceTables.find(
          (priceTable) => priceTable.id === this.user.store.default_price_table
        );

        this.getAllowedProductsToUser();
      }
    });
  }

  getAllowedProductsToUser() {
    const productsRequest = this.firebase
      .functions()
      .httpsCallable('products-getCart');

      let selectedOrderTypeId = this.localStorageService.get('selectedOrderTypeCode');
      this.selectedPriceTableId = this.localStorageService.get('priceTableId');

    const data = {
      price_table_id: this.selectedPriceTableId,
      source: 'new-ecommerce',
      company: {
        id: this.firebaseId,
      },
      ids: [this.productId],
      order_type_id: selectedOrderTypeId,
    };

    productsRequest(data).then((res) => {
      this.allProducts = res['data']['products'];

      this.allProducts.forEach((prod) => {
        prod['image'] = prod['photo_cover']
          ? 'https://p.biaction.com.br/' +
          this.user.client_id +
          '/products/300x300/' +
          prod['photo_cover']
          : 'https://app.pedidosdigitais.com.br/img/sem-imagem-mini.png';
        prod['has_discount'] =
          prod['default_discount'] > 0 &&
          prod['price_no_discounts'] > prod['price_with_discounts']
            ? true
            : false;
        prod['price_default'] =
          prod['price_default'] && prod['price_default'] > 0
            ? prod['price_default']
            : 0;
        prod['price_no_discounts'] = prod['product_price_table'];
        prod['price_with_discounts'] =
          prod['product_price_table'] -
          (prod['product_price_table'] * prod['default_discount']) / 100;
        prod['total_discount_percentage'] = prod['has_discount']
          ? numeral(
            ((prod['price_with_discounts'] - prod['price_no_discounts']) /
              prod['price_no_discounts']) *
            100
          ).format('0')
          : 0;
        prod['stock_default'] = prod['stock'] > 0 ? prod['stock'] : 0;
        prod['stock'] = this.getLocalStock(prod) + this.getFutureStock(prod);
      });

      this.product = this.allProducts.find(
        (p) => String(p.id) === String(this.productId)
      );

      this.addToCart();
    });
  }

  addToCart() {
    if (this.product) {
      this.cartService.setProduct(this.product, this.qtd);
    }
    this.loadedData = true;
  }

  keepShopping() {
    this.router.navigate(['/']);
  }

  goToCart() {
    this.router.navigate(['/cart']);
  }

  onChangePage(pageOfItems) {
    this.pageOfItems = pageOfItems;
  }

  getLocalStock(prod) {
    this.scopeStockQuota;
    let general = 'general';

    if(this.configType == 2)  {
      if(!this.ignoreStockQuota && this.generalEnableStockUsage){

        if (prod['stock_quota'] === undefined || prod['stock_quota'] === null || (Object.keys(prod['stock_quota']).length === 0)) {
          return prod['stock_default'];
        }
        
        if (this.scopeStockQuota === 'segment' && this.storeSegment !== null) {
          return this.getStockQuota(prod, this.storeSegment) ?? 0;
        } else if (this.scopeStockQuota === 'general') {
          return this.getStockQuota(prod, general) ?? 0;
        } 

      } else {
        //usar estoque local

        if(prod['stock_place_order_type'] !== undefined && prod['stock_place_order_type'] !== null) {
          return prod['stock_place_order_type'];
        } else if(prod['stock_place_enterprise'] !== undefined && prod['stock_place_enterprise'] !== null) {
          return prod['stock_place_enterprise'];
        } else if(prod['stock_place_store'] !== undefined && prod['stock_place_store'] !== null) {
          return prod['stock_place_store'];
        } else {
          return 0;
        }
      }
    } else if(this.configType == 1) {
      if(!this.ignoreStockQuota){

        if (prod['stock_quota'] === undefined || prod['stock_quota'] === null || (Object.keys(prod['stock_quota']).length === 0)) {
          return prod['stock_default'];
        }

        if (this.scopeStockQuota === 'segment' && this.storeSegment !== null) {
          return this.getStockQuota(prod, this.storeSegment) ?? 0;
        } else if (this.scopeStockQuota === 'general') {
          return this.getStockQuota(prod, general) ?? 0;
        }

      } else {
        //usar estoque local

        if(prod['stock_place_order_type'] !== undefined && prod['stock_place_order_type'] !== null) {
          return prod['stock_place_order_type'];
        } else if(prod['stock_place_enterprise'] !== undefined && prod['stock_place_enterprise'] !== null) {
          return prod['stock_place_enterprise'];
        } else if(prod['stock_place_store'] !== undefined && prod['stock_place_store'] !== null) {
          return prod['stock_place_store'];
        } else {
          return 0;
        }
      }

    }
  }

  getStockQuota(prod, param) {
    let totalQuota = 0;
    if (prod['stock_quota'] !== null && prod['stock_quota'][param] !== null && prod['stock_quota'][param] !== undefined) {
      return totalQuota = prod['stock_quota'][param];
    } else {
      return prod['stock_default'];
    }
  }

  getFutureStock(prod) {

    if(this.configType == 2) {
      
      if (this.generalEnableFutureStock && !this.generalStockFutureAdvisory) {
        const dataAtual = new Date();
  
        let totalFutureStock = 0;
    
        if (prod.future_stock && Array.isArray(prod.future_stock)) {
          for (const future of prod.future_stock) {
            const dataFuture = new Date(future.date);
    
            if (dataFuture > dataAtual) {
              return totalFutureStock += future.qty;
            }
          }
        }
    
        return 0;
      } else {
        return 0;
      }


    } else if(this.configType == 1) {

      if (this.enableFutureStock && !this.stockFutureAdvisory) {
        const dataAtual = new Date();
  
        let totalFutureStock = 0;
    
        if (prod.future_stock && Array.isArray(prod.future_stock)) {
          for (const future of prod.future_stock) {
            const dataFuture = new Date(future.date);
    
            if (dataFuture > dataAtual) {
              return totalFutureStock += future.qty;
            }
          }
        }
    
        return 0;
      } else {
        return 0;
      }
    }
    
  }
}
