<!-- ============== Header  ============================ -->

<div class="sellentt-col-xl-12" *ngIf="currentGrade.products.length > 0 && showSpinner">
  <div class="card-header text-white px-4 secondary-color-ground">
    <div class="d-flex justify-content-between align-items-center w-100">
      <div class="d-flex align-items-center" style="margin-left: 100px;">
        <label for="category_id" class="control-label me-2 mb-0 d-flex align-items-center">
          <i class="fas fa-layer-group me-1" style="margin-right: 10px;"></i>
          Categorias:
        </label>
        <select name="category_id" id="category_id" class="form-control" style="max-width: 200px; margin-left: 10px;"
          (change)="filter()" [(ngModel)]="selectedCategoryId">
          <option value="">- Todas -</option>
          <option *ngFor="let e of categories" [value]="e.id">{{ e.name }}</option>
        </select>
      </div>
      <h3 class="mb-0 text-center" style="margin-right: 100px;">
        {{ catalogs.name }}
      </h3>
      <div style="margin-right: 100px;">
        <a (click)="openModal()" class="text-white text-decoration-none">
          <i class="fa-solid fa-share"></i> COMPARTILHE
        </a>
      </div>
    </div>
  </div>
</div>


<div class="sellentt-col-xl-12" *ngIf="currentGrade.products.length <= 0 && showSpinner">
  <div class="card-header text-white px-4 secondary-color-ground">
    <div class="d-flex justify-content-between align-items-center w-100">
      <div class="d-flex align-items-center" style="margin-left: 100px;">
        <label for="category_id" class="control-label me-2 mb-0 d-flex align-items-center">
          <i class="fas fa-layer-group me-1" style="margin-right: 10px;"></i>
          Categorias:
        </label>
        <select name="category_id" id="category_id" class="form-control" style="max-width: 200px; margin-left: 10px;"
          (change)="filter()" [(ngModel)]="selectedCategoryId">
          <option value="">- Todas -</option>
          <option *ngFor="let e of categories" [value]="e.id">{{ e.name }}</option>
        </select>


      </div>
      <h3 class="mb-0 text-center" style="margin-right: 880px;">
        {{ catalogs.name }}
      </h3>

    </div>
  </div>
</div>

<!-- <div class="sellentt-col-xl-12" *ngIf="currentGrade.products.length <= 0">
  <div class="card-header text-white px-4 secondary-color-ground"
    style="border: 1px solid var(--secondary-color-ground);">
    <div class="d-flex flex-column justify-content-center align-items-center w-100">
      <h3 class="mb-0 text-center" style="margin: 0; text-align: center;">
        {{ catalogs.name }}
      </h3>
    </div>
  </div>
</div> -->


<!-- ============== Fim Header  ============================ -->

<!-- ============== banner ============================ -->
<div class="sellentt-col-xl-12" style="padding-left: 70px; padding-right: 70px;">
  <div id="carousel-banners" class="carousel slide" data-ride="carousel" ride="carousel" interval="3000">
    <ol class="carousel-indicators" *ngIf="banners.length > 0">
      <li data-target="#carousel-banners" *ngFor="let banner of banners; let i = index;" attr.data-slide-to="{{i}}"
        [ngClass]="i === 0 ? 'active' : ''">
      </li>
    </ol>
    <div class="carousel-inner">
      <div [ngClass]="i === 0 ? 'carousel-item active' : 'carousel-item'"
        *ngFor="let banner of banners; let i = index;">
        <img
          [ngClass]="banner !== 'default.jpg' ? 'd-block w-100 banner-image image-sellentt-has-banner' : 'd-block w-100 banner-image image-sellentt'"
          src="{{storage}}/{{clientId}}/banners/{{banner.image}}">
      </div>
    </div>
  </div>
</div>
<!-- ======================= Fim banner ============================ -->

<!-- ======================= showSpinner  ============================ -->
<div *ngIf="!showSpinner"
  class="sk-spinner sk-spinner-three-bounce m-5 w-100 min-vh-100 d-flex justify-content-center align-items-center">
  <div class="sk-bounce1"></div>
  <div class="sk-bounce2"></div>
  <div class="sk-bounce3"></div>
</div>
<!-- ======================= Fim showSpinner ============================ -->


<!-- ================== CATALOGO COM PRODUTO E COM BANNER ============= -->
<div *ngIf="currentGrade.products.length > 0 && banners.length > 0 && showSpinner">
  <div class="tab-content" id="pills-tabContent">
    <div class="tab-pane fade show active" id="pills-grid" role="tabpanel" aria-labelledby="pills-grid-tab"
      tabindex="0">

      <div class="card-body text-white" style="background-color: var(--banner-color);">
        <div
          class="sellentt-row sellentt-row-cols-1 sellentt-row-cols-sm-2 sellentt-row-cols-md-3 sellentt-row-cols-xl-4 sellentt-row-cols-xxl-5 g-2"
          *ngIf="currentGrade.products.length > 0">
          <div class="sellentt-col" *ngFor="let item of currentGrade.products; trackBy: trackByFn">
            <app-product [type]="'card'" [insideCatalog]="true" [product]="item" [orderTypeId]="selectedOrderType['id']"
              [priceTableId]="user?.store?.default_price_table_code ? user.store.default_price_table_code : null">
            </app-product>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
<!-- ================== FIM CATALOGO COM PRODUTO E COM BANNER ============= -->


<!-- ================== CATALOGO COM PRODUTO E SEM BANNER ============= -->
<div *ngIf="currentGrade.products.length > 0 && banners.length <= 0 && showSpinner">
  <div class="tab-content" id="pills-tabContent">
    <div class="tab-pane fade show active" id="pills-grid" role="tabpanel" aria-labelledby="pills-grid-tab"
      tabindex="0">
      <div class="card-body text-white" style="background-color: var(--banner-color);">
        <div
          class="sellentt-row sellentt-row-cols-1 sellentt-row-cols-sm-2 sellentt-row-cols-md-3 sellentt-row-cols-xl-4 sellentt-row-cols-xxl-5 g-2"
          *ngIf="currentGrade.products.length > 0">
          <div class="sellentt-col" *ngFor="let item of currentGrade.products; trackBy: trackByFn">
            <app-product [type]="'card'" [insideCatalog]="true" [product]="item" [orderTypeId]="selectedOrderType['id']"
              [priceTableId]="user?.store?.default_price_table_code ? user.store.default_price_table_code : null">
            </app-product>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- ================== FIM DO CATALOGO COM PRODUTO E SEM BANNER ============= -->


<!-- ================== CATALOGO SEM PRODUTO E SEM BANNER ============= -->
<div *ngIf="currentGrade.products.length <= 0 && banners.length <= 0 && showSpinner"
  class="d-flex justify-content-center align-items-center min-vh-100 bg-light">
  <div class="text-center p-5 border rounded shadow-lg bg-white">
    <div class="mb-4">
      <i class="fa-solid fa-exclamation-circle text-warning" style="font-size: 3rem;"></i>
    </div>
    <h4 class="display-5 fw-bold text-dark mb-3">
      Nenhum produto encontrado!
    </h4>
    <p class="text-muted" style="font-size: 1.2rem;">
      Não encontramos nenhum produto no momento. Por favor, volte mais tarde.
    </p>
  </div>
</div>
<!-- ================== FIM DO CATALOGO COM PRODUTO E SEM BANNER ============= -->


<!-- ======================= Modal Compartilhar  ============================ -->

<div class="modal inmodal " id="modal-Share" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content animated fadeIn">
      <div class="modal-header  secondary-color-ground">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <p class="p-share">
          <i class="fa fa-share-alt " aria-hidden="true"></i> COMPARTILHAR
        </p>
      </div>
      <div class="modal-body">
        <a class="product-social-share" href="https://api.whatsapp.com/send?1=pt_BR&text={{link}}">
          <i class="fa fa-whatsapp"></i>
        </a>
        <a class="product-social-share" href="https://www.facebook.com/sharer/sharer.php?u={{link}}">
          <i class="fa-brands fa-facebook"></i>
        </a>
        <a class="product-social-share" href="https://x.com/intent/post?url={{link}}">
          <span class="bi--twitter-x"></span>
        </a>
        <a class="product-social-share"
          href="https://www.linkedin.com/shareArticle?mini=true&url={{link}}&title=Confira%20este%20catálogo&summary=Aqui%20está%20um%20catálogo%20que%20pode%20lhe%20interessar!&source=ecommerce.teste.com.br"
          target="_blank">
          <i class="fa fa-linkedin"></i>
        </a>
        <a class="product-social-share"
          href="mailto:?subject=Confira este catálogo&body=Confira este catálogo: {{link}}">
          <i class="fa fa-envelope"></i>
        </a>
        <div class="copy-url-container">
          <input type="text" id="copyUrl" value="{{link}}">
          <button (click)="copyToClipboard()">Copiar URL</button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- =======================  Fim modal Compartilhar  ============================ -->


<!--
<div class="sellentt-row sellentt-row-cols-12" *ngIf="currentGrade.products.length <= 0">
  <div class="sellentt-col">
    <div class="alert alert-warning text-center">
      <h2>
        <i class="fa-solid fa-exclamation-circle"></i>
        Nenhum produto encontrado!
      </h2>
    </div>
  </div>
</div>

-->
