<div class="sellentt-container" *ngIf="loadedData">
  <div class="sellentt-row">
    <div class="sellentt-col-xl-12">

      <div class="sellentt-row">
        <div class="sellentt-col-xl-12">
          <div class="product-wrapper">
            <div class="sellentt-row pt-5" align="center">
              <div class="sellentt-col-xl-6 sellentt-col-xl-12">
                <div class="progress-container-circle">
                  <div class="progress-bar" role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"
                    data-content="20%"></div>
                </div>
              </div>

              <div class="sellentt-col-xl-6 sellentt-col-xl-12">
                <div class="progress-container">
                  <div class="progress" id="progress"></div>
                  <div class="circle active">
                    <i class="fa-solid fa-shopping-cart fa-2x1"></i>
                  </div>
                  <div class="arrow mt-3">
                    <i class="fa-solid fa-arrow-right-long fa-2x1"></i>
                  </div>
                  <div class="circle" id="user-circle">
                    <i class="fa-solid fa-user fa-2x1"></i>
                  </div>
                  <div class="arrow mt-3">
                    <i class="fa-solid fa-arrow-right-long"></i>
                  </div>
                  <div class="circle" id="payment-circle">
                    <i class="fa-solid fa-credit-card fa-2x1"></i>
                  </div>
                  <div class="arrow mt-3">
                    <i class="fa-solid fa-arrow-right-long"></i>
                  </div>
                  <div *ngIf="showFreightInfo" class="circle" id="transport-circle">
                    <i class="fa-solid fa-truck fa-2x1"></i>
                  </div>
                  <div *ngIf="showFreightInfo" class="arrow mt-3">
                    <i class="fa-solid fa-arrow-right-long"></i>
                  </div>
                  <div class="circle" id="summary-circle">
                    <i class="fa-solid fa-eye fa-2x1"></i>
                  </div>
                  <div class="arrow mt-3">
                    <i class="fa-solid fa-arrow-right-long"></i>
                  </div>
                  <div class="circle">
                    <i class="fa-solid fa-circle-check fa-2x1"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="sellentt-row" *ngIf="showCart">
        <div class="sellentt-col-xl-12">
          <div class="product-wrapper card h-40 mt-3">
            <div class="sellentt-row p-5">
              <div class="sellentt-col-xl-12">
                <div class="row align-items-center">
                  <div class="col-12 col-md-6">
                    <div class="d-flex">
                      <i class="fa-solid fa-shopping-cart secondary-color h3 mr-1"></i>
                      <h2>Carrinho</h2>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 text-md-right">
                    <button type="button" class="btn btn-outline-danger remove-all-button"
                      (click)="removeAllProducts()">
                      <i class="fa-solid fa-trash-can"></i> REMOVER TODOS OS PRODUTOS
                    </button>
                  </div>
                </div>
                <hr>
                <ng-container *ngFor="let product of cartProducts">
                  <div class="row align-items-center">
                    <div class="col-4 col-lg-1">
                      <a [routerLink]="['/products/' + product.id]">
                        <img src="{{ product.image }}" class="product-image">
                      </a>
                    </div>
                    <div class="col-8 col-lg-3">
                      <div class="form-group">
                        <small class="category-name">{{ product.cat.name }}</small> <br>
                        <a [routerLink]="['/products/' + product.id]" class="link">
                          <b>{{ product.name }}</b>
                        </a>
                      </div>
                    </div>
                    <div class="col-12 col-lg-2">
                      <div class="d-flex flex-column justify-content-center align-items-center">
                        <div class="mb-2 text-center">
                          <label for="product_{{product.id}}_qtd">Quantidade</label>

                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <button type="button" (click)="plusMinusCart(product, 'minus')" class="btn btn-primary">
                                <i class="fa-solid fa-square-minus"></i>
                              </button>
                            </div>

                            <input type="number" class="form-control text-center font-weight-bold input-number"
                              value="{{product.quantity}}" (change)="plusMinusCart(product, 'custom')"
                              id="product_{{product.id}}_qtd" />

                            <div class="input-group-append">
                              <button type="button" (click)="plusMinusCart(product, 'plus')" class="btn btn-primary">
                                <i class="fa-solid fa-square-plus"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div>
                          <button id="remove-button" type="button" (click)="removeProduct(product)">
                            <i class="fa-solid fa-trash-can"></i>
                            <span>Remover</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-6 mt-4 m-lg-0">
                      <table class="table table-responsive-xl table-bordered">
                        <thead>
                          <tr>
                            <th scope="col">Preço tabela</th>
                            <th scope="col" *ngIf="!removeTextIpiStPauta">IPI</th>
                            <th scope="col" *ngIf="!removeTextIpiStPauta">ST</th>
                            <th scope="col">Preço final</th>
                            <th scope="col">Quantidade</th>
                            <th scope="col">Subtotal final</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              {{ product.product_price_table | currency : 'BRL' }}
                            </td>
                            <td *ngIf="!removeTextIpiStPauta">
                              {{ product.ipi | currency : 'BRL' }}
                            </td>
                            <td>
                              {{ product.st | currency : 'BRL' }}
                            </td>
                            <td *ngIf="!removeTextIpiStPauta">
                              {{ product.price_with_discounts | currency : 'BRL' }}
                            </td>
                            <td>
                              {{ product.quantity }}
                            </td>
                            <td>
                              {{ product.price_with_discounts * product.quantity | currency : 'BRL' }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <hr class="my-4">
                </ng-container>


                <!-- <div class="col-12 col-lg-6 mt-4 m-lg-0 mt-3">
                </div>
                <div class="col-12 col-lg-6 mt-4 m-lg-0 mt-3">
                  <table class="table table-responsive-xl table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">Peso total dos produtos (kg)</th>
                        <th scope="col">Volume total dos produtos (m³)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {{ totalWeigthProducts }}
                        </td>
                        <td>
                          {{ totalCubageProducts }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div> -->



              </div>
            </div>
          </div>

          <div class="product-wrapper card mt-4 float-md-right">
            <div class="sellentt-row px-3 py-4">
              <div class="sellentt-col-xl-12">
                <div class="sellentt-row">
                  <div class="sellentt-col-x1-6 px-4">
                    <div *ngIf="showBudget">
                      <div class="form-group">
                        <i class="fa fa-gift secondary-color" aria-hidden="true"></i>
                        <label for="discountCode" class="control-label req">Tipo de
                          Solicitação</label>
                      </div>
                      <hr>
                      <div class="sellentt-row" style="font-size: 20px;">
                        <div class="sellentt-col-xs-18 sellentt-col-md-12">
                          <select class="form-control size-select" name="type_order" id="type_order"
                            (change)="type_order_selected()">
                            <!-- <option value="">- Selecionar -</option> -->
                            <option value="order"> Pedido </option>
                            <option value="budget"> Orçamento </option>
                          </select>
                        </div>
                      </div>
                      <hr>
                      <br>
                    </div>
                    <div class="form-group">
                      <i class="fa fa-gift secondary-color" aria-hidden="true"></i>
                      <label for="discountCode" class="control-label req" style="margin: 3px;">Insira o Cupom de
                        desconto</label>
                      <div class="input-group">
                        <input class="form-control" id="discountCode" maxlength="200" name="discountCode" type="text"
                          [(ngModel)]="discountCode" (keyup.enter)="applyCoupon()">
                        <h5>
                          <button class="btn btn-primary" type="button" id="applyCoupon"
                            style="margin-top: -5px; margin-left: 5px; height: 31px;" (click)="applyCoupon()">aplicar
                            cupom</button>
                        </h5>
                      </div>
                      <h6 style="color: red;" *ngIf="MensageCoupon">
                        <i class="fa fa-info-circle" aria-hidden="true"></i> Cupom inserido inválido
                      </h6>
                    </div>
                    <hr>
                    <br>
                    <div class="sellentt-row" *ngIf="is_suframa">
                      <div class="col-lg-12 d-flex align-items-center justify-content-between">
                        <div class="d-flex align-items-center">
                          <!-- <i class="fa fa-tag iconSellentt"></i>  -->Cliente Suframa
                          <span class="tooltip-container ms-2">
                            <i class="fa fa-question-circle iconSellentt"></i>
                            <span class="tooltip-text">
                              Clientes SUFRAMA têm descontos de ICMS e IPI conforme legislação vigente.
                            </span>
                          </span>
                        </div>
                        <button class="btn btn-primary" type="button" id="simulatedSuframa" (click)="simulatedSuframa()">Simular desconto</button>
                      </div>
                    </div>
                    <hr>
                    <br>
                    <div class="sellentt-row pb-5" style="font-size: 18px;">
                      <div class="sellentt-col-xs-18 sellentt-col-md-12 font-weight-bold">
                        <i class="fa-solid fa-file-circle-question secondary-color"></i> RESUMO
                      </div>
                    </div>
                    <div class="sellentt-row">
                      <div class="sellentt-col-xs-8 sellentt-col-md-6">
                        Valor dos Produtos:
                      </div>
                      <div class="sellentt-col-xs-8 sellentt-col-md-6 font-weight-bold text-right pb-3">
                        {{ cartTotalProducts | currency : 'BRL' }}
                      </div>
                    </div>
                    <hr>
                    <div class="sellentt-row">
                      <div class="sellentt-col-xs-8 sellentt-col-md-6">
                        Descontos:
                      </div>
                      <div class="sellentt-col-xs-8 sellentt-col-md-6 font-weight-bold text-right pb-5">
                        {{ - cartTotalDiscount | currency : 'BRL' }}
                      </div>
                    </div>
                    <div class="sellentt-row" *ngIf="apliCoupon">
                      <div class="sellentt-col-xs-8 sellentt-col-md-6">
                        Cupom:
                      </div>
                      <div class="sellentt-col-xs-8 sellentt-col-md-6 font-weight-bold text-right pb-5">
                        {{ - dicountValue || - fixedValue | currency : 'BRL' }}
                      </div>
                    </div>
                    <div class="sellentt-row" *ngIf="user['client']['payment_methods_show_prices']">
                      <div class="sellentt-col-xs-16 sellentt-col-md-12 text-center green-card">
                        Valor total do <b>pedido:</b> <br>
                        <span class="font-weight-bold text-center h1">
                          {{ cartTotalWithDiscounts | currency : 'BRL' }} <br>
                        </span>

                        (Economize <b>{{ cartTotalDiscountPercentage.toFixed(0) }}%</b>)
                      </div>
                    </div>
                    <div class="sellentt-row pt-5" style="font-size: 20px;">
                      <div class="sellentt-col-xs-18 sellentt-col-md-12">
                        <button type="button" class="btn btn-warning w-100 font-weight-bold" (click)="payment()">
                          <i class="fa-solid fa-wallet"></i> IR PARA O {{user['client']['payment_methods_active'] ?
                          'PAGAMENTO' : 'RESUMO'}}
                        </button>
                      </div>
                    </div>
                    <div class="sellentt-row mt-3" style="font-size: 20px;">
                      <div class="sellentt-col-xs-18 sellentt-col-md-12">
                        <button type="button" class="btn btn-outline-warning w-100 font-weight-bold"
                          (click)="keepShopping()">
                          <i class="fa-solid fa-store"></i> CONTINUAR COMPRANDO
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="sellentt-row" *ngIf="showPayment">
        <div class="sellentt-col-xl-12">
          <div class="product-wrapper card h-40 mb-5" *ngIf="cartProducts.length > 0;">
            <div class="sellentt-row p-5">
              <div class="sellentt-col-xl-12">
                <div class="sellentt-row">
                  <div class="sellentt-col-x1-6" align="left">
                    <h2 class="card-title">
                      <i class="fa-solid fa-cash-register secondary-color"></i> FORMA DE PAGAMENTO
                    </h2>
                  </div>
                </div>
                <hr>
                <div class="sellentt-row">
                  <div class="sellentt-col-x1-6 h5">
                    <div class="sellentt-row pb-5">
                      <div class="sellentt-col-12 sellentt-col-md-6 sellentt-col-xl-4" align="left"
                        *ngFor="let method of paymentMethods">
                        <input type="radio" class="radio-button-input" name="methods" id="method_{{ method.id }}"
                          (change)="changePaymentMethod(method)">
                        <label class="btn btn-outline-warning btn-lg btn-block radio-button-label"
                          for="method_{{ method.id }}">
                          <i class="fa-solid fa-money-bill"></i> {{ method.name }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="sellentt-row" *ngIf="showPaymentTerms">
                  <div class="sellentt-col-x1-6" align="left">
                    <h2 class="card-title">
                      <i class="fa-solid fa-calendar-day secondary-color"></i> PRAZO DE PAGAMENTO
                    </h2>
                  </div>
                </div>
                <hr *ngIf="showPaymentTerms">
                <div class="sellentt-row" *ngIf="showPaymentTerms">
                  <div class="sellentt-col-x1-6 h5">
                    <div class="sellentt-row pb-5">
                      <div class="sellentt-col-12 sellentt-col-md-6 sellentt-col-xl-4" align="left"
                        *ngFor="let term of paymentTermsList;">
                        <input type="radio" class="radio-button-input" name="terms" id="term_{{ term.id }}"
                          (change)="changePaymentTerm(term)">
                        <label class="btn btn-outline-warning btn-lg btn-block radio-button-label"
                          for="term_{{ term.id }}" id="term_{{ term.id }}_label">
                          <i class="fa-solid fa-calendar-days"></i> {{ term.description }} (em {{ term.installments || 1
                          }}x)
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <hr *ngIf="showPaymentMessage">
                <div class="sellentt-row" *ngIf="showPaymentMessage">
                  <div class="sellentt-col-x1-12" align="left">
                    <div class="alert alert-warning">
                      <i class="fa-solid fa-info-circle"></i>&nbsp;
                      Após finalizar o pedido você será direcionado à tela de pagamento!
                    </div>
                  </div>
                </div>
                <hr *ngIf="showPaymentMessage">

                <div class="sellentt-row" *ngIf="showEfipayForm">
                  <div class="sellentt-col-x1-6" align="left">
                    <h2 class="card-title">
                      <i class="fa-solid fa-cash-register secondary-color"></i> SELECIONE A OPÇÃO DE PAGAMENTO
                    </h2>
                  </div>
                </div>
                <hr *ngIf="showEfipayForm">

                <div class="sellentt-row" *ngIf="showEfipayForm">
                  <div class="sellentt-col-x1-6 h5">
                    <div class="sellentt-row pb-5">
                      <div class="sellentt-col-12 sellentt-col-md-6 sellentt-col-xl-4" align="left">
                        <input type="radio" class="radio-button-input" name="efipayPaymentOption" id="creditCard"
                          autocomplete="off" (change)="changeEfipayPaymentMethod('credit-card')">
                        <label class="btn btn-outline-warning btn-lg btn-block radio-button-label" for="creditCard">
                          <i class="fas fa-credit-card me-2"></i> Cartão de Crédito
                        </label>
                      </div>
                      <div class="sellentt-col-12 sellentt-col-md-6 sellentt-col-xl-4" align="left">
                        <input type="radio" class="radio-button-input" name="efipayPaymentOption" id="pix"
                          autocomplete="off" (change)="changeEfipayPaymentMethod('pix')">
                        <label class="btn btn-outline-warning btn-lg btn-block radio-button-label" for="pix">
                          <i class="fas fa-qrcode me-2"></i> PIX
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="sellentt-row" *ngIf="showEfipayForm && showEfipayCreditCard">
                  <div class="sellentt-col-x1-6" align="left">
                    <h2 class="card-title">
                      <i class="fa-solid fa-cash-register secondary-color"></i> DADOS DO CARTÃO
                    </h2>
                  </div>
                </div>
                <hr *ngIf="showEfipayForm && showEfipayCreditCard">

                <div class="sellentt-row" *ngIf="showEfipayForm && showEfipayCreditCard">
                  <div id="creditCardForm">
                    <form class="row g-3">
                      <div class="col-8 mt-3">
                        <label for="card-number" class="form-label"><i class="fas fa-credit-card"></i> Número do
                          Cartão</label>
                        <input type="text" class="form-control" id="card-number" placeholder="0000 0000 0000 0000"
                          (keyup)="fillEfipayInstallments()">
                      </div>
                      <div class="col-4 mt-3">
                        <label for="card-installments" class="form-label">
                          <i class="fas fa-coins"></i> Parcelamento
                        </label>
                        <select class="form-control" id="card-installments" disabled>
                          <option value="">- Selecionar -</option>
                          <option value={{ i.number }} *ngFor="let i of efipayInstallments">
                            {{ i.description }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-6 mt-3">
                        <label for="card-name" class="form-label"><i class="fas fa-user"></i> Nome no Cartão</label>
                        <input type="text" class="form-control" id="card-name" placeholder="Nome Completo">
                      </div>
                      <div class="col-md-3 mt-3">
                        <label for="card-expiry" class="form-label"><i class="fas fa-calendar-alt"></i> Validade</label>
                        <input type="text" class="form-control" id="card-expiry" placeholder="MM/AA">
                      </div>
                      <div class="col-md-3 mt-3">
                        <label for="card-cvv" class="form-label"><i class="fas fa-lock"></i> CVV</label>
                        <input type="text" class="form-control" id="card-cvv" placeholder="123">
                      </div>
                    </form>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="sellentt-row">
            <div class="sellentt-col-xl-12">
              <div class="sellentt-row">
                <div class="sellentt-col-x1-12">
                  <div class="sellentt-row g-3 pb-5" align="right">
                    <div class="sellentt-col-xs-9 sellentt-col-md-6 sellentt-col-xl-3">
                      <button type="button" class="btn btn-outline-warning btn-lg btn-block font-weight-bold p-3"
                        (click)="cartPage()">
                        <i class="fa-solid fa-circle-left"></i> VOLTAR
                      </button>
                    </div>
                    <div *ngIf="showFreightInfo" class="sellentt-col-xs-9 sellentt-col-md-6 sellentt-col-xl-3">
                      <button type="button" class="btn btn-warning btn-lg btn-block font-weight-bold p-3"
                        (click)="getShippingCompanys()">
                        <i class="fa-solid fa-file-export"></i> DADOS DO FRETE
                      </button>
                    </div>


                    <div *ngIf="!showFreightInfo" class="sellentt-col-xs-9 sellentt-col-md-6 sellentt-col-xl-3">
                      <button type="button" class="btn btn-warning btn-lg btn-block font-weight-bold p-3"
                        (click)="summary()">
                        <i class="fa-solid fa-file-export"></i> RESUMO
                      </button>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sellentt-row" *ngIf="showShippingCompany">
        <div class="sellentt-col-xl-12">
          <div class="product-wrapper card mt-3">
            <div class="sellentt-row p-5">
              <div class="sellentt-col-xl-12">
                <div class="row align-items-center">
                  <div class="col-12 col-md-6">
                    <div class="d-flex">
                      <i class="fa-solid fa-truck secondary-color h3 mr-1"></i>
                      <h2>Frete</h2>
                    </div>
                  </div>
                </div>
                <hr>
                <div class="sellentt-row">
                  <div class="sellentt-col-x1-6">
                    <div class="sellentt-col-xs-12 sellentt-col-md-12">
                      <div class="sellentt-row">
                        <div class="sellentt-col-xs-24 sellentt-col-md-3">
                          <h3 class="font-weight-bold">
                            <i class="fa-solid fa-calendar secondary-color"></i> Selecione o tipo de frete:
                          </h3>
                          <select class="form-control size-select" name="freight" id="freight"
                            (change)="setFreightSelected()">
                            <option value="">- Selecionar -</option>

                            <ng-container *ngFor="let f of objTypeFreight">
                              <option value="{{f.id}}">{{f.name}}</option>
                            </ng-container>

                            <!-- <option value="CIF_SHIPPING" *ngIf="showCIF_SHIPPING && cif_with_shipping"> CIF
                              ({{cif_shipping_description}})</option> -->


                            <!-- <option value="FOB" *ngIf="showFOB"> FOB (Destinatário)</option>
                            <option value="CIF" *ngIf="showCIF"> CIF (Emitente)</option>
                            <option value="CIF_SHIPPING" *ngIf="showCIF_SHIPPING && cif_with_shipping"> CIF
                              ({{cif_shipping_description}})</option>

                            <ng-container *ngFor="let f of listOfCustomFreights">
                              <option *ngIf="showCustomFreight" value="{{f.id}}">{{f.data.description}}</option>
                            </ng-container> -->
                          </select>
                        </div>
                        <div class="col-md-4" *ngIf="showSelectFreight && !mostraTransportadora">
                          <div class="form-group">
                            <h3 class="font-weight-bold">
                              <i class="fa-solid fa-truck-moving secondary-color"></i> Selecione o frete
                            </h3>
                            <select class="form-control size-select" name="cif_integration_type"
                              id="cif_integration_type" (change)="getCifIntegrationValue()">
                              <option value="">- Selecionar -</option>
                              <option *ngFor="let f of freightSelect" value="{{f.id}}">{{f.description}} -
                                {{f.priceFormatted}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="sellentt-col-xs-12 sellentt-col-md-6" *ngIf="mostraTransportadora">
                          <div class="sellentt-row">
                            <div class="sellentt-col-xs-24 sellentt-col-md-6">
                              <h3 class="font-weight-bold">
                                <i class="fa-solid fa-calendar secondary-color"></i>
                                Dados da transportadora
                              </h3>
                              <select class="form-control size-select" name="shipping-company" id="shipping-company">
                                <option value="">- Selecionar -</option>
                                <option *ngFor="let shipping of listShippingCompany" value="{{shipping.id}}">
                                  {{shipping.data.legal_name !== '' ?
                                  shipping.data.legal_name : shipping.data.name}}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr>
          <div class="product-wrapper card mt-4 float-right">
            <div class="sellentt-row px-3 py-4">
              <div class="sellentt-col-xl-12">
                <div class="sellentt-row">
                  <div class="sellentt-col-x1-6 px-4">
                    <div class="sellentt-row pb-5" style="font-size: 18px;">
                      <div class="sellentt-col-xs-18 sellentt-col-md-12 font-weight-bold">
                        <i class="fa-solid fa-file-circle-question secondary-color"></i> RESUMO
                      </div>
                    </div>
                    <div class="sellentt-row">
                      <div class="sellentt-col-xs-8 sellentt-col-md-6">
                        Valor dos Produtos:
                      </div>
                      <div class="sellentt-col-xs-8 sellentt-col-md-6 font-weight-bold text-right pb-3">
                        {{ cartTotalProducts | currency : 'BRL' }}
                      </div>
                    </div>
                    <hr>
                    <div class="sellentt-row">
                      <div class="sellentt-col-xs-8 sellentt-col-md-6">
                        Descontos:
                      </div>
                      <div class="sellentt-col-xs-8 sellentt-col-md-6 font-weight-bold text-right pb-5">
                        {{ - cartTotalDiscount | currency : 'BRL' }}
                      </div>
                    </div>
                    <hr>
                    <div class="sellentt-row">
                      <div class="sellentt-col-xs-8 sellentt-col-md-6">
                        Frete:
                      </div>
                      <div class="sellentt-col-xs-8 sellentt-col-md-6 font-weight-bold text-right pb-5">
                        {{ valueFreight | currency : 'BRL' }}
                      </div>
                    </div>
                    <div class="sellentt-row" *ngIf="user['client']['payment_methods_show_prices']">
                      <div class="sellentt-col-xs-16 sellentt-col-md-12 text-center green-card">
                        Valor total do <b>pedido:</b> <br>
                        <span class="font-weight-bold text-center h1">
                          {{ valueTotalCartWithDiscounts | currency : 'BRL' }} <br>
                        </span>
                        (Economize <b>{{ cartTotalDiscountPercentage | number : '1.2-2' }}%</b>)
                      </div>
                    </div>
                    <div class="sellentt-row pt-5" style="font-size: 20px;">
                      <div class="sellentt-col-xs-18 sellentt-col-md-12">
                        <button type="button" class="btn btn-warning w-100 font-weight-bold" (click)="summary()"
                          id="btn-place-order">
                          <i class="fa-solid fa-file-import"></i> RESUMO
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="sellentt-row" *ngIf="showSummary">
        <div class="sellentt-col-xl-12">
          <div class="product-wrapper card mt-3">
            <div class="sellentt-row p-5">
              <div class="sellentt-col-xl-12">
                <div class="row align-items-center">
                  <div class="col-12 col-md-6">
                    <div class="d-flex">
                      <i class="fa-solid fa-file-invoice secondary-color h3 mr-1"></i>
                      <h2>Informações do pedido</h2>
                    </div>
                  </div>
                </div>
                <hr>
                <div class="sellentt-row">
                  <div class="sellentt-col-x1-6">
                    <div class="sellentt-row">
                      <div class="sellentt-col-xs-12 sellentt-col-md-12">
                        <div class="sellentt-row">
                          <div class="sellentt-col-xs-24 sellentt-col-md-3">
                            <h3 class="font-weight-bold">
                              <i class="fa-solid fa-address-card secondary-color"></i>
                              Dados Pessoais
                            </h3>
                            <span class="pl-4 pt-5 lh-base"><strong>Cliente: </strong>{{ user.name }}</span> <br>
                            <span class="pl-4 pt-4 lh-lg"><strong>CPF / CNPJ: </strong>{{ user.store.doc_number
                              }}</span> <br>
                            <span class="pl-4 pt-4 lh-lg"><strong>RG: </strong>{{ user.store.reg_number }}</span> <br>
                            <span class="pl-4 pt-4 lh-lg"><strong>Telefone: </strong>{{ user.store.phone_number ||
                              '-'}}</span> <br>
                            <span class="pl-4 pt-4 lh-lg"><strong>Celular: </strong>{{ user.store.mobile_number || '-'
                              }}</span> <br>
                            <span class="pl-4 pt-4 lh-lg"><strong>E-mail: </strong>{{ user.store.email_1 }}</span>
                          </div>
                          <div class="sellentt-col-xs-24 sellentt-col-md-3">
                            <h3 class="font-weight-bold">
                              <i class="fa-solid fa-address-book secondary-color"></i> Endereço de Entrega
                            </h3>

                            <span class="pl-4 pt-5 lh-lg"><strong>Rua: </strong>{{ user.store.address_street }}</span>
                            <br>
                            <span class="pl-4 pt-4 lh-lg"><strong>Bairro: </strong>{{ user.store.address_district
                              }}</span> <br>
                            <span class="pl-4 pt-4 lh-lg"><strong>CEP: </strong>{{ user.store.address_zipcode }}</span>
                            <br>
                            <span class="pl-4 pt-4 lh-lg"><strong>Cidade: </strong>{{ user.store.address_city }}</span>
                            <br>
                            <span class="pl-4 pt-4 lh-lg"><strong>Complemento: </strong>{{ user.store.address_more
                              }}</span>
                          </div>
                          <div class="sellentt-col-xs-24 sellentt-col-md-2" *ngIf="prevision_date">
                            <h3 class="font-weight-bold">
                              <i class="fa-solid fa-calendar secondary-color"></i>
                              Previsão de entrega
                            </h3>
                            <span class="pl-4 pt-5 lh-lg">{{ previsionDate }}</span> <br>
                          </div>
                          <div class="sellentt-col-xs-24 sellentt-col-md-2">
                            <h3 class="font-weight-bold">
                              <i class="fa-regular fa-folder-open secondary-color"></i>
                              Tipo do Pedido
                            </h3>
                            <span class="pl-4 pt-5 lh-lg">{{ orderType.data.name }}</span> <br>
                          </div>
                          <div *ngIf="showFreightInfo" class="sellentt-col-xs-24 sellentt-col-md-2">
                            <h3 class="font-weight-bold">
                              <i class="fa-solid fa-solid fa-truck secondary-color"></i>
                              Frete
                            </h3>
                            <span class="pl-4 pt-5 lh-lg"><strong>Tipo: </strong>{{ freteFormated
                              }}</span> <br>
                            <span *ngIf="showShippings" class="pl-4 pt-4 lh-lg"><strong>Transportadora:
                              </strong>{{ shippingDescription && shippingDescription.data ?
                              shippingDescription.data.legal_name : ""}}</span> <br>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="product-wrapper card mt-3">
            <div class="sellentt-row p-5">
              <div class="sellentt-col-xl-12">
                <div class="row align-items-center">
                  <div class="col-12 col-md-6">
                    <div class="d-flex">
                      <i class="fa-solid fa-basket-shopping secondary-color h3 mr-1"></i>
                      <h2>Lista de produtos</h2>
                    </div>
                  </div>
                </div>
                <hr>
                <ng-container *ngFor="let product of cartProducts">
                  <div class="row align-items-center">
                    <div class="col-4 col-lg-1">
                      <a [routerLink]="['/products/' + product.id]">
                        <img src="{{ product.image }}" class="product-image">
                      </a>
                    </div>
                    <div class="col-8 col-lg-4">
                      <div class="form-group">
                        <small class="category-name">{{ product.cat.name }}</small> <br>
                        <a [routerLink]="['/products/' + product.id]" class="link">
                          <b>{{ product.name }}</b>
                        </a>
                      </div>
                    </div>
                    <div class="col-12 col-lg-7 mt-4 m-lg-0">
                      <table class="table table-responsive-xl table-bordered">
                        <thead>
                          <tr>
                            <th scope="col">Preço tabela</th>
                            <th scope="col" *ngIf="!removeTextIpiStPauta">IPI</th>
                            <th scope="col" *ngIf="!removeTextIpiStPauta">ST</th>
                            <th scope="col">Preço final</th>
                            <th scope="col">Quantidade</th>
                            <th scope="col">Subtotal final</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              {{ product.product_price_table | currency : 'BRL' }}
                            </td>
                            <td *ngIf="!removeTextIpiStPauta">
                              {{ product.ipi | currency : 'BRL' }}
                            </td>
                            <td *ngIf="!removeTextIpiStPauta">
                              {{ product.st | currency : 'BRL' }}
                            </td>
                            <td>
                              {{ product.price_with_discounts | currency : 'BRL' }}
                            </td>
                            <td>
                              {{ product.quantity }}
                            </td>
                            <td>
                              {{ product.price_with_discounts * product.quantity | currency : 'BRL' }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <hr class="my-4">
                </ng-container>
              </div>
            </div>
          </div>

          <div class="product-wrapper card mt-3">
            <div class="sellentt-row p-5">
              <div class="sellentt-col-xl-12">
                <div class="row align-items-center">
                  <div class="col-12 col-md-12">
                    <div class="d-flex">
                      <i class="fa-regular fa-comments secondary-color h3 mr-1"></i>
                      <h2>Observações:</h2>
                    </div>
                    <hr>
                    <div class="col-12 col-md-12">
                      <textarea class="form-control" name="text_observation" id="text_observation" rows="6"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="product-wrapper card mt-4">
            <div class="sellentt-row px-3 py-4">
              <div class="sellentt-col-xl-12">
                <div class="sellentt-row">
                  <div class="sellentt-col-x1-6 px-4">
                    <div class="sellentt-row pb-5" style="font-size: 18px;">
                      <div class="sellentt-col-xs-18 sellentt-col-md-12 font-weight-bold">


                        <div class="row">
                          <div class="col-md-4">
                            <div class="form-group">
                              <i class="fa fa-gift" aria-hidden="true"></i>
                              <label for="discountCode" class="control-label req" style="margin: 3px;">Insira o Cupom de desconto</label>
                              <div class="input-group">
                                <input class="form-control" id="discountCode" maxlength="200" name="discountCode" type="text" [(ngModel)]="discountCode">
                                <h5>
                                  <button class="btn btn-primary" type="button" id="applyCoupon" style="margin-top: -5px; margin-left: 5px; height: 31px;" (click)="applyCoupon()">aplicar cupom</button>
                                </h5>
                              </div>
                              <h6 style="color: red;" *ngIf="MensageCoupon">
                                <i class="fa fa-info-circle" aria-hidden="true"></i> Cupom inserido ivalido
                              </h6>
                            </div>
                          </div>
                        </div>


                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->


          <div class="product-wrapper card mt-4 float-right">
            <div class="sellentt-row px-3 py-4">
              <div class="sellentt-col-xl-12">
                <div class="sellentt-row">
                  <div class="sellentt-col-x1-6 px-4">
                    <div class="sellentt-row pb-5" style="font-size: 18px;">
                      <div class="sellentt-col-xs-18 sellentt-col-md-12 font-weight-bold">
                        <i class="fa-solid fa-file-circle-question secondary-color"></i> RESUMO
                      </div>
                    </div>
                    <div class="sellentt-row">
                      <div class="sellentt-col-xs-8 sellentt-col-md-6">
                        Valor dos Produtos:
                      </div>
                      <div class="sellentt-col-xs-8 sellentt-col-md-6 font-weight-bold text-right pb-3">
                        {{ cartTotalProducts | currency : 'BRL' }}
                      </div>
                    </div>
                    <hr>
                    <div class="sellentt-row">
                      <div class="sellentt-col-xs-8 sellentt-col-md-6">
                        Descontos:
                      </div>
                      <div class="sellentt-col-xs-8 sellentt-col-md-6 font-weight-bold text-right pb-5">
                        {{ - cartTotalDiscount | currency : 'BRL' }}
                      </div>
                    </div>

                    <div class="sellentt-row" *ngIf="apliCoupon">
                      <div class="sellentt-col-xs-8 sellentt-col-md-6">
                        Cupom:
                      </div>
                      <div class="sellentt-col-xs-8 sellentt-col-md-6 font-weight-bold text-right pb-5">
                        {{ - dicountValue || - fixedValue | currency : 'BRL' }}
                      </div>
                    </div>

                    <div *ngIf="showFreightInfo" class="sellentt-row">
                      <div class="sellentt-col-xs-8 sellentt-col-md-6">
                        Frete:
                      </div>
                      <div class="sellentt-col-xs-8 sellentt-col-md-6 font-weight-bold text-right pb-5">
                        {{ valueFreight | currency : 'BRL' }}
                      </div>
                    </div>

                    <div class="sellentt-row" *ngIf="user['client']['payment_methods_show_prices']">
                      <div class="sellentt-col-xs-16 sellentt-col-md-12 text-center green-card">
                        Valor total do <b>pedido:</b> <br>
                        <span class="font-weight-bold text-center h1" *ngIf="showFreightInfo">
                          {{ valueTotalCartWithDiscounts | currency : 'BRL' }} <br>
                        </span>

                        <span class="font-weight-bold text-center h1" *ngIf="!showFreightInfo">
                          {{ cartTotalWithDiscounts | currency : 'BRL' }} <br>
                        </span>

                        (Economize <b>{{ cartTotalDiscountPercentage.toFixed(0) }}%</b>)
                      </div>
                    </div>
                    <div class="sellentt-row pt-5" style="font-size: 20px;">
                      <div class="sellentt-col-xs-18 sellentt-col-md-12">
                        <button type="button" class="btn btn-warning w-100 font-weight-bold" (click)="placeOrder()"
                          id="btn-place-order">
                          <i class="fa-solid fa-file-import"></i> {{ finishButtonText }}
                        </button>
                      </div>
                    </div>
                    <div class="sellentt-row mt-3" style="font-size: 20px;">
                      <div class="sellentt-col-xs-18 sellentt-col-md-12">
                        <button type="button" class="btn btn-outline-warning w-100 font-weight-bold"
                          (click)="payment('back')">
                          <i class="fa-solid fa-circle-left"></i> VOLTAR
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal inmodal" id="modal-infos-suframa" tabindex="-1" role="dialog" style="display: none;" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content animated fadeIn shadow-lg">
      <div class="modal-header bg-primary text-white">
        <button type="button" class="close text-white" data-dismiss="modal">
          <span aria-hidden="true">×</span>
          <span class="sr-only">Fechar</span>
        </button>
        <h2 class="m-0">
          <i class="fa-solid fa-percent"></i> Desconto Suframa
        </h2>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 text-center">
            <h3 class="text-success font-weight-bold">
              O seu desconto Suframa é de:
            </h3>
            <p class="display-4 text-primary font-weight-bold">{{resultSuframa}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

