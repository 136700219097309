<!-- ======================= single product start  ========================== -->
<div class="product-details-sec mb-5" *ngIf="loadedData">
  <div class="sellentt-container">
    <div class="sellentt-row gy-3 gy-lg-0">
      <div class="product-name">
        <h2>{{ product.name }} - {{ product.code }}</h2>
      </div>
      <div class="col-lg-6">
        <div class="singel-pro-info">
          <div class="d-flex mb-4 justify-content-between align-items-center position-relative">
            <div class="review_star">
              <i class="fa-solid fa-star icon"></i>
              <i class="fa-solid fa-star icon"></i>
              <i class="fa-solid fa-star icon"></i>
              <i class="fa-solid fa-star icon"></i>
              <i class="fa-solid fa-star icon"></i>
              <span></span>
            </div>
            <div class="divider"></div>
            <!-- share & favorite -->
            <div class="single-pro-share">
              <div class="d-flex justify-content-between align-items-center">
                <!-- share -->
                <a (click)="shareProduct(product)">
                  <i class="fa-solid fa-share-nodes fa-2x"></i>
                </a>
                <!-- wishlist -->
                <div class="product-wishlist" *ngIf="isLoggedIn">
                  <div class="form-check">
                    <input class="form-check-input" (change)="openWishlist(product)" type="checkbox" name="wishlist">
                    <label class="form-check-label" (click)="openWishlist(product)"></label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- image gallery -->
          <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner" role="listbox" style=" width:100%; height: 300px !important;">
              <div class="carousel-item active">
                <div class="d-flex justify-content-center">
                  <img src="{{ product.image }}" class="d-block w-90" alt="...">
              </div>
                
              </div>
              <div class="carousel-item" *ngFor="let image of product.images">
                <div class="d-flex justify-content-center">
                <img src="{{ image }}" class="d-block w-90" alt="...">
              </div>
              </div>
            </div>
           <button class="carousel-control-prev" type="button" data-target="#carouselExampleControls" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-target="#carouselExampleControls" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </button>
          </div>
          <!-- <div class="product-item-gallery single-product mb-4">
            <div class="swiper mySwiper2">
              <div class="swiper-wrapper single-item">
                <div class="swiper-slide">
                  <div class="zoom">
                    <img src="{{ product.image }}" />
                  </div>
                </div>
              </div>
              <div class="swiper-button-next" *ngIf="product.images && product.images.length > 0"></div>
              <div class="swiper-button-prev" *ngIf="product.images && product.images.length > 0"></div>
            </div>

            <div class="swiper mySwiper" *ngIf="product.images && product.images.length > 0">
              <div class="swiper-wrapper">
                <div class="swiper-slide" *ngFor="let image of product.images">
                  <img src="{{ image }}" alt="product-img" />
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <div class="col-lg-6 mt-3" *ngIf="hasStock(product)">
        <div class="product-information">
          <div class="d-flex align-items-center justify-content-between">
            <div class="discount">
              <i class="fa-solid fa-percentage fa-2x text-white"></i>
              <span>&nbsp; Desconto: {{ product.total_discount_percentage }}%</span>
            </div>
            <div *ngIf="isStockActive" class="divider"></div>
            <div *ngIf="isStockActive" class="remain">
              <img src="assets/images/icons/remain.svg" alt="">
              <span *ngIf="showStock; else dontShowQuantityStock">Restam: {{ product.stock || 0 }} un.</span>
              <ng-template #dontShowQuantityStock>
                <span *ngIf="hasStock(product); else noStock" style="font-size: 15px;">
                  Em estoque
                </span>
              </ng-template>
              <ng-template #noStock>
                <span style="font-size: 10px;">
                  Fora de estoque 
                </span>
              </ng-template>
            </div>
          </div>
        </div>
        
        <div class="product-sort-info">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <td style="width: 25%;">Código</td>
                <td style="width: 75%;">{{ product.code || '-' }}</td>
              <tr>
                <td style="width: 25%;">EAN</td>
                <td style="width: 75%;">{{ product.ean || '-' }}</td>
              </tr>
              <tr *ngIf="!removeTextIpiStPauta">
                <td style="width: 25%;">IPI</td>
                <td style="width: 75%;">{{ product.ipi_percentage || '-' }}</td>
              </tr>
              <tr *ngIf="!removeTextIpiStPauta">
                <td style="width: 25%;">ST</td>
                <td style="width: 75%;">{{ product.st_percentage || '-' }}</td>
              </tr>
              <tr>
                <td style="width: 25%;">Descrição</td>
                <td style="width: 75%;">{{ product.text_description || '-' }}</td>
              </tr>
            </tbody>
          </table>
          
          <div class="d-flex justify-content-between align-items-center mt-3 mb-5">
            <div class="price">
              <p *ngIf="product.price_no_discounts != product.price_with_discounts">
                <del class="del" style="color: gray;">{{ product.price_no_discounts | currency: 'BRL' }}</del>
              </p>
              <p>
                {{ product.price_with_discounts | currency : 'BRL' }}
              </p>
            </div>
            <div class="purchase-btn d-none d-lg-block">
              <div class="purchase-input">
                <span>Quantidade</span>
                <input type="number" 
                       class="form-control" 
                       [(ngModel)]="product.quantity" 
                       id="qtd-input" 
                       step="1" 
                       min="1"
                       (input)="validateIntegerInput($event)">
                <!-- Mensagem de erro exibida se a quantidade for inválida -->
                <div *ngIf="quantityError" class="error-message">
                  A quantidade não pode ser zero ou vazia.
                </div>
              </div>
              <div class="purchase-link">
                <a (click)="insertCart(product)">
                  <i class="fas fa-shopping-cart"></i>
                  &nbsp; Comprar
                </a>
              </div>
            </div>
          </div>
        </div>
        <h3 *ngIf="product.variations.length > 0" style="color: #ed8823;">Variações</h3>
        <div *ngFor="let variation of product.variations">
          <strong>{{ variation.name }} {{ variation.mandatory ? '*' : '' }}</strong>
          <div *ngIf="variation.type === 'select' || variation.type === 'radio'">
            <span *ngFor="let option of variation.options;">
              <input type="radio" name="variacao_{{variation.options[0]}}" id="variacao_{{option}}"
                class="radio-image-input" />
              <label for="variacao_{{option}}">
                <img src="{{ product.image }}" alt="" class="radio-image-label">
                <br> {{ option }}
              </label>
            </span>
          </div>
          <div *ngIf="variation.type === 'text'">
            <label for="variacao_{{slug}}"></label>
            <input type="text" name="variacao_{{slug}}" id="variacao_{{slug}}" class="form-control" />
          </div>
        </div>
        <br>

        <div class="" *ngIf="showGoldCotation">
          <p class="pt-2">
            Cotação (OURO/REAIS): 1g / <strong class="gold_to_reais">R$ {{ cotation }}</strong>
          </p>
          <p class="pt-2" *ngIf="showGoldCotationTotal">
            Total do Produto Cotado: <strong class="gold_to_reais_total">R$ {{ cotationTotal }}</strong>
          </p>
        </div>
      </div>

      <div class="semilar-products d-none d-lg-block" *ngIf="product.relations && product.relations.length > 0">
        <h3>
          <i class="fa fa-tag"></i> Produtos Similares
        </h3>
        
        <ul>
          <li *ngFor="let related of product.relations">
            <a [routerLink]="['/produtos/' + related.code ]">
              <span>{{related.name}} ({{related.code}})</span>
            </a>
          </li>
        </ul>

        <!-- <div class="slider-btn">
          <button class="slider-btn--prev slick-arrow">
            <i class="fa-solid fa-arrow-left"></i>
          </button>
          <button class="slider-btn--next slick-arrow sellentt-float-end">
            <i class="fa-solid fa-arrow-right"></i>
          </button>
        </div> -->

        <!-- <div class="related-post-slider mx-2">
          <div class="semilar-item text-center" *ngFor="let related of product.relations">
            <div class="pro-img">
              <a [routerLink]="['/produtos/' + related.code ]">
                <img src="https://app.pedidosdigitais.com.br/img/sem-imagem-mini.png" width="80" alt="">
              </a>
            </div>
            <div class="pro-price h4">
              <a [routerLink]="['/produtos/' + related.code ]">
                <span>{{related.name}} ({{related.code}})</span>
              </a>
            </div>
          </div>
        </div> -->
      </div>
      
      <div class="purchase-btn d-block d-lg-none">
        <div class="purchase-input w-100 mb-2">
          <span>Quantidade</span>
          <input type="number" class="form-control" [(ngModel)]="product.quantity" id="qtd-input" step=".01">
        </div>
        <a class="w-100" (click)="insertCart(product)">
          <i class="fas fa-shopping-cart fa-2x"></i>
          <span>Comprar</span>
        </a>
      </div>

    </div>

    <div class="col-md-6 mt-3" *ngIf="!hasStock(product)">
      <div class="no-stock-container">
        <div class="no-stock-header">
          <i class="fa-solid fa-circle-xmark"></i>
          <h2>Desculpe!</h2>
          <p>O produto que você está procurando está sem estoque. Cadastre o seu e-mail que avisaremos quando chegar!
          </p>
        </div>
        <form #noStockForm="ngForm" (ngSubmit)="handleOutOfStockNotification(noStockForm)"
          *ngIf="!outOfStockNotifyCreated">
          <div class="form-group" [class.has-error]="!nameField.valid && nameField.touched">
            <input type="text" name="userName" class="form-control" placeholder="Digite seu nome" required
              #nameField="ngModel" ngModel>
          </div>

          <div class="form-group" [class.has-error]="(!emailField.valid && emailField.touched)">
            <input type="email" name="userEmail" class="form-control" placeholder="Digite seu e-mail" required
              email="true" #emailField="ngModel" ngModel>
          </div>
          <div class="text-center">
            <button type="submit" class="btn btn-primary no-stock-btn" [disabled]="!noStockForm.valid">Me
              avise!</button>
          </div>

        </form>
        <h2 class="out-of-stock-msg" *ngIf="outOfStockNotifyCreated">E-mail cadastrado.</h2>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!loadedData" class="sk-spinner sk-spinner-three-bounce m-5 w-100 min-vh-100 d-flex justify-content-center align-items-center">
  <div class="sk-bounce1"></div>
  <div class="sk-bounce2"></div>
  <div class="sk-bounce3"></div>
</div>
<!-- ======================= single product end  ============================ -->

<div class="sellentt-container" *ngIf="technical_sheet.length > 0 || additional_info.length > 0">
  <div class="sellentt-row">
    <div class="col-lg-6">
      <ul class="nav nav-pills nav-fill mb-3" id="pills-tab" role="tablist">
        <li [class]="technical_sheet.length > 0 ? 'nav-item' : 'nav-item disabled-li'" role="presentation">
          <a [class]="technical_sheet.length > 0 ? 'nav-link active' : 'nav-link disabled-anchor'"
            id="pills-technical_sheet-tab" data-toggle="pill" href="#pills-technical_sheet" role="tab"
            aria-controls="pills-technical_sheet" aria-selected="true">
            Ficha Técnica
          </a>
        </li>
        <li [class]="additional_info.length > 0 ? 'nav-item' : 'nav-item disabled-li'" role="presentation">
          <a [class]="additional_info.length > 0 ? 'nav-link' : 'nav-link disabled-anchor'"
            id="pills-additional_info-tab" data-toggle="pill" href="#pills-additional_info" role="tab"
            aria-controls="pills-additional_info" aria-selected="false">
            Informações Adicionais
          </a>
        </li>
      </ul>
      <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="pills-technical_sheet" role="tabpanel"
          aria-labelledby="pills-technical_sheet-tab">
          <table class="table">
            <tbody>
              <tr *ngFor="let item of technical_sheet">
                <td>{{ item.split('|')[0] }}</td>
                <td>{{ item.split('|')[1] }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="tab-pane fade" id="pills-additional_info" role="tabpanel"
          aria-labelledby="pills-additional_info-tab">
          <table class="table">
            <tbody>
              <tr *ngFor="let item of additional_info">
                <td>{{ item.split('|')[0] }}</td>
                <td>{{ item.split('|')[1] }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="sellentt-container" *ngIf="loadedData && userReviews.length > 0">
  <div class="row">
    <div class="col-md-12 course-details-content">
      <div class="course-details-card mt-5">
        <div class="course-content">
          <h2 class="mb-5 text-center">Avaliações dos Usuários</h2>
          <div class="row pb-5">
            <div class="col-lg-2"></div>
            <div class="col-lg-2">
              <div class="rating-box">
                <div class="rating-number">{{review.average.toFixed(1)}}</div>
                <div class="rating">
                  <i class="fa fa-star {{review.average >= 1 ? 'checked' : ''}}" aria-hidden="true"></i>
                  <i class="fa fa-star {{review.average >= 2 ? 'checked' : ''}}" aria-hidden="true"></i>
                  <i class="fa fa-star {{review.average >= 3 ? 'checked' : ''}}" aria-hidden="true"></i>
                  <i class="fa fa-star {{review.average >= 4 ? 'checked' : ''}}" aria-hidden="true"></i>
                  <i class="fa fa-star {{review.average >= 5 ? 'checked' : ''}}" aria-hidden="true"></i>
                </div>
                <span>({{review.total}} Avaliações)</span>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="review-wrapper">
                <div class="single-progress-bar">
                  <div class="rating-text"> 5 <i class="fa fa-star checked" aria-hidden="true"></i> </div>
                  <div class="progress">
                    <div class="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0"
                      aria-valuemax="100" [ngStyle]="{'width': ((100 / (review.total)) * review.stars.five) + '%'}">
                    </div>
                  </div>
                  <span class="rating-value">{{review.stars.five}}</span>
                </div>
                <div class="single-progress-bar">
                  <div class="rating-text"> 4 <i class="fa fa-star checked" aria-hidden="true"></i> </div>
                  <div class="progress">
                    <div class="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"
                      [ngStyle]="{'width': ((100 / (review.total)) * review.stars.four) + '%'}">
                    </div>
                  </div>
                  <span class="rating-value">{{review.stars.four}}</span>
                </div>
                <div class="single-progress-bar">
                  <div class="rating-text"> 3 <i class="fa fa-star checked" aria-hidden="true"></i> </div>
                  <div class="progress">
                    <div class="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"
                      [ngStyle]="{'width': ((100 / (review.total)) * review.stars.three) + '%'}">
                    </div>
                  </div>
                  <span class="rating-value">{{review.stars.three}}</span>
                </div>
                <div class="single-progress-bar">
                  <div class="rating-text"> 2 <i class="fa fa-star checked" aria-hidden="true"></i> </div>
                  <div class="progress">
                    <div class="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"
                      [ngStyle]="{'width': ((100 / (review.total)) * review.stars.two) + '%'}">
                    </div>
                  </div>
                  <span class="rating-value">{{review.stars.two}}</span>
                </div>
                <div class="single-progress-bar">
                  <div class="rating-text"> 1 <i class="fa fa-star checked" aria-hidden="true"></i> </div>
                  <div class="progress">
                    <div class="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="80"
                      aria-valuemax="100" [ngStyle]="{'width': ((100 / (review.total)) * review.stars.one) + '%'}">
                    </div>
                  </div>
                  <span class="rating-value">{{review.stars.one}}</span>
                </div>
              </div>
            </div>
            <div class="col-lg-6"></div>
          </div>
          <div class="review-wrapper pt-5">
            <div class="row">
              <div class="col-md-6 p-2" *ngFor="let review of userReviews">
                <div class="reviews">
                  <div class="thumbnail">
                    <img
                      src="https://p.biaction.com.br/108/users/avatares/{{user.avatar.name + user.avatar.version}}.png"
                      alt="Usuário">
                  </div>
                  <div class="review-content">
                    <div class="review-top">
                      <h6 class="title">{{review.user.name}}</h6>
                      <div class="rating">
                        <i class="fa fa-star {{review.stars >= 1 ? 'checked' : ''}}" aria-hidden="true"></i>
                        <i class="fa fa-star {{review.stars >= 2 ? 'checked' : ''}}" aria-hidden="true"></i>
                        <i class="fa fa-star {{review.stars >= 3 ? 'checked' : ''}}" aria-hidden="true"></i>
                        <i class="fa fa-star {{review.stars >= 4 ? 'checked' : ''}}" aria-hidden="true"></i>
                        <i class="fa fa-star {{review.stars >= 5 ? 'checked' : ''}}" aria-hidden="true"></i>
                      </div>
                    </div>
                    <p>
                      {{review.review}}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- clicar em wishlist abre esse modal para as opções-->
<div class="modal inmodal" id="modal-wishlist-{{product.id}}" tabindex="-1" role="dialog" style="display: none;"
  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content animated fadeIn">

      <div class="modal-header">

        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>

        <h4 class="modal-title" style="color: var(--bg-secondary);">
          <i class="fa-solid fa-heart" style="color: var(--bg-secondary);"></i> Lista de Desejos
        </h4>


      </div>

      <div class="modal-body">

        <!-- Formulário para criar uma nova lista de desejos -->
        <div class="form-group">
          <div class="d-flex align-items-center">
            <label class="col-md-2" style="margin-top: 9px;">Nome:</label>
            <input type="text" class="form-control col-md-6" name="newWishlistName" id="newWishlistName"
              [(ngModel)]="newWishlistName" placeholder="Digite o nome da nova lista">
            <button type="button" class="btn btn-primary float-right col-md-4 ml-1" (click)="createNewWishlist()">
              <i class="fas fa-plus"></i> Criar lista
            </button>
          </div>
          <hr>
        </div>

        <!-- <div *ngIf="!showSpinner" class="sk-spinner sk-spinner-three-bounce m-5 w-100 d-flex justify-content-center align-items-center">
          <div class="loader"></div>
        </div> -->

        <!-- Lista de desejos existentes -->
        <ul class="list-group">
          <li class="list-group-item" *ngFor="let wishlist of wishlists">
            <div class="d-flex justify-content-between align-items-center">


              <div class="form-check">
                <input class="form-check-input" (change)="onChangeFavorite(product, wishlist.id)" type="checkbox"
                  name="wishlist" id="wishlist_{{ wishlist.id }}_{{product.id}}">
                <label class="form-check-label" for="wishlist_{{ product.id }}"></label>
              </div>

              <span>{{ wishlist.name }}</span>

              <div class="d-flex">
                <button type="button" class="btn btn-success" data-dismiss="modal" aria-label="Close"
                  [routerLink]="['/favorites-details', wishlist.id]" style="margin-right: 5px;">
                  <i class="fa fa-eye" aria-hidden="true"></i>
                </button>
              </div>

            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
