<div class="sellentt-container" *ngIf="loadedData">
  <div class="sellentt-row">
    <div class="sellentt-col-md-12">
      <h2 class="pb-1 pt-4">
        <b><i class="fa-solid fa-store secondary"></i> Meus Pedidos</b>
        <p class="mt-2" style="font-size: 12px;">
          <i class="fa-solid fa-caret-right"></i> Informações de seus últimos pedidos dentro da plataforma.
        </p>
      </h2>
    </div>

    <div class="row" style="margin-left: 1px;">

      <div class="col-md-3" style="margin-right: 7%;">
        <label for="search"><i class="fa-solid fa-box iconSellentt"></i> Pedido</label>
        <div class="input-group">
          <input type="text" id="search" name="search" placeholder="Código ou cliente"
            class="form-control form-control-lg" [(ngModel)]="search" (keyup.enter)="filter()">
        </div>
      </div>

      <div class="col-md-3">
        <label for="start_date"><i class="fas fa-calendar-day secondary"></i> Data</label>
        <input type="date" id="start_date" name="start_date" [(ngModel)]="date" class="form-control form-control-lg">
      </div>

      <div class="col-md-3">
        <label for="order_type_select">Tipo de Pedido:</label>
            <select name="order_type_select" class="form-control" id="order_type_select" [(ngModel)]="orderType">
              <option value="">- Selecione -</option>
              <option *ngFor="let e of orderTypes" value="{{e.id}}">{{e.name}}</option>
            </select>
      </div>

      <div class="col-md-2">
        <button class="btn btn-primary btn-lg mt-4" type="submit" (click)="filter()">
          <i class="fas fa-search"></i> Buscar
        </button>
      </div>

    </div>

    <div *ngIf="noResults" class="alert alert-warning mt-4">
      <i class="fas fa-exclamation-triangle"></i> Nenhum produto encontrado com os filtros aplicados.
    </div>


    <div class="panel-group sellentt-accordion" id="accordion" style="margin-top: 20px;"
      *ngIf="paginatedData.length > 0">
      <div class="panel panel-default" *ngFor="let order of paginatedData">
        <div class="panel-heading">
          <h4 class="panel-title">
            <div class="sellentt-row info-container">
              <div class="sellentt-col-md-1 info-col sellentt-col-sm-10 sellentt-col-xs-10">
                <div class="info-title">
                  <i class="fa-solid fa-file-invoice"></i>&nbsp; Pedido <span class="hidden-sm hidden-md hidden-lg">/
                    Cliente</span>
                </div>
                <div class="info-content">#{{ order.code || '-'}} <span class="hidden-sm hidden-md hidden-lg">/ {{
                    order.store.name }}</span></div>
              </div>
              <div class="sellentt-col-md-1 hidden-xs info-col">
                <div class="info-title">Criado em</div>
                <div class="info-content">{{ order.created_at | date : 'dd/MM/yyyy'}}</div>
              </div>
              <div class="sellentt-col-md-2 hidden-xs info-col">
                <div class="info-title">Cliente</div>
                <div class="info-content">{{ order.store.name }} ({{ order.store.code }})</div>
              </div>
              <div class="sellentt-col-md-2 hidden-xs info-col">
                <div class="info-title">Vendedor</div>
                <div class="info-content">{{ order.seller.name }} ({{ order.seller.code }})</div>
              </div>
              <div class="sellentt-col-md-2 hidden-xs info-col">
                <div class="info-title">Status &nbsp;
                  <i class="fa-solid fa-battery-full 2xl"
                    *ngIf="order.external && order.external.pagarme_status_id === 'success'" style="color: green;"></i>
                  <i class="fa-solid fa-battery-empty 2xl"
                    *ngIf="order.external && order.external.pagarme_status_id === 'canceled'" style="color: red;"></i>
                  <i class="fa-solid fa-battery-half 2xl"
                    *ngIf="order.external && order.external.pagarme_status_id === 'pending'" style="color: orange;"></i>
                  <i class="fa-solid fa-battery-quarter 2xl"
                    *ngIf="order.external && !order.external.pagarme_status_id"></i>
                </div>
                <div class="info-content">
                  <span class="badge" [style.background.color]="order.status.color">
                    {{ order.status.name }}
                  </span>
                </div>
              </div>
              <div class="sellentt-col-md-2 hidden-xs info-col">
                <div class="info-title">Total</div>
                <div class="info-content">{{ order.amount_final | currency : 'BRL' }}</div>
              </div>
              <div class="sellentt-col-md-2 sellentt-col-sm-2 sellentt-col-xs-2 info-col-action">
                <div class="info-content">
                  <!-- <a class="info-action-details" data-toggle="collapse" data-parent="#accordion" href="#collapse-{{store.id}}">Detalhes do cliente</a> -->
                  <a [routerLink]="['/orders', order.code]">
                    <i class="fa-solid fa-pen-to-square"></i> <span class="hidden-xs"> Detalhes do pedido</span>
                  </a>
                </div>
              </div>
            </div>
          </h4>
        </div>
      </div>
    </div>

    <div class="pagination mt-5 justify-content-center">
      <nav>
        <sellentt-pagination (pageChanged)="onPageChange($event)" [page]="page" [hitsPerPage]="hitsPerPage"
          [nbHits]="nbHits" [nbPages]="nbPages" [maxSize]="maxSize">
        </sellentt-pagination>
      </nav>
    </div>
  </div>
</div>


<div *ngIf="!loadedData"
  class="sk-spinner sk-spinner-three-bounce m-5 w-100 min-vh-100 d-flex justify-content-center align-items-center">
  <div class="sk-bounce1"></div>
  <div class="sk-bounce2"></div>
  <div class="sk-bounce3"></div>
</div>
