<div *ngIf="isAuthenticationPage()">
  <router-outlet></router-outlet>
</div>

<div id="page-container" *ngIf="!isAuthenticationPage()">
  <!-- ======================= header start  ============================ -->
  <div class="header-section" *ngIf="loadedData && !isMaintenance">
    <div class="sellentt-container">
      <nav class="p-0 sellentt-navbar sellentt-navbar-expand-lg sellentt-bg-light">
        <a class="sellentt-navbar-brand" [routerLink]="['/home']">
          <img alt="Logo da Empresa" class="home-logo" src="{{logoUrl}}">
        </a>
        <button (click)="openSideBar()" aria-controls="mobileNavExample" class="sellentt-navbar-toggler"
          data-bs-target="#mobileNavExample" data-bs-toggle="offcanvas" type="button">
          <i class="fa-solid fa-bars fa-2x text-black"></i>
        </button>
        <div class="collapse sellentt-navbar-collapse" id="navbarSupportedContentLoggedIn">
          <ul class="sellentt-navbar-nav mr-4 mb-2 mb-lg-0">
            <li class="nav-item header-search ml-3 mr-5">
              <form action="#" method="post">
                <div class="input-group" style="flex-wrap: nowrap">
                  <input (keyup.enter)="filterProducts($event)" class="form-control" id="query" name="search"
                    placeholder="O que você está procurando?" required type="text">
                  <span class="input-group-append">
                    <button (click)="filterProducts($event)" class="btn btn-primary" type="submit" title="Pesquisar">
                      <i class="fa-solid fa-search fa-xl"></i>
                    </button>
                  </span>
                </div>
              </form>
            </li>

            <li [id]="isLoggedIn ? 'card-div': ''" class="nav-item d-flex align-items-center ml-4">
              <div class="row">
                <div *ngIf="isLoggedIn; else notLoggedIn" class="col-12">
                  <a (click)="showUserIcon()" id="user-card-opener">
                    <i class="fa-regular fa-circle-user fa-2xl iconSellentt"></i>
                    <div>
                      <strong>Olá, {{ user?.name }} </strong>
                      <span>minha conta <i class="fa-solid fa-chevron-down fa-2xs"></i></span>
                    </div>
                  </a>
                </div>
                <div (mouseleave)="showUserIcon()" class="col-2 col-xs-2 col-sm-2 col-md-2">
                  <div class="user-card hide" id="user-card">
                    <div class="sellentt-row text-left hide">
                      <div class="sellentt-col-12">
                        <div class="sellentt-col-12 p-0 ml-4 mt-3">
                          <b style="color: #5E6C84; font-size: 12px;">CONTA</b>
                        </div>
                      </div>
                    </div>
                    <div class="sellentt-row m-0 menu-items">
                      <ul class="ml-1 pt-3" style="list-style-type: none;">
                        <li class="custom-hover pb-3">
                          <span class="">
                            <a [routerLink]="['user-profile']" class="link">
                              <i class="fa-solid fa-user"></i> Meus Dados
                              <hr>
                            </a>
                          </span>
                        </li>
                        <li class="custom-hover pb-3">
                          <span class="">
                            <a [routerLink]="['stores']" class="link">
                              <i class="fa-solid fa-store"></i> Minhas Lojas
                            </a>
                          </span>
                        </li>
                        <li class="custom-hover pb-3">
                          <span class="">
                            <a [routerLink]="['orders']" class="link">
                              <i class="fa-solid fa-file-invoice"></i> Meus Pedidos
                            </a>
                          </span>
                        </li>
                        <li class="custom-hover pb-3">
                          <span class="">
                            <a [routerLink]="['suggested_lists']" class="link">
                              <i class="fa-solid fa-lightbulb"></i> Listas Sugeridas
                            </a>
                          </span>
                        </li>
                        <li class="custom-hover pb-3">
                          <span class="">
                            <a [routerLink]="['catalogs']" class="link">
                              <i class="fa-solid fa-warehouse"></i> Catálogos
                            </a>
                          </span>
                        </li>
                        <li *ngIf="isLoggedIn" class="custom-hover pb-3">
                          <span class="">
                            <a (click)="logout()" class="link">
                              <i class="fa-solid fa-right-from-bracket"></i> Sair
                            </a>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <ul class="sellentt-navbar-nav mb-2 mb-0" *ngIf="isLoggedIn">
            <li class="nav-item d-flex align-items-center ml-4">
              <a [routerLink]="['stores']">
                <h4>
                  <i class="fa-solid fa-store fa-xl iconSellentt animated-item" title="Minhas lojas"></i>
                </h4>
              </a>
            </li>
            <li class="nav-item d-flex align-items-center ml-4">
              <a [routerLink]="['/favorites']">
                <h4>
                  <i class="fa-solid fa-heart fa-xl iconSellentt animated-item" title="Favoritos"></i>
                </h4>
              </a>
            </li>
            <li class="nav-item d-flex align-items-center ml-4">
              <a [routerLink]="['/cart']">
                <div class="cart-content">
                  <i class="fa-solid fa-shopping-cart fa-xl iconSellentt" title="Carrinho"></i>
                  <span id="cart-qtd"> {{ cartProducts && cartProducts.length || 0 }}</span>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </nav>

      <!-- mobile menu -->
      <div aria-labelledby="mobileNavExampleLabel" class="sellentt-mobile-nav offcanvas offcanvas-start"
        id="mobileNavExample" tabindex="-1">
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="mobileNavExampleLabel">
            Menu
          </h5>
          <button (click)="closeSideBar()" aria-label="Close" class="btn-close" data-bs-dismiss="offcanvas"
            type="button"></button>
        </div>
        <div class="offcanvas-body">
          <form action="#" method="post">
            <div class="input-group">
              <input class="form-control" name="search" placeholder="Buscar..." required type="text">
              <span class="input-group-append">
                <button class="btn btn-primary" type="submit">
                  <i class="fa-solid fa-search fa-xl"></i>
                </button>
              </span>
            </div>
          </form>
          <div class="mt-4 menu">
            <li class="nav-item d-flex mb-3">
              <div *ngIf="isLoggedIn; else notLoggedIn">
                <span class="ml-2 text-white">Bem vindo,
                  <strong>{{ user?.name }}</strong>
                </span>
              </div>
            </li>
            <div class="sellentt-row m-0 menu-items" *ngIf="isLoggedIn">
              <ul class="ml-1 pt-3" style="list-style-type: none;">
                <li class="custom-hover pb-3">
                  <span class="">
                    <a (click)="navigationTo('user-profile')" class="link">
                      <i class="fa-solid fa-user"></i> Meus Dados
                      <hr>
                    </a>
                  </span>
                </li>
                <li class="custom-hover pb-3">
                  <span class="">
                    <a (click)="navigationTo('stores')" class="link">
                      <i class="fa-solid fa-store"></i> Minhas Lojas
                    </a>
                  </span>
                </li>
                <li class="custom-hover pb-3">
                  <span class="">
                    <a (click)="navigationTo('orders')" class="link">
                      <i class="fa-solid fa-file-invoice"></i> Meus Pedidos
                    </a>
                  </span>
                </li>
                <li class="custom-hover pb-3">
                  <span class="">
                    <a (click)="navigationTo('suggested_lists')" class="link">
                      <i class="fa-solid fa-lightbulb"></i> Listas Sugeridas
                    </a>
                  </span>
                </li>
                <li class="custom-hover pb-3">
                  <span class="">
                    <a (click)="navigationTo('catalogs')" class="link">
                      <i class="fa-solid fa-warehouse"></i> Catálogos
                    </a>
                  </span>
                </li>
                <!-- @if (isset($store_keeper_access) && count($store_keeper_access) > 1) -->
                <li class="custom-hover pb-3">
                  <span class="">
                    <a (click)="navigationTo('other-stores')" class="link">
                      <i class="fa-solid fa-shop"></i> Outras lojas
                    </a>
                  </span>
                </li>
                <li *ngIf="isLoggedIn" class="custom-hover pb-3">
                  <span class="">
                    <a (click)="logout()" class="link">
                      <i class="fa-solid fa-right-from-bracket"></i> Sair
                    </a>
                  </span>
                </li>
              </ul>
            </div>
            <div class="d-flex justify-content-between p-4" style="list-style-type: none">

              <li *ngIf="isLoggedIn">
                <a class="sellentt-nav-link" href="#">
                  <i class="fa-solid fa-store fa-xl"></i>
                  <span class="icon-description">Minhas Lojas</span>
                </a>
              </li>
              <li>
                <a class="sellentt-nav-link" [routerLink]="['/favorites']">
                  <i class="fa-solid fa-heart fa-xl"></i>
                  <span class="icon-description">Favoritos</span>
                </a>
              </li>
              <li>
                <a (click)="navigationTo('cart')" class="sellentt-nav-link">
                  <i class="fa-solid fa-shopping-cart fa-xl"></i>
                  <span class="icon-description">
                    Carrinho (<span id="cart-qtd-mobile">{{ cartProducts && cartProducts.length || 0 }}</span>)
                  </span>
                  <span id="cart-qtd"></span>
                </a>
              </li>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

  <div class="category-section d-none d-lg-block" *ngIf="loadedData && !isMaintenance">

    <div class="sellentt-container">
      <nav class="navbar navbar-expand-lg">
        <div class="collapse navbar-collapse justify-content-around" id="navbarSupportedContentLoggedOut">
          <div class="dropdown">
            <a class="btn btn-ghost dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              CATEGORIAS
            </a>
            <ul class="dropdown-menu multi-level" role="menu" aria-labelledby="dropdownMenu">
              <ng-container *ngFor="let category of categories">
                <li *ngIf="category.children.length > 0" class="dropdown-submenu">
                  <a class="dropdown-item" [routerLink]="['/categories/' + category.id]" tabindex="-1">{{ category.name
                    |
                    uppercase }}</a>
                  <ul class="dropdown-menu">
                    <ng-container *ngFor="let child of category.children">
                      <li *ngIf="child.children.length === 0">
                        <a class="dropdown-item" [routerLink]="['/categories/' + child.id]">
                          {{ child.name | uppercase }}
                        </a>
                      </li>
                      <li *ngIf="child.children.length > 0" class="dropdown-submenu">
                        <a class="dropdown-item" [routerLink]="['/categories/' + child.id]">
                          {{ child.name | uppercase }}</a>
                        <ul class="dropdown-menu">
                          <li *ngFor="let grandChild of child.children">
                            <a class="dropdown-item" [routerLink]="['/categories/' + grandChild.id]">{{ grandChild.name
                              |
                              uppercase }}</a>
                          </li>
                        </ul>
                      </li>
                    </ng-container>
                  </ul>
                </li>
                <li *ngIf="category.children.length === 0">
                  <a class="dropdown-item" [routerLink]="['/categories/' + category.id]">{{ category.name | uppercase
                    }}</a>
                </li>
              </ng-container>
            </ul>
          </div>

          <a class="btn btn-ghost" *ngFor="let footerCategories of footerCategories; let i = index;"
            [routerLink]="['/categories/' + footerCategories.id]" (mouseover)="firstNestedMouseOver(i)">
            {{footerCategories.name | uppercase}}
          </a>
        </div>
      </nav>

    </div>
  </div>

  <!-- ======================= header end  ============================ -->
  <ng-template #notLoggedIn>
    <div class="make-login">
      <div class="mr-3">
        <i class="fa-regular fa-circle-user fa-2xl"></i>
      </div>
      <div class="">
        <span>Faça </span>
        <a [routerLink]="['/login']"><strong>LOGIN </strong></a>
        <span>ou </span><br>
        <span>crie seu </span>
        <a [routerLink]="['/register']"><strong>CADASTRO</strong></a>
      </div>
    </div>
  </ng-template>

  <div id="content-wrap">
    <router-outlet></router-outlet>
  </div>

  <button (click)="openWhatsApp()" class="btn btn-whatsapp" *ngIf="showBtnWhatsappRedirect">
    <span id="whatsapp-icon-span">
      <i class="fa-brands fa-whatsapp"></i>
    </span>
  </button>

  <!-- Footer -->
  <footer class="text-center text-lg-start bg-light" *ngIf="loadedData && !isMaintenance">
    <!-- Section: Links  -->
    <section class="">
      <div class="container text-center text-md-start mt-5">
        <!-- Grid row -->
        <div class="row mt-3">

          <!-- Grid column -->
          <div class="col-md-3 col-lg-3 col-xl-2 text-left mx-auto mb-4">
            <!-- Content -->
            <h6 class="text-uppercase fw-bold mb-4">
              <i class="fas fa-gem me-3"></i> {{ecommerceConfigs.store_name}}
            </h6>
          </div>
          <!-- Grid column -->

          <div class="col-md-3 col-lg-4 col-xl-3 text-left mx-auto mb-4">
            <!-- Content -->
            <h6 class="text-uppercase fw-bold mb-4">
              INSTITUCIONAL
            </h6>
            <p *ngIf="showTextAbout">
              <a class="sellentt-navbar-brand" [routerLink]="['/about']">
                <i class="fas fa-info-circle me-3"></i> Sobre
              </a>
            </p>
            <p *ngIf="showTextPrivacyPolicy">
              <a class="sellentt-navbar-brand" [routerLink]="['/privacy-policy']">
                <i class="fas fa-user-shield me-3"></i> Política de Privacidade
              </a>
            </p>
            <p *ngIf="showTextSitePolicy">
              <a class="sellentt-navbar-brand" [routerLink]="['/site-policy']">
                <i class="fas fa-file-contract me-3"></i> Política do Site
              </a>
            </p>
          </div>

          <!-- Grid column -->
          <div class="col-md-2 col-lg-2 col-xl-2 text-left mx-auto mb-4">
            <!-- Links -->
            <h6 class="text-uppercase fw-bold mb-4">
              Categorias
            </h6>
            <p *ngFor="let category of footerCategories">
              <a [routerLink]="['/categories/' + category.id]" class="text-reset">
                {{capitalizeOnlyFirstLetter(category.name)}}
              </a>
            </p>
          </div>
          <!-- Grid column -->

          <!-- Grid column -->
          <div class="col-md-3 col-lg-2 col-xl-2 text-left mx-auto mb-4">
            <!-- Links -->
            <h6 class="text-uppercase fw-bold mb-4">
              Mídias Sociais
            </h6>
            <p>
              <a href="{{ecommerceConfigs.facebook_link}}" class="me-4 text-reset" target="_blank" *ngIf="ecommerceConfigs.facebook_link.includes('.com')">
                <i class="fab fa-facebook-f" style="color: #3b5998"></i> Facebook
              </a>
            </p>
            <p>
              <a href="{{ecommerceConfigs.instagram_link}}" class="me-4 text-reset" target="_blank" *ngIf="ecommerceConfigs.instagram_link.includes('.com')">
                <i class="fab fa-instagram" style="color: #fb3958;"></i> Instagram
              </a>
            </p>
            <p>
              <a href="{{ecommerceConfigs.linkedin_link}}" class="me-4 text-reset" target="_blank" *ngIf="ecommerceConfigs.linkedin_link.includes('.com')">
                <i class="fab fa-linkedin" style="color: #0077b5;"></i> Linkedin
              </a>
            </p>
            <p>
              <a href="{{ecommerceConfigs.twitter_link}}" class="me-4 text-reset" target="_blank" *ngIf="ecommerceConfigs.twitter_link.includes('.com')">
                <i class="fab fa-twitter" style="color: #00acee;"></i> Twitter / X
              </a>
            </p>
          </div>
          <!-- Grid column -->

          <!-- Grid column -->
          <div class="col-md-4 col-lg-3 col-xl-3 text-left mx-auto mb-md-0 mb-4">
            <!-- Links -->
            <h6 class="text-uppercase fw-bold mb-4">Contato</h6>
            <p *ngIf="ecommerceConfigs.address_city && ecommerceConfigs.address_state">
              <i class="fas fa-home me-3" style="color: #fefefe;"></i>
              {{ecommerceConfigs.address_city}} - {{ecommerceConfigs.address_state}}
            </p>
            <p *ngIf="ecommerceConfigs.email_1">
              <i class="fas fa-envelope me-3" style="color: #fefefe;"></i>
              {{ecommerceConfigs.email_1}}
            </p>
            <p *ngIf="ecommerceConfigs.phone_number_1">
              <i class="fas fa-phone me-3"></i>
              {{ecommerceConfigs.phone_number_1}}
            </p>
            <p *ngIf="ecommerceConfigs.mobile_number_1">
              <i class="fas fa-square-phone me-3"></i>
              {{ecommerceConfigs.mobile_number_1}}
            </p>
          </div>
          <!-- Grid column -->
        </div>
        <!-- Grid row -->
      </div>
    </section>
    <!-- Section: Links  -->

    <hr class="footer__divider" />

    <!-- Copyright -->
    <div class="text-center pb-3" style="background-color: rgba(0, 0, 0, 0.05);">
      © 2024
      <a class="text-reset fw-bold" href="https://sellentt.com.br/">Sellentt</a>
      | Todos os direitos reservados.
    </div>
    <!-- Copyright -->
  </footer>
  <!-- Footer -->
</div>
