import {Component, ElementRef, OnInit} from '@angular/core';
import {AuthService} from './services/auth/auth.service';
import {Router} from '@angular/router';
import {ApiService} from './services/api/api.service';
import {Title} from '@angular/platform-browser';
import {CartService} from './services/carts/cart.service';
import {UserService} from './services/user/user.service';
import { LocalStorageService } from './services/local-storage/local-storage.service';
import { FirebaseApp } from '@angular/fire';
import { SalePolicyService } from './services/sale-policy/sale-policy.service';
import { Toastr } from 'ng6-toastr-notifications';
import { ToastrService } from './services/toastr/toastr-service.service';
import { UrlService } from './services/url/url.service';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {

  title = 'sellentt-e-commerce';

  username = '';
  isAdmin = true;
  isManager = true;
  isSeller = true;
  isLoggedIn = false;
  loadedData: boolean = false;
  all_regions;
  notifications;
  region_selected;
  regionNames;
  configs;
  infos;
  region_name = 'Região';
  region_name_plural = 'Regiões';
  sellers_name = 'Vendedor';
  sellers_name_plural = 'Vendedores';
  segments_name_plural = 'Segmentos';
  rh_label = 'Ponto Eletrônico';
  user_id;
  user_name = '';
  user_email;
  hide_margin = false;
  currency = 'R$';
  plan_id = '';
  b2b;
  categories = [];
  footerCategories = [];

  modules = {
    biaction: true,
    rh: true,
    prices_shopping: true,
    orders: true,
    crm: true,
    budget: true,
    reporting: true,
    products_rating: true,
    pd: true,
    fiscal: true,
    georef: true,
    expenses: true,
    marketing: true,
    ecommerce: true,
    b2c: true,
    payment_term: true,
    pd_old: true
  };

  urlBase = '';
  basePath = `https://p.biaction.com.br/`;

  storeFavicon = 'favicon.icon';
  isMaintenance = false;

  ecommerceConfigs = {};

  user;

  catalog = {
    whatsapp_number: '',
    link_facebook: '',
    link_instagram: '',
    link_youtube: '',
  };


  mainCategories = [];
  allCategories = [];
  cartProducts = [];

  showProductsSpinner: boolean = true;
  logoUrl = '';

  term = '';

  nestedSecondChildren = [];
  nestedThirdChildren = [];

  showFirstNestedMenu = false;
  showSecondNestedMenu = false;
  showThirdNestedMenu = false;
  showBtnWhatsappRedirect;
  storePhoneNumber = '';
  storeWhatsappNumber = '';
  allOrderTypes = [];
  newListCategories = [];

  showTextAbout = false;
  showTextPrivacyPolicy = false;
  showTextSitePolicy = false;

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private router: Router,
    private _eref: ElementRef,
    private cartService: CartService,
    private userService: UserService,
    private salePolicyService: SalePolicyService,
    private toastrService: ToastrService,
    private localStorageService: LocalStorageService, // Injetando o serviço LocalStorageService
    private urlService: UrlService
  ) {
    this.urlBase = this.urlService.getUrlBase();

    onclick = (event) => {
      if (!this._eref.nativeElement.contains(event.target)) {
        $('#user-card').addClass('hide');
      }
    };
  }

  ngOnInit() {
    this.checkUserState();
    this.getLocalStorageEcommerceConfigs();
    // this.firebase.functions().useFunctionsEmulator('http://localhost:5001');
    this.toggleUserIcon();
    this.setMobileMenu();

    this.localStorageService.storageChanges$.subscribe(change => {
      if (change) {
        // Quando o Local Storage foi LIMPO
        if (change.key === 'clear') {
          this.getLocalStorageEcommerceConfigs();
        }
      }
    });
  }

  getEcommercePolicy() {
    this.salePolicyService.getEcommercePolice(this.ecommerceConfigs['biaction_id']).then((res) => {
      if(res['show_text_about'] == 1){
        this.showTextAbout = true;
      }
      if(res['show_text_about'] == 1){
        this.showTextPrivacyPolicy = true;
      }
      if(res['show_text_about'] == 1){
        this.showTextSitePolicy = true;
      }
      this.showBtnWhatsappRedirect = res && res['show_whatsapp_redirect'] ? res['show_whatsapp_redirect'] : false;
    });
  }

  openWhatsApp() {
    const that = this;
    const btn = $('#whatsapp-icon-span');
    const html = btn.html();
    btn.html('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Enviando...');

    that.storePhoneNumber = that.localStorageService.get('mobile_number_1');
    that.storeWhatsappNumber = that.localStorageService.get('mobile_number_2');

    if (that.storeWhatsappNumber === '' || that.storeWhatsappNumber === undefined) {
      that.storeWhatsappNumber = that.storePhoneNumber;
    }

    btn.html(html);
    if (true) {
      window.open(`https://wa.me/+55${that.storeWhatsappNumber}?`, '_blank')
    }
  }

  checkUserState() {
    this.isLoggedIn = this.authService.isLoggedIn();

    if (!this.isLoggedIn) {
        this.authService.authLogout();
    }
  }

  logout() {
    this.authService.authLogout();
    window.location.href = '/home';
  }

  async getLocalStorageEcommerceConfigs() {
    // Método Principal para pegar configurações da Empresa e salvar no Storage
    await this.authService.getInfosByUrl('?url_base=' + this.urlBase);

    // Método para setar as configurações do Ecommerce
    this.ecommerceConfigs = this.authService.setEcommerceConfigs();
    this.isMaintenance = await this.authService.getEcommerceStatus(this.urlBase);

    this.logoUrl = this.basePath + this.ecommerceConfigs['pd_id'] + '/' + this.ecommerceConfigs['store_logo'];
    this.storeFavicon = this.basePath + this.ecommerceConfigs['pd_id'] + '/' + this.ecommerceConfigs['store_favicon'];

    // Seta as propriedades de CSS Secundário
    document.documentElement.style.setProperty('--bg-secondary', this.ecommerceConfigs['store_primary_color']);

    // Altera o Favicon
    this.hangeFavicon(this.storeFavicon);

    // Busca os Produtos
    this.getCartProducts();

    // Verifica se o Usuário está LOGADO
    if (this.isLoggedIn) {
      this.getUser();
    }
  }

  getUser() {
    this.userService.getUserData().then((res) => {
      if (!res['error']) {
        this.user = res['user'];
      }
    })
  }

  getCartProducts() {
    if (this.isLoggedIn) {
      const cart = this.cartService.getCart();

      if (cart && cart !== null) {
        this.cartProducts = cart.products;
      }
    }

    this.getProductsCategories();
  }

  getProductsCategories() {
    this.showProductsSpinner = true;
    this.apiService.getProductsCategoriesParents(this.ecommerceConfigs['pd_id']).subscribe(res => {
      if (!res['error']) {

        this.allCategories = res['productCategory'];

        this.mainCategories = this.allCategories.slice(0, 5);

      }

      setTimeout(() => {
        this.getEcommercePolicy();
      }, 2000);

      this.getCategoriesWithChildren();
    });
  }

  getAllOrderTypes() {
    const that = this;

    this.apiService
      .getOrderTypes(this.isLoggedIn, this.ecommerceConfigs['pd_id'])
      .subscribe((res) => {
        if (!res['error']) {
          this.allOrderTypes = res['order_types'];
        }
      });

      setTimeout(() => {
        this.checkOrderTypeHasSpecificCategories()
      }, 4000);
  }

  //valida de tipo de pedido tem categoria especifica para exibir
  checkOrderTypeHasSpecificCategories() {
    let selectedOrderTypeId = this.localStorageService.get('selectedOrderTypeCode');
    let findOrderType = this.allOrderTypes.find(item => item.id === selectedOrderTypeId);

    if(findOrderType === undefined) {
      findOrderType = this.allOrderTypes[0];
    }

    if(findOrderType) {
      let filterCategories = findOrderType['data']['ecommerce']['categories'];
      this.localStorageService.set('filterCategories', filterCategories);

      if(filterCategories.length > 0){
        this.newListCategories = this.categories.filter((item) => {
          return filterCategories.includes(item.id);
        });
      }
    }

    if(this.newListCategories !== undefined && this.newListCategories.length > 0) {
      this.categories = this.newListCategories;
    }
  }

  getCategoriesWithChildren() {
    // Verifica se o usuário está logado
    const isUserLoggedIn = this.isLoggedIn;

    const getCategoryChildrenSubscription = this.apiService.getCategoryChildrenById(1, isUserLoggedIn, this.ecommerceConfigs['pd_id']);

    const handleResponse = (res) => {
      if (!res['error']) {
        res['categories'].map((categorys) => {
          if (categorys.allow_ecommerce === 1) {
            this.categories.push(categorys);
          }
        });

        let Category_filter;
        if (isUserLoggedIn) {
          Category_filter = this.localStorageService.get('main_categories');
        } else {
          Category_filter = this.localStorageService.get('no_user_categories');
        }

        const filteredCategories = this.categories.filter(category => Category_filter.includes(category.id.toString()));

        this.footerCategories = filteredCategories.slice(0, 5);

        this.pageLoaded();
      }
    };

    getCategoryChildrenSubscription.subscribe(handleResponse);
    this.getAllOrderTypes();
  }




  hangeFavicon(src) {
    const link = document.createElement('link'),
      oldLink = document.getElementById('dynamic-favicon');

    link.id = 'dynamic-favicon';
    link.rel = 'shortcut icon';
    link.href = src;

    if (oldLink) {
      document.head.removeChild(oldLink);
    }

    document.head.appendChild(link);
  }

  // hide header and footer on login page
  isAuthenticationPage() {
    let location = window.location.href;
    return location.indexOf('/login') > 0 ||
      location.indexOf('/forgot-password') > 0 ||
      location.indexOf('/password-reset') > 0;
  }

  capitalizeOnlyFirstLetter(word: string) {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }


  setMobileMenu() {
    $('.filter-button').click(function () {
      $('.shop-sidebar').animate({
        left: 0
      }, 500);
    });

    $('.close-sidebar-filter').click(function () {
      $('.shop-sidebar').animate({
        left: '-110%'
      }, 700);
    });
  }

  setMouseUp() {
    $(document).mouseup(function (e) {
      const container = $('#user-card');
      const button = $('#user-card-opener');

      if (button.is(e.target) && container.has(e.target).length === 0) {
        container.show();
      } else if ((!container.is(e.target)) && (container.has(e.target).length === 0)) {
        container.hide();
      }
    });
  }

  openSideBar() {
    $('#mobileNavExample').addClass('show');
  }

  closeSideBar() {
    $('#mobileNavExample').removeClass('show');
  }

  getStorageUrl(path) {
    path = path.replace(/^\/?(storage\/)?uploads\//, '');

    if (path.indexOf('/') === 0) {
      path = path.substr(1);
    }

    return `https://p.biaction.com.br/${this.ecommerceConfigs['pd_id']}/${path}`;
  }

  toggleUserIcon() {
    $(document).on('mouseover', function (e) {
      if ((e.target.id === 'card-div') || ($(e.target).parents('#card-div').length !== 0) || (e.target.id === 'user-card')) {
        $('#user-card').show();
      }
    });

    $('#user-card').on('mouseout', function (e) {
      $('#user-card').hide();
    });
  }

  showUserIcon() {
    if ($('#user-card').hasClass('hide')) {
      $('#user-card').removeClass('hide');
    } else {
      $('#user-card').addClass('hide');
    }
  }

  filterProducts() {
    const query = $('#query').val();
    this.router.navigate([`home/${query}`]);
  }

  pageLoaded() {
    this.loadedData = true;
  }

  zeroNestedMouseOver() {
    $('#categories-menu i').delay(500).removeClass('fa-angle-up');
    $('#categories-menu i').delay(500).addClass('fa-angle-down');

    this.showFirstNestedMenu = true;
  }

  firstNestedMouseOver(child) {
    const nestedSecondChildren = [];

    this.categories.forEach((cat) => {
      if (cat.parent_id === null) {
        if (typeof cat.children !== 'undefined' &&
          cat.children.length > 0) {
          nestedSecondChildren.push(cat.children);
        }
      }
    });

    this.nestedSecondChildren = nestedSecondChildren[child];
    this.showSecondNestedMenu = true;
    this.showThirdNestedMenu = false;
  }

  secondNestedMouseOver(child) {
    const nestedSecondChildren = [];
    const nestedThirdChildren = [];

    this.categories.forEach((cat) => {
      if (cat.parent_id === null) {
        if (typeof cat.children !== 'undefined' &&
          cat.children.length > 0) {
          nestedSecondChildren.push(cat.children);
        }
      }
    });

    this.nestedSecondChildren.forEach((cat, index) => {
      if (typeof cat.children !== 'undefined' &&
        cat.children.length > 0) {
        nestedThirdChildren.push(cat.children);
      }
    });

    this.nestedThirdChildren = nestedThirdChildren[child];
    this.showThirdNestedMenu = true;
  }

  zeroMouseLeave(e) {
    $('#categories-menu i').delay(500).removeClass('fa-angle-down');
    $('#categories-menu i').delay(500).addClass('fa-angle-up');

    this.showFirstNestedMenu = false;
    this.showSecondNestedMenu = false;
    this.showThirdNestedMenu = false;
  }

  navigationTo(location) {
    this.router.navigate([`${location}`]);
    this.closeSideBar();
  }

}
