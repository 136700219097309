import {Component, Input, Output, OnInit, EventEmitter} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {LocalStorageService} from '../../services/local-storage/local-storage.service';
import {ToastrService} from '../../services/toastr/toastr-service.service';
import {AuthService} from '../../services/auth/auth.service';
import {FirebaseApp} from "@angular/fire";
import {ActivatedRoute} from "@angular/router";

declare const $: any;

@Component({
  selector: 'app-products-filter',
  templateUrl: './products-filter.component.html',
  styleUrls: ['./products-filter.component.css']
})
export class ProductsFilterComponent implements OnInit {

  @Input()
  ecommerceConfigs?: any;

  @Input()
  orderTypeId: any;

  @Input()
  priceTableId?: any;

  @Input()
  page?: any;

  @Input()
  screen?: any;

  @Input()
  categoryId?: any;

  @Output() filter_data = new EventEmitter();

  categories = [];
  filters = [{ value: 'is_kit', label: 'É Kit' }, { value: 'is_offer', label: 'Preço Promocional' }];

  companyId: any;
  clientId: any;
  isLoggedIn: boolean;
  allOrderTypes = [];
  newListCategories = [];

  constructor(
    private apiService: ApiService,
    private localStorageService: LocalStorageService,
    private toastr: ToastrService,
    private authService: AuthService,
    private firebase: FirebaseApp,
    private route: ActivatedRoute,
  ) {
    this.companyId = this.localStorageService.get('biaction_id');
    this.clientId = this.localStorageService.get('pd_id');
  }

  ngOnInit() {
    this.isLoggedIn = this.authService.isLoggedIn();
    this.getAllOrderTypes();
    if (this.screen === 'home') {
      this.getCategories();
    }

    $(document).ready(function () {
      $('#category').select2({});

      $('#status_select').select2({
        closeOnSelect: false,
        placeholder: 'Selecione'
      });
    });
  }

  getAllOrderTypes() {
    const that = this;
    this.apiService
      .getOrderTypes(this.isLoggedIn, this.ecommerceConfigs['pd_id'])
      .subscribe((res) => {
        if (!res['error']) {
          this.allOrderTypes = res['order_types'];
        }
      });

      setTimeout(() => {
        this.defineListOfCategories()
      }, 700);
      

  }

  defineListOfCategories() {
    let selectedOrderTypeId = this.localStorageService.get('selectedOrderTypeCode');

    if (selectedOrderTypeId.length > 0 && selectedOrderTypeId !== undefined && this.allOrderTypes.length > 0) {
      const findOrderType = this.allOrderTypes.find(item => item.id === selectedOrderTypeId);

      if (findOrderType) {
        let listOfCategoriesLinkedToOrderType = findOrderType['data']['ecommerce']['categories'];
        this.localStorageService.set('listOfCategoriesLinkedToOrderType', listOfCategoriesLinkedToOrderType);

        if (listOfCategoriesLinkedToOrderType.length > 0 && listOfCategoriesLinkedToOrderType !== undefined && this.categories.length > 0) {
          this.newListCategories = this.categories.filter((item) => {
            return listOfCategoriesLinkedToOrderType.includes(item.id);
          });
        }
      }

      if (this.newListCategories !== undefined && this.newListCategories.length > 0) {
        this.categories = this.newListCategories;
      }

      this.localStorageService.set('listOfCategories', this.categories);
    }  
  }

  getCategories() {
    this.apiService.getCategoryChildrenById(1, this.isLoggedIn, this.ecommerceConfigs['pd_id']).subscribe(res => {
      if (!res['error']) {
        res['categories'].map((categorys) => {
          if (categorys.allow_ecommerce === 1) {
            this.categories.push(categorys);
          }
        });
      }
    });
  }

  filter() {
    const that = this;
    const btn = $('#filterBtn');
    btn.html('<span class="spinner-border spinner-border-sm" aria-hidden="true"></span>');
    btn.prop('disabled', true);
    const ProdutosRequest = this.firebase.functions().httpsCallable('products-getCart'); // Busca para tabela principal
    const find_prod = $('#find-prod').val() ? $('#find-prod').val() : '';
    const name = find_prod !== '' ? find_prod : '';
    const category = $('#category').val() ? $('#category').val() : '';
    const status = this.getStatusFilter();

    let selectedOrderTypeId = this.localStorageService.get('selectedOrderTypeCode');
    let priceTable = this.localStorageService.get('priceTableId');

    const params = {
      name: name,
      category: this.screen === 'home' ? category : this.categoryId,
      status: status,
      page: this.page,
      source: 'new-ecommerce',
      company: {
        id: this.companyId,
      },
      order_type_id: selectedOrderTypeId,
      price_table_id: priceTable,
    };

    ProdutosRequest(params).then(result => {
      let productsResult = result.data.products

      let categoriesIds = [];
      if(this.newListCategories.length > 0) {
        categoriesIds = this.newListCategories.map(item => item.id);
      } else {
        categoriesIds = this.categories.map(item => item.id);
      }
      
      let productsFiltered = productsResult.filter((item) => {
        return categoriesIds.includes(item.category_id);
      });
      
      this.localStorageService.set('listProductsOfCategories', productsFiltered);
      result.data.products = productsFiltered;
      this.filter_data.emit(result);
      btn.html('<i class="fa fa-search"></i> Consultar');
      btn.prop('disabled', false);
    }).catch(error => {
      console.log('ERROR', error);
      this.filter_data.emit(error);
      btn.html('<i class="fa fa-search"></i> Consultar');
      btn.prop('disabled', false);
    });
  }

  getStatusFilter() {
    const statusList = [];
    const statusSelect = $('#status_select').val() ? $('#status_select').val() : [];
    if (statusSelect.length > 0) {
      statusSelect.forEach(s => {
        statusList.push(s);
      });
    }

    return statusList;
  }

}
