import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import * as numeral from 'numeral';
import { ApiService } from '../../services/api/api.service';
import { UserService } from '../../services/user/user.service';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';
import { NavigationService } from '../../services/navigation/navigate.service';
import { AuthService } from '../../services/auth/auth.service';
import { SalePolicyService } from '../../services/sale-policy/sale-policy.service';
import { FirebaseApp } from '@angular/fire';

declare var $: any;

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css'],
})
export class CategoriesComponent implements OnInit {
  companyId;
  clientId;
  categoryId;
  products = [];
  categories = [];
  mainCategories = [];
  allowedCategories = [];
  productSelected: any = {};
  orderTypes = [];
  selectedOrderType = {};

  showQtdField: boolean;
  isGrid = true;
  ncm_list: any;
  isento_st = false;

  user;
  category = {
    id: '',
    children: [],
  };

  term = '';

  pageOfItems = [];

  showProductsSpinner = true;
  loadedData = false;
  componentInit = false;
  isLoggedIn = false;

  paginatedData = [];
  page: number = 1;
  hitsPerPage: number = 20;
  nbHits: number;
  nbPages: number = 1;
  maxSize: number = 7;

  countChildren: number = 1;

  isStockActive = false;

  configType = 0;
  generalEnableFutureStock = '';
  generalStockFutureAdvisory = '';
  enableFutureStock = '';
  stockFutureAdvisory = '';
  scopeStockQuota = '';
  ignoreStockQuota = '';
  generalEnableStockUsage = '';
  storeSegment = '';

  constructor(
    private firebase: FirebaseApp,
    private apiService: ApiService,
    private userService: UserService,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private navigationService: NavigationService,
    private router: Router,
    private authService: AuthService,
    private salePolicyService: SalePolicyService
  ) {
    this.clientId = this.localStorageService.get('pd_id');
    this.companyId = this.localStorageService.get('biaction_id');
  }

  ngOnInit() {
    this.isLoggedIn = this.authService.isLoggedIn();

    this.route.params.subscribe((val) => {
      this.categoryId = this.route.snapshot.params['id'];

      this.componentInit = true;
      this.getEcommercePolicy();
      this.getOrderTypes();
      this.getPricingPolicy();
      this.getValuesOfStorage();
    });
  }

  getValuesOfStorage() {
    this.configType = this.localStorageService.get('configType');
    this.generalEnableFutureStock = this.localStorageService.get('generalEnableFutureStock');
    this.generalStockFutureAdvisory = this.localStorageService.get('generalStockFutureAdvisory');
    this.enableFutureStock = this.localStorageService.get('enableFutureStock');
    this.stockFutureAdvisory = this.localStorageService.get('stockFutureAdvisory');
    this.scopeStockQuota = this.localStorageService.get('scopeStockQuota');
    this.ignoreStockQuota = this.localStorageService.get('ignoreStockQuota');
    this.generalEnableStockUsage = this.localStorageService.get('generalEnableStockUsage');
    this.storeSegment = this.localStorageService.get('storeSegment');
  }

  getOrderTypes() {
    const that = this;

    this.apiService
      .getOrderTypes(this.isLoggedIn, this.clientId)
      .subscribe((res) => {
        if (!res['error']) {
          res['order_types'].forEach((type) => {
            if (
              typeof type.data.ecommerce !== 'undefined' &&
              type.data.ecommerce.active === true
            ) {
              that.orderTypes.push({
                id: type.id,
                name: type.data.name,
                categories: type.data.ecommerce.categories,
              });

              that.selectedOrderType = {
                id: type.id,
                name: type.data.name,
                categories: type.data.ecommerce.categories,
              };

              this.getUser();
            }
          });
        }
      });
  }

  getPricingPolicy() {
    this.salePolicyService.getPolices(this.companyId).then((res) => {
      if (typeof res['fields'] !== 'undefined') {
        if (
          typeof res['fields']['stock_ecommerce'] !== 'undefined' &&
          res['fields']['stock_ecommerce']
        ) {
          this.isStockActive = true;
        } else {
          this.isStockActive = false;
        }
      }
    });
  }

  setFilters() {
    $(document).ready(function () {
      $('#discount-percentage').slider({
        range: true,
        min: 0,
        max: 90,
        values: [30, 60],
        slide: function (event, ui) {
          $('#amount').val(ui.values[0] + '% - ' + ui.values[1] + '%');
        },
      });

      $('#amount').val(
        $('#discount-percentage').slider('values', 0) +
          '% - ' +
          $('#discount-percentage').slider('values', 1) +
          '%'
      );
    });
  }

  getUser() {
    this.userService.getUserData().then((res) => {
      if (!res['error']) {
        this.user = res['user'];
        this.getPriceTableById();
      }
    });
  }

  getPriceTableById() {
    this.apiService.getPriceTablesAll().subscribe((res) => {
      if (!res['error']) {
        const priceTables = res['price_tables'];

        const storePriceTable = priceTables.find(
          (priceTable) => priceTable.id === this.user.store.default_price_table
        );

        this.user.store.default_price_table_code = storePriceTable.code;

        this.getTaxRules();
        this.getCategory();
      }
    });
  }
  getEcommercePolicy() {
    this.salePolicyService.getEcommercePolice(this.companyId).then((res) => {
      if (res) {
        this.showQtdField = res['show_qtd_field']
          ? res['show_qtd_field']
          : false;
      }
    });
  }

  getTaxRules() {
    const store = this.user && this.user.store ? this.user.store : {};
    const tax_rules_id = store.tax_rules_id ? store.tax_rules_id : '';

    if (tax_rules_id !== '') {
      this.apiService.getTaxRules(tax_rules_id).subscribe((res) => {
        if (!res['error']) {
          const fiscalTaxRules = res['fiscalTaxRules'];
          if (fiscalTaxRules !== undefined) {
            this.isento_st = fiscalTaxRules.isento_st
              ? fiscalTaxRules.isento_st
              : false;
            this.ncm_list = fiscalTaxRules.ncm;
          }
        }
      });
    }
  }

  getCategory() {
    this.apiService
      .getCategoryChildrenById(this.categoryId, this.isLoggedIn)
      .subscribe((res) => {
        if (res['error']) {
          console.log('Erro ao buscar as categorias!');
        } else {
          this.category = res['category'];

          if (typeof this.category.children !== 'undefined') {
            this.category.children = this.category['children'];
          }

          this.getAllowedProductsToUser();
        }
      });
  }

  search(txt) {
    this.term = txt;

    this.getAllowedProductsToUser();
  }

  searchChanged(e: any) {
    const that = this;

    setTimeout(function () {
      that.search(e.target.value);
    }, 500);
  }

  getAllowedProductsToUser() {
    this.allowedCategories = [];
    this.paginatedData = [];
    this.showProductsSpinner = true;
    this.loadedData = true;

    let is_search = false;

    if (this.term && this.term !== '') {
      is_search = true;
    }

    this.allowedCategories.push(this.category.id);

    const result = [];

    const getChildrenIds = (node) => {
      for (let child of node) {
        if (
          child.children &&
          Array.isArray(child.children) &&
          child.children.length > 0
        ) {
          const children = child.children.map(({ id }) => id);
          result.push(children);
          getChildrenIds(child.children);
        }
      }

      return [].concat.apply([], result);
    };

    const allowedCategories = getChildrenIds([this.category]);
    this.allowedCategories.push(allowedCategories);

    this.componentInit = false;

    const productsRequest = this.firebase
      .functions()
      .httpsCallable('products-getCart');

    const data = {
      name: this.term,
      price_table_id: this.user.store.default_price_table_code,
      page: this.page,
      source: 'new-ecommerce',
      company: {
        id: this.companyId,
      },
      category: this.categoryId,
      order_type_id: this.selectedOrderType['id'],
    };

    productsRequest(data)
      .then((res) => {
        const currentPage = res['data']['current_page'];
        const fullPage = res['data']['full_page'];

        if (res['data']['products']) {
          res['data']['products'].forEach((prod) => {
            prod['image'] = prod['photo_cover']
              ? 'https://p.biaction.com.br/' +
                this.user.client_id +
                '/products/300x300/' +
                prod['photo_cover']
              : 'https://app.pedidosdigitais.com.br/img/sem-imagem-mini.png';
            prod['has_discount'] =
              prod['default_discount'] > 0 &&
              prod['price_no_discounts'] > prod['price_with_discounts']
                ? true
                : false;
            prod['price_default'] =
              prod['price_default'] && prod['price_default'] > 0
                ? prod['price_default']
                : 0;
            prod['price_no_discounts'] = prod['product_price_table'];
            prod['price_with_discounts'] =
              prod['product_price_table'] -
              (prod['product_price_table'] * prod['default_discount']) / 100;
            prod['total_discount_percentage'] = prod['has_discount']
              ? numeral(
                  ((prod['price_with_discounts'] - prod['price_no_discounts']) /
                    prod['price_no_discounts']) *
                    100
                ).format('0')
              : 0;
              prod['stock_default'] = prod['stock'] > 0 ? prod['stock'] : 0;
              prod['stock'] = this.getLocalStock(prod) + this.getFutureStock(prod);

            const ipi = prod.ipi;
            if (
              prod.ncm !== '' &&
              this.ncm_list !== undefined &&
              Object.keys(this.ncm_list).length > 0
            ) {
              const ncm = this.ncm_list[prod.ncm];
              if (ncm !== undefined) {
                const state = this.user.store.address_state;
                const ipi_ncm = ncm.ipi;
                const isento_ipi = ncm.isento_ipi ? ncm.isento_ipi : false;
                if (!isento_ipi) {
                  if (ipi_ncm && !isNaN(ipi_ncm)) {
                    prod['ipi'] = ipi_ncm;
                  } else {
                    prod['ipi'] = ipi !== -1 && ipi !== null ? ipi : 0;
                  }
                } else {
                  prod['ipi'] = 0;
                }
                if (!this.isento_st) {
                  if (prod.fromabroad) {
                    prod['st'] =
                      ncm.im !== undefined && ncm.im[state] !== undefined
                        ? ncm.im[state]
                        : 0;
                  } else {
                    prod['st'] =
                      ncm.st !== undefined && ncm.st[state] !== undefined
                        ? ncm.st[state]
                        : 0;
                  }
                } else {
                  prod['st'] = 0;
                }
                prod['tax_discount'] =
                  ncm.tax_discount !== undefined &&
                  ncm.tax_discount[state] !== undefined
                    ? ncm.tax_discount[state]
                    : 0;
              } else {
                prod['ipi'] = ipi !== -1 && ipi !== null ? ipi : 0;
                prod['st'] = 0;
                prod['tax_discount'] = 0;
              }
            } else {
              prod['ipi'] = ipi !== -1 && ipi !== null ? ipi : 0;
              prod['st'] = 0;
              prod['tax_discount'] = 0;
            }

            this.paginatedData.push(prod);
          });
        }

        this.nbHits = 1;
        this.nbPages = fullPage;
        this.maxSize = fullPage;
        this.page = currentPage;

        this.loadedData = true;
        this.showProductsSpinner = false;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  onPageChange(page: number): void {
    this.page = page;
    this.getAllowedProductsToUser();
  }

  changeCategories() {
    this.getAllowedProductsToUser();
  }

  changeColor(event) {
    event.preventDefault();
    const target = event.currentTarget.id;

    if (target === 'pills-list-tab') {
      this.isGrid = false;
      $('#pills-grid')
        .removeClass('show active')
        .animate({ overflow: 'hidden', opacity: '0.4' }, 1300, 'easeOutCubic');
      $('#pills-list')
        .addClass('show active')
        .animate({ opacity: '1' }, 1000, 'easeOutCubic');

      if ($('#pills-list-tab svg path').hasClass('icon-active')) {
        $('#pills-list-tab svg path').removeClass('icon-active');
        $('#pills-list-tab svg path').addClass('icon-inactive');
      } else {
        $('#pills-list-tab svg path').addClass('icon-active');
        $('#pills-list-tab svg path').removeClass('icon-inactive');
        $('#pills-grid-tab svg path').removeClass('icon-active');
        $('#pills-grid-tab svg path').addClass('icon-inactive');
      }
    } else {
      event.preventDefault();
    }

    if (target === 'pills-grid-tab') {
      this.isGrid = true;
      $('#pills-list')
        .removeClass('show active')
        .animate({ overflow: 'hidden', opacity: '0.4' }, 1300, 'easeOutCubic');
      $('#pills-grid')
        .addClass('show active')
        .animate({ opacity: '1' }, 1000, 'easeOutCubic');

      if ($('#pills-grid-tab svg path').hasClass('icon-active')) {
        $('#pills-grid-tab svg path').removeClass('icon-active');
        $('#pills-grid-tab svg path').addClass('icon-inactive');
      } else {
        $('#pills-grid-tab svg path').addClass('icon-active');
        $('#pills-grid-tab svg path').removeClass('icon-inactive');
        $('#pills-list-tab svg path').removeClass('icon-active');
        $('#pills-list-tab svg path').addClass('icon-inactive');
      }
    } else {
      event.preventDefault();
    }
  }

  navigateToDifferentCategory(item) {
    this.categoryId = item.id;
    this.router.navigate([`/categories/${this.categoryId}`]);
  }

  hasStock(item) {
    if (this.isStockActive) {
      if (item.stock > 0) {
        return true;
      }

      return false;
    }

    return true;
  }

  navigateBack(): void {
    this.navigationService.back();
  }

  openProductDetails(item) {
    /* Só navegará para Detalhes do Produto, se o item.grade_id
    for null e o produto não possuir nenhuma relação na tabela grade. */

    let goToPreCart = false,
      qtd = 0;
    if (this.showQtdField) {
      let qtdField;
      if (this.isGrid) {
        qtdField = $(`#${item.id}_qtd`).val();
      } else {
        qtdField = $(`#${item.id}_qtd_list`).val();
      }

      if (qtdField && qtdField !== '') {
        goToPreCart = true;
        qtd = qtdField;
      }
    }

    if (goToPreCart) {
      this.router.navigate([`/precart/${item.id}`], {
        queryParams: { qtd: qtd },
      });
    } else {
      this.router.navigate([`/products/${item.id}`]);
    }
  }

  openInfos(product) {
    this.productSelected = product;
    $('#modal-products-infos').modal('show');
  }

  // função para pegar dados do componente filho
  filterProducts(res) {
    this.paginatedData = [];

    const currentPage = res['data']['current_page'];
    const fullPage = res['data']['full_page'];

    if (res['data']['products']) {
      res['data']['products'].forEach((prod) => {
        prod['image'] = prod['photo_cover'] ?
          'https://p.biaction.com.br/' + this.user.client_id + '/products/300x300/' + prod['photo_cover'] :
          'https://app.pedidosdigitais.com.br/img/sem-imagem-mini.png';
        prod['has_discount'] = prod['default_discount'] > 0 &&
        prod['price_no_discounts'] > prod['price_with_discounts'] ? true : false;
        prod['price_default'] = prod['price_default'] && prod['price_default'] > 0 ? prod['price_default'] : 0;
        prod['price_no_discounts'] = prod['product_price_table'];
        prod['price_with_discounts'] = prod['product_price_table'] -
          (prod['product_price_table'] * prod['default_discount']) / 100;
        prod['total_discount_percentage'] = prod['has_discount'] ?
          numeral(((prod['price_with_discounts'] - prod['price_no_discounts']) / prod['price_no_discounts']) * 100
          ).format('0') : 0;
        prod['stock_default'] = prod['stock'] > 0 ? prod['stock'] : 0;
        prod['stock'] = this.getLocalStock(prod) + this.getFutureStock(prod);

        const ipi = prod.ipi;
        if (prod.ncm !== '' && this.ncm_list !== undefined && Object.keys(this.ncm_list).length > 0) {
          const ncm = this.ncm_list[prod.ncm];
          if (ncm !== undefined) {
            const state = this.user.store.address_state;
            const ipi_ncm = ncm.ipi;
            const isento_ipi = ncm.isento_ipi ? ncm.isento_ipi : false;
            if (!isento_ipi) {
              if (ipi_ncm && !isNaN(ipi_ncm)) {
                prod['ipi'] = ipi_ncm;
              } else {
                prod['ipi'] = ipi !== -1 && ipi !== null ? ipi : 0;
              }
            } else {
              prod['ipi'] = 0;
            }
            if (!this.isento_st) {
              if (prod.fromabroad) {
                prod['st'] = ncm.im !== undefined && ncm.im[state] !== undefined ? ncm.im[state] : 0;
              } else {
                prod['st'] = ncm.st !== undefined && ncm.st[state] !== undefined ? ncm.st[state] : 0;
              }
            } else {
              prod['st'] = 0;
            }
            prod['tax_discount'] = ncm.tax_discount && ncm.tax_discount[state] ? ncm.tax_discount[state] : 0;
          } else {
            prod['ipi'] = ipi !== -1 && ipi !== null ? ipi : 0;
            prod['st'] = 0;
            prod['tax_discount'] = 0;
          }
        } else {
          prod['ipi'] = ipi !== -1 && ipi !== null ? ipi : 0;
          prod['st'] = 0;
          prod['tax_discount'] = 0;
        }

        this.paginatedData.push(prod);
      });
    }

    this.nbHits = 1;
    this.nbPages = fullPage;
    this.maxSize = fullPage;
    this.page = currentPage;

    this.loadedData = true;
  }

  getLocalStock(prod) {
    this.scopeStockQuota;
    let general = 'general';

    if(this.configType == 2)  {
      if(!this.ignoreStockQuota && this.generalEnableStockUsage){

        if (prod['stock_quota'] === undefined || prod['stock_quota'] === null || (Object.keys(prod['stock_quota']).length === 0)) {
          return prod['stock_default'];
        }
        
        if (this.scopeStockQuota === 'segment' && this.storeSegment !== null) {
          return this.getStockQuota(prod, this.storeSegment) ?? 0;
        } else if (this.scopeStockQuota === 'general') {
          return this.getStockQuota(prod, general) ?? 0;
        } 

      } else {
        //usar estoque local

        if(prod['stock_place_order_type'] !== undefined && prod['stock_place_order_type'] !== null) {
          return prod['stock_place_order_type'];
        } else if(prod['stock_place_enterprise'] !== undefined && prod['stock_place_enterprise'] !== null) {
          return prod['stock_place_enterprise'];
        } else if(prod['stock_place_store'] !== undefined && prod['stock_place_store'] !== null) {
          return prod['stock_place_store'];
        } else {
          return 0;
        }
      }
    } else if(this.configType == 1) {
      if(!this.ignoreStockQuota){

        if (prod['stock_quota'] === undefined || prod['stock_quota'] === null || (Object.keys(prod['stock_quota']).length === 0)) {
          return prod['stock_default'];
        }

        if (this.scopeStockQuota === 'segment' && this.storeSegment !== null) {
          return this.getStockQuota(prod, this.storeSegment) ?? 0;
        } else if (this.scopeStockQuota === 'general') {
          return this.getStockQuota(prod, general) ?? 0;
        }

      } else {
        //usar estoque local

        if(prod['stock_place_order_type'] !== undefined && prod['stock_place_order_type'] !== null) {
          return prod['stock_place_order_type'];
        } else if(prod['stock_place_enterprise'] !== undefined && prod['stock_place_enterprise'] !== null) {
          return prod['stock_place_enterprise'];
        } else if(prod['stock_place_store'] !== undefined && prod['stock_place_store'] !== null) {
          return prod['stock_place_store'];
        } else {
          return 0;
        }
      }

    }
  }

  getStockQuota(prod, param) {
    let totalQuota = 0;
    if (prod['stock_quota'] !== null && prod['stock_quota'][param] !== null && prod['stock_quota'][param] !== undefined) {
      return totalQuota = prod['stock_quota'][param];
    } else {
      return prod['stock_default'];
    }
  }

  getFutureStock(prod) {

    if(this.configType == 2) {
      
      if (this.generalEnableFutureStock && !this.generalStockFutureAdvisory) {
        const dataAtual = new Date();
  
        let totalFutureStock = 0;
    
        if (prod.future_stock && Array.isArray(prod.future_stock)) {
          for (const future of prod.future_stock) {
            const dataFuture = new Date(future.date);
    
            if (dataFuture > dataAtual) {
              return totalFutureStock += future.qty;
            }
          }
        }
    
        return 0;
      } else {
        return 0;
      }


    } else if(this.configType == 1) {

      if (this.enableFutureStock && !this.stockFutureAdvisory) {
        const dataAtual = new Date();
  
        let totalFutureStock = 0;
    
        if (prod.future_stock && Array.isArray(prod.future_stock)) {
          for (const future of prod.future_stock) {
            const dataFuture = new Date(future.date);
    
            if (dataFuture > dataAtual) {
              return totalFutureStock += future.qty;
            }
          }
        }
    
        return 0;
      } else {
        return 0;
      }
    }
    
  }
}
